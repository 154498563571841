<template>
  <label class="control-label" style="width: 100%">
    ATTACHMENTS
    <small class="btn btn-sm btn-transparent text-danger" @click="addAttachmentRow">
      <i class="fas fa-plus fa05x"></i> Add File
    </small>
  </label>
  <div v-if="visibleAttachments.length">
    <div class="row">
      <div class="col-sm-3">
        <label class="control-label">Type</label>
      </div>
      <div class="col-sm-3">
        <label class="control-label">Document Number</label>
      </div>
      <div class="col-sm-3">
        <label class="control-label">Document Date</label>
      </div>
      <div class="col-sm-3">
        <label class="control-label">Attachment</label>
      </div>
    </div>
    <span v-for="(attachment, index) in attachments" :key="index">
      <DocumentAttachment :attachment="attachment" />
    </span>
  </div>
  <div class="alert alert-secondary text-muted" v-else>
    <i class="fas fa-info-circle"></i> No files attached to this form.
  </div>
</template>
<script setup>
	import DocumentAttachment from "@/components/layout/Form/DocumentAttachment";
	import { computed, defineModel, provide } from "vue";

	const newRow = { type:'', document_number: '', date: '', file: '', visible: true}
	const attachments = defineModel({default:[{ type: '', id: null, document_number: '', date: '', file: '', visible: true}]});

  const visibleAttachments = computed(() => {
    return attachments.value.filter(item => item.visible == true)
  });

	const addAttachmentRow = () => {
		let nR = {...newRow};
		nR.id = null;
		attachments.value = [...attachments.value, nR];
	};
</script>
<style>
.file-attachment.details:nth-child(even){
  border-bottom: 1px solid #f5f5f5;
}
</style>