const { default: axios } = require("axios");

const ports_of_entry = { "Airports": { "Jomo Kenyatta International Airport (Nairobi)": "Nairobi", "Moi International Airport (Mombasa)": "Mombasa", "Kisumu International Airport": "Kisumu", "Eldoret International Airport": "Eldoret", "Wilson Airport (Nairobi)": "Nairobi", "Malindi Airport": "Malindi", "Lamu Airport": "Lamu" }, "Seaports": { "Mombasa Port": "Mombasa", "Kisumu Port": "Kisumu" }, "Land Border Crossings": { "Taveta-Holili Border Crossing (Kenya-Tanzania)": "Taveta", "Busia Border Crossing (Kenya-Uganda)": "Busia", "Namanga Border Crossing (Kenya-Tanzania)": "Namanga", "Isebania Border Crossing (Kenya-Tanzania)": "Isebania" } };
const import_categories = [{ "category_name": "Electronics", "subcategories": ["Smartphones", "Laptops", "Tablets", "Accessories"] }, { "category_name": "Apparel", "subcategories": ["Men's Clothing", "Women's Clothing", "Footwear", "Accessories"] }, { "category_name": "Home Goods", "subcategories": ["Furniture", "Kitchenware", "Bedding", "Decor"] }, { "category_name": "Toys and Games", "subcategories": ["Board Games", "Outdoor Toys", "Action Figures", "Puzzles"] }, { "category_name": "Beauty and Personal Care", "subcategories": ["Skincare", "Haircare", "Cosmetics", "Fragrances"] }, { "category_name": "Automotive", "subcategories": ["Car Parts", "Accessories", "Tools", "Tires"] }, { "category_name": "Sports and Fitness", "subcategories": ["Sporting Goods", "Athletic Apparel", "Fitness Equipment", "Outdoor Gear"] }, { "category_name": "Books and Media", "subcategories": ["Books", "Music", "Movies", "Magazines"] }];

const headersList = {
	"Accept": "*/*",
	"User-Agent": "ACID",
	"Content-Type": "application/json",
};

axios.defaults.withCredentials = true;
axios.defaults.withXSRFToken = true;

const saveUserToServer = async (SERVER, data) => {
	try {
		let csrf = await axios.get(SERVER + '/sanctum/csrf-cookie');
		let res = await axios.post(SERVER + '/api/register', data);
		return {
			"status": true,
			"data": res.data
		};
	}
	catch (err) {
		return {
			"status": false,
			"error": err
		};
	}
}

const userLogin = async (SERVER, data) => {
	try {
		let csrf = await axios.get(SERVER + '/sanctum/csrf-cookie');
		let res = await axios.post(SERVER + '/api/login', data);
		if (res.data.status) {
			return {
				"status": true,
				"user": res.data.user
			};
		}
		else {
			return {
				"status": false,
				"error": res.data
			};
		}
	}
	catch (err) {
		console.log(err);
		return {
			"status": false,
			"error": err
		};
	}
}

const updateUserToServer = async (SERVER, data, token) => {
	try {
		let csrf = await axios.get(SERVER + '/sanctum/csrf-cookie');
		let save = await axios.patch(SERVER + '/api/profile-update', data, { Authorization: `Bearer ${token}` });
		if (save.data.status) {
			return {
				"status": true,
				"user": save.data.user
			};
		}
		else {
			return {
				"status": false,
				"error": save.data.message
			};
		}
	}
	catch (err) {
		return {
			"status": false,
			"error": err.message
		};
	}
}

const fetchCountries = async (SERVER) => {
	try {
		let countries = await axios.get(SERVER + '/api/countries');
		return {
			"status": true,
			"countries": countries.data
		};
	}
	catch (err) {
		console.log(err);
		return {
			"status": false,
			"error": err
		};
	}
}

const fetchCounties = async (SERVER) => {
	try {
		let counties = await axios.get(SERVER + '/api/counties');
		return {
			"status": true,
			"counties": counties.data
		};
	}
	catch (err) {
		console.log(err);
		return {
			"status": false,
			"error": err
		};
	}
}

const fetchFormData = async (SERVER, id, token) => {
	let options = {
		headers: { Authorization: `Bearer ${token}` }
	}
	try {
		let FORM = await axios.get(SERVER + '/api/forms/' + id, options);
		return {
			"status": true,
			"form": FORM.data.form
		};
	}
	catch (err) {
		return {
			"status": false,
			"error": err
		};
	}
}

const fetchOfferData = async (SERVER, id, token) => {
	let options = {
		headers: { Authorization: `Bearer ${token}` }
	}
	try {
		let FORM = await axios.get(SERVER + '/api/offers/' + id, options);
		return {
			"status": true,
			"offer": FORM.data.offer
		};
	}
	catch (err) {
		return {
			"status": false,
			"error": err
		};
	}
}

const makeNormalObject = ($data, $key) => {
	if ($data == "") {
		return $data;
	}

	if ($key == "other_charges" || $key == "items" || typeof $data == "array") {
		let arr = [];
		for (let d in $data) {
			arr.push($data[d]);
		}
		return JSON.stringify(arr);
	}
	else if (typeof $data == "object") {
		let arr = {};
		for (let d in $data) {
			arr[d] = $data[d];
		}
		return JSON.stringify(arr);
	}
	else {
		return $data
	}
}

const postOfferData = async (data, token, SERVER) => {
	const FORM = new FormData();
	const obj = data.value;

	Object.keys(obj).forEach((key) => {
		let dd = makeNormalObject(obj[key], key);
		dd = dd == "{}" ? "" : dd;
		FORM.append(key, dd);
	});

	try {
		let options = {
			headers: { Authorization: `Bearer ${token}`, "content-type": "application/json" }
		}
		let csrf = await axios.get(SERVER + '/sanctum/csrf-cookie');
		let save = data.value.id && parseInt(data.value.id) > 0 ? await axios.patch(SERVER + '/api/offers/' + data.value.id, FORM, options)
			: await axios.post(SERVER + '/api/offers', FORM, options);
		if (save.data.status) {
			return {
				"status": true,
				"offer": save.data.offer
			};
		}
		else {
			return {
				"status": false,
				"error": save.data.message
			};
		}
	}
	catch (err) {
		return {
			"status": false,
			"error": err.message
		};
	}
}

const postFormData = async (data, token, SERVER) => {
	const FORM = new FormData();
	const obj = data.value;
	Object.keys(obj).forEach((key) => {
		let dd = makeNormalObject(obj[key], key);
		dd = dd == "{}" ? "" : dd;
		FORM.append(key, dd);
	});

	try {
		let options = {
			headers: { Authorization: `Bearer ${token}`, "content-type": "application/json" }
		}
		let csrf = await axios.get(SERVER + '/sanctum/csrf-cookie');
		let save = data.value.id && parseInt(data.value.id) > 0 ? await axios.patch(SERVER + '/api/forms/' + data.value.id, FORM, options)
			: await axios.post(SERVER + '/api/forms', FORM, options);
		if (save.data.status) {
			return {
				"status": true,
				"form": save.data.form
			};
		}
		else {
			return {
				"status": false,
				"error": save.data.message
			};
		}
	}
	catch (err) {
		return {
			"status": false,
			"error": err.message
		};
	}
}

const postFormFiles = async (id, file, token, SERVER) => {
	if (file.id > 0) {
		return false;
	}
	const FORM = new FormData();
	let options = {
		headers: { Authorization: `Bearer ${token}` }
	}

	let fileKeys = Object.keys(file);

	for (let k of fileKeys) {
		FORM.append(k, file[k]);
	}

	try {
		await axios.get(SERVER + '/sanctum/csrf-cookie');
		let save = await axios.post(SERVER + '/api/forms/' + id + '/fileUpload', FORM, options);
		if (save.data.status) {
			return {
				"status": true,
				"file": save.data.data
			};
		}
	}
	catch (err) {
		console.log(err.message)
		return {
			"status": false,
			"error": err.message
		};
	}
}

const deleteAttachment = async (id, token, SERVER) => {
	let options = {
		headers: { Authorization: `Bearer ${token}` }
	}
	try {
		await axios.get(SERVER + '/sanctum/csrf-cookie');
		let del = await axios.delete(SERVER + '/api/forms/c36-attachement/' + id + '/remove', options);
		if (del.data.status) {
			return {
				"status": true
			};
		}
	}
	catch (err) {
		console.log(err.message)
		return {
			"status": false,
			"error": err.message
		};
	}
}

const resendOTP = async (SERVER, token, id) => {
	let options = {
		headers: { Authorization: `Bearer ${token}` }
	}
	try {
		await axios.get(SERVER + '/sanctum/csrf-cookie');
		let resend = await axios.post(SERVER + '/api/forms/' + id + '/resend-otp', {}, options);
		if (resend.data.status) {
			return {
				"status": true
			};
		}
	}
	catch (err) {
		console.log(err.message)
		return {
			"status": false,
			"error": err.message
		};
	}
}

const getCurrencies = () => {
	return {
		"€": { value: 155, name: 'Euro' },
		"KES": { value: 1, name: 'Kenya Shilling' },
		"£": { value: 180, name: 'Pound Sterling' },
		"TSH": { value: 1 / 18, name: 'Tanzanian Shilling' },
		"UGX": { value: 1 / 28, name: 'Ugandan Shilling' },
		"$": { value: 142, name: 'US Dollar' },
	};
}

const convertToKsh = (val, currency) => {
	if (currency == "KES") {
		return val;
	}

	let currencies = getCurrencies();
	let value = currencies[currency].value;

	return val * value;
}

class UnitConverter {
	static LBKGConverter(val, og = "KG", nx = "LB") {
		if (isNaN(val)) {
			return 0;
		}
		if (og == nx) {
			return val;
		}
		let convertToLB = og == "KG" && nx == "LB";
		return parseFloat((convertToLB ? val * 2.20462 : (val * 0.453592)).toFixed(2))
	}

	static CM_MConverter(val, og = "CM", nx = "M") {
		if (isNaN(val)) {
			return 0;
		}
		if (og == nx) {
			return val;
		}
		let convertToM = og == "CM" && nx == "M";
		return parseFloat((convertToM ? (val * 0.001) : val * 1000).toFixed(2));
	}

	static CurrencyConverter(val, c1 = null, c2 = null) {
		if (c1 == null || c2 == null) {
			throw !c1 ? 'Original currency can not be null' : (c2 ? 'Target currency can not be null' : 'Issue with the currencies')
		}

		if (c1 == c2) {
			return val;
		}

		val = parseFloat(val);

		let c1InKes = convertToKsh(val, c1);

		let currencies = getCurrencies();
		let convertedAmnt = c1InKes / currencies[c2].value;
		return convertedAmnt;
	}
}

const fetchDataOrigin = async (SERVER, id, token) => {
	let options = {
		headers: { Authorization: `Bearer ${token}` }
	}
	try {
		let Offers = await axios.get(SERVER + '/api/forms/' + id + '/origin-data', options);
		return {
			"status": true,
			"origin": Offers.data.data
		};
	}
	catch (err) {
		return {
			"status": false,
			"error": err
		};
	}
}

const postDataOrigin = async (data, token, SERVER) => {
	const FORM = new FormData();
	const obj = data.value;
	Object.keys(obj).forEach((key) => {
		let dd = makeNormalObject(obj[key], key);
		dd = dd == "{}" ? "" : dd;
		FORM.append(key, dd);
	});

	try {
		let options = {
			headers: { Authorization: `Bearer ${token}`, "content-type": "application/json" }
		}
		let csrf = await axios.get(SERVER + '/sanctum/csrf-cookie');
		let save = await axios.post(SERVER + '/api/forms/' + data.value.form_c36_id + '/origin-data', FORM, options);
		if (save.data.status) {
			return {
				"status": true,
				"form": save.data.data
			};
		}
		else {
			return {
				"status": false,
				"error": save.data.message
			};
		}
	}
	catch (err) {
		return {
			"status": false,
			"error": err.message
		};
	}
}

const createForm = (data) => {
	const FORM = new FormData();
	let keys = Object.keys(data);

	for (let k of keys) {
		FORM.append(k, data[k]);
	}

	return FORM;
}

const getShipments = () => {
	return [
		{
			"tracking_number": "MNU210223000",
			"vessel_name": "MV MANUELA S",
			"vessel_mmsi_number": "210223000",
			"origin_port": "Rotterdam, Netherlands",
			"destination_port": "Mombasa, Kenya",
			"estimated_arrival_date": "2024-06-15",
			"shipper_name": "Dutch Exports Ltd.",
			"consignee_name": "Kenyan Importers Co.",
			"shipment_description": "Machinery parts",
			"container_number": "MSCU1234567",
			"container_type": "40ft Dry",
			"shipment_weight": 18000.75,
			"shipment_volume": 75.25,
			"shipping_line": "Maersk Line",
			"shipment_status": "In Transit"
		},
		{
			"tracking_number": "TYM431300401",
			"vessel_name": "TOYOMASA MARU",
			"vessel_mmsi_number": "431300401",
			"origin_port": "Yokohama, Japan",
			"destination_port": "Mombasa, Kenya",
			"estimated_arrival_date": "2024-06-10",
			"shipper_name": "Toyota Motor Corporation",
			"consignee_name": "Kenyan Automotive Importers",
			"shipment_description": "Automobiles and parts",
			"container_number": "TCLU9876543",
			"container_type": "40ft High Cube",
			"shipment_weight": 22500.00,
			"shipment_volume": 95.00,
			"shipping_line": "Ocean Network Express",
			"shipment_status": "In Transit"
		},
		{
			"tracking_number": "NMR470142000",
			"vessel_name": "NAMER",
			"vessel_mmsi_number": "470142000",
			"origin_port": "Dubai, UAE",
			"destination_port": "Mombasa, Kenya",
			"estimated_arrival_date": "2024-07-10",
			"shipper_name": "Emirates Petroleum",
			"consignee_name": "Kenyan Energy Corp",
			"shipment_description": "Crude oil",
			"container_number": "HLCU0987654",
			"container_type": "Oil Tanker",
			"shipment_weight": 180000.00,
			"shipment_volume": 200000.00,
			"shipping_line": "Gulf Shipping Line",
			"shipment_status": "In Transit"
		},
		{
			"tracking_number": "STF211551690",
			"vessel_name": "STEFFI",
			"vessel_mmsi_number": "211551690",
			"origin_port": "Hamburg, Germany",
			"destination_port": "Mombasa, Kenya",
			"estimated_arrival_date": "2024-07-05",
			"shipper_name": "German Machinery Exports",
			"consignee_name": "Kenyan Industrial Solutions",
			"shipment_description": "Heavy machinery",
			"container_number": "HLCU4567890",
			"container_type": "40ft Flat Rack",
			"shipment_weight": 35000.00,
			"shipment_volume": 105.00,
			"shipping_line": "Hapag-Lloyd",
			"shipment_status": "In Transit"
		},
		{
			"tracking_number": "COD857409919",
			"vessel_name": "CAO",
			"vessel_mmsi_number": "8574090919",
			"origin_port": "Quy Nhon, Vietnam",
			"destination_port": "Mombasa, Kenya",
			"estimated_arrival_date": "2024-08-01",
			"shipper_name": "Vietnam Seafood Exports",
			"consignee_name": "Kenyan Seafood Imports",
			"shipment_description": "Frozen seafood",
			"container_number": "CRFU7654321",
			"container_type": "20ft Reefer",
			"shipment_weight": 18000.00,
			"shipment_volume": 60.00,
			"shipping_line": "Regional Container Lines",
			"shipment_status": "In Transit"
		},
		{
			"tracking_number": "JNY244730118",
			"vessel_name": "JENNY",
			"vessel_mmsi_number": "244730118",
			"origin_port": "Antwerp, Belgium",
			"destination_port": "Mombasa, Kenya",
			"estimated_arrival_date": "2024-07-15",
			"shipper_name": "Belgian Brewers",
			"consignee_name": "Kenyan Beverage Distributors",
			"shipment_description": "Beer and beverages",
			"container_number": "MOLU1234567",
			"container_type": "20ft Dry",
			"shipment_weight": 15000.00,
			"shipment_volume": 50.00,
			"shipping_line": "Mol Lines",
			"shipment_status": "In Transit"
		},
		{
			"tracking_number": "BRT244057049",
			"vessel_name": "BRUTE",
			"vessel_mmsi_number": "244057049",
			"origin_port": "Southampton, UK",
			"destination_port": "Mombasa, Kenya",
			"estimated_arrival_date": "2024-06-20",
			"shipper_name": "British Auto Exports",
			"consignee_name": "Kenyan Luxury Autos",
			"shipment_description": "Luxury vehicles",
			"container_number": "MSCU8765432",
			"container_type": "40ft High Cube",
			"shipment_weight": 20000.00,
			"shipment_volume": 90.00,
			"shipping_line": "Maersk Line",
			"shipment_status": "In Transit"
		},
		{
			"tracking_number": "CFZ701000837",
			"vessel_name": "CIUDAD FELIZ",
			"vessel_mmsi_number": "701000837",
			"origin_port": "Mar del Plata, Argentina",
			"destination_port": "Mombasa, Kenya",
			"estimated_arrival_date": "2024-07-20",
			"shipper_name": "Argentinian Seafood Co.",
			"consignee_name": "Kenyan Seafood Imports",
			"shipment_description": "Frozen fish",
			"container_number": "CRXU9012345",
			"container_type": "20ft Reefer",
			"shipment_weight": 16000.00,
			"shipment_volume": 55.00,
			"shipping_line": "Hamburg Sud",
			"shipment_status": "In Transit"
		},
		{
			"tracking_number": "APL219023441",
			"vessel_name": "APOLLON",
			"vessel_mmsi_number": "219023441",
			"origin_port": "Kingston, Jamaica",
			"destination_port": "Mombasa, Kenya",
			"estimated_arrival_date": "2024-08-10",
			"shipper_name": "Jamaican Rum Exporters",
			"consignee_name": "Kenyan Liquor Imports",
			"shipment_description": "Rum and spirits",
			"container_number": "CRXU6789012",
			"container_type": "20ft Reefer",
			"shipment_weight": 14000.00,
			"shipment_volume": 45.00,
			"shipping_line": "Zim Integrated Shipping",
			"shipment_status": "In Transit"
		}
	];
};

const fetchOffers = async (SERVER, id, token, typ) => {
	let options = {
		headers: { Authorization: `Bearer ${token}` }
	}
	try {
		let Offers = await axios.get(SERVER + '/api/offers?service_type=' + typ + '&form_c36_id=' + id, options);
		return {
			"status": true,
			"offers": Offers.data.offers
		};
	}
	catch (err) {
		return {
			"status": false,
			"error": err
		};
	}
}

module.exports = { fetchDataOrigin, postDataOrigin, createForm, postOfferData, fetchOfferData, fetchOffers, getShipments, resendOTP, getCurrencies, UnitConverter, ports_of_entry, import_categories, headersList, postFormFiles, postFormData, saveUserToServer, updateUserToServer, userLogin, fetchCountries, fetchCounties, fetchFormData, deleteAttachment };