<template>
  <div class="rates">
    <h1>This is rates page</h1>
  </div>
</template>

<style>
#app{
	
}
</style>