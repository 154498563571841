<template>
  <div class="login">
    <div class="fill-space">
      <h1>ACID</h1>
    </div>
    <div class="form">
      <form @submit.prevent="Register">
        <img src="../assets/images/login.png" class="login" alt="User Login" />
        <h3 class="mv-2">REGISTER</h3>
        <div class="form-floating mb-3">
          <input
            required
            type="text"
            v-model="name"
            class="form-control"
            placeholder="Name..."
          />
          <label for="floatingInput">*Name</label>
        </div>
        <div class="form-floating mb-3">
          <input
            required
            type="email"
            v-model="email"
            class="form-control"
            placeholder="Email..."
          />
          <label for="floatingInput">*Email</label>
        </div>
        <div class="form-floating mb-3">
          <input
            required
            type="password"
            v-model="password"
            class="form-control"
            placeholder="Password..."
          />
          <label for="floatingInput">*Password</label>
        </div>
        <div class="form-floating mb-3">
          <input
            required
            type="password"
            v-model="confirm_password"
            class="form-control"
            placeholder="Confirm Password..."
          />
          <label for="floatingInput">*Confirm Password</label>
        </div>
        <div class="alert alert-danger" role="alert" v-if="registrationError">
          <small>{{ registrationError }}</small>
        </div>
        <div class="alert alert-warning text-danger" role="alert" v-if="!passwordsMatch">
          <small><i class="fas fa-exclamation-triangle"></i> Passwords do not match!</small>
        </div>
        <div class="d-grid mb-3">
          <button class="btn btn-success btn-lg" :disabled="!passwordsMatch || sendingData">
						<small v-if="!sendingData">Create an Account</small>
						<small v-else><i class="fas fa-spin fa-spinner"></i> Sending user data...</small>
					</button>
        </div>
				<div class="d-flex mb-3 justify-content-center">
          <router-link :to="{name: 'login'}" class="text-default text-deco-none">
						<i class="fas fa-arrow-left"></i>
						Back to Login
					</router-link>
        </div>
      </form>
    </div>
  </div>
</template>
<script setup>
import { ref, inject, computed } from "vue";
import { useRouter } from "vue-router";
import { saveUserToServer } from "@/scripts/data";
const name = ref("");
const email = ref("");
const password = ref("");
const confirm_password = ref("");
const registrationError = ref("");
const sendingData = ref(null);


const SERVER_LOCATION = inject("SERVER_LOCATION");
const SERVER = SERVER_LOCATION.value;

const router = useRouter();

const passwordsMatch = computed(()=> confirm_password.value == password.value);

const Register = async() => {
  sendingData.value = true;
	registrationError.value = null;

  let registerStatus = await saveUserToServer(SERVER, {
    'name':name.value,
    'password':password.value,
    'password_confirmation':confirm_password.value,
    'email':email.value
  });

	sendingData.value = null;

  console.log(registerStatus, email.value);

  if(registerStatus.data.email == email.value){
    router.push({name: 'login'});
  }
  else{
		registrationError.value = registerStatus.error.message;
  }
};
</script>
<style>
.login {
  display: flex;
  justify-content: space-between;
}

.group label {
  display: block;
  padding: 5px 0;
}

img.login {
  margin-top: 80px;
  width: 120px;
}

.fill-space {
  flex-grow: 1;
  display: flex;
  height: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

form {
  width: 300px;
}

.form {
  justify-items: center;
  display: flex;
  flex-direction: column;
  width: 360px;
  height: 100vh;
  background-color: #fff;
  text-align: center;
  align-items: center;
  border-left: 1px solid #ccc;
}

@media screen and (max-width: 960px) {
  .form {
    width: 100%;
  }
}
</style>
