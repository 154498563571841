<template>
  <div class="button-checkbox">
    <span v-for="option in options" :class="{'selected': value==option}" :key="option"
		@click="toggleSelectedOption(option)">{{ option }}</span>
  </div>
  <input type="hidden"  />
</template>

<script setup>
  import {defineModel, defineProps, ref} from 'vue'
  const props = defineProps(['multiple', 'options'])

  const value = defineModel({default:''});
  const { options } = props;

  if(!value.value){
    value.value = JSON.stringify(options[0]);
  }

  const toggleSelectedOption = (op) => {
    value.value = op;
  };
</script>

<style>
.button-checkbox {
  display: inline-flex;
	margin: 3px 5px;
  border-radius: 5px;
	overflow: hidden;
	background-color: rgba(0,0,0,0.08);
}

.button-checkbox span {
  display: inline-block;
  padding: 2px 5px;
	font-weight: 500;
	text-transform: capitalize;
  font-size: 10px;
  cursor: pointer;
  color: #555;
}

.button-checkbox span.selected {
  background-color: rgb(9, 49, 134);
  color: #fff;
}
</style>