<template>
  <div class="login">
    <div class="fill-space">
      <h1>ACID</h1>
    </div>
    <div class="form">
      <form @submit.prevent="Login">
        <div class="p-2">
          <img
            src="../assets/images/login.png"
            class="login"
            alt="User Login"
          />
        </div>

        <h3 class="mv-2">SIGN IN</h3>
        <div class="form-floating mb-3">
          <input
            required
            type="email"
            v-model="email"
            class="form-control"
            placeholder="Email..."
          />
          <label for="floatingInput">*Email</label>
        </div>
        <div class="form-floating mb-3">
          <input
            required
            type="password"
            v-model="password"
            class="form-control"
            placeholder="Password..."
          />
          <label for="floatingInput">*Password</label>
        </div>
        <div class="d-flex mb-3 justify-content-between">
          <router-link
            :to="{ name: 'register' }"
            class="text-success text-deco-none"
            >Forgot Password</router-link
          >
          <router-link :to="{ name: 'register' }" class="text-deco-none"
            >Sign Up</router-link
          >
        </div>
        <div class="alert alert-danger" role="alert" v-if="loginError">
          <small>{{ loginError }}</small>
        </div>
        <div class="d-grid mb-3">
          <button class="btn btn-primary btn-lg" :disabled="sendingData">
            <small v-if="!sendingData">SIGN IN</small>
            <small v-else
              ><i class="fas fa-spin fa-spinner"></i> Please wait...</small
            >
          </button>
        </div>
      </form>
    </div>
  </div>
</template>
<script setup>
import { ref, inject, onMounted } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useStore } from "vuex";
import { userLogin } from "../scripts/data.js";
const email = ref("");
const password = ref("");
const loginError = ref("");
const sendingData = ref(null);

const router = useRouter();
const store = useStore();

const SERVER_LOCATION = inject("SERVER_LOCATION");
const SERVER = SERVER_LOCATION.value;

if(store.state.user !== null){
  router.push({ name: "dashboard" });
  // location.reload();
}

const Login = async () => {
  sendingData.value = true;
  let currentSession = await userLogin(SERVER, {
    email: email.value,
    password: password.value,
    device_name: "ACID Webapp",
  });
  sendingData.value = null;
  if (currentSession.status) {
    store.commit('setUser', currentSession.user);
    store.commit('setToken', currentSession.user.token);
    router.push({ name: "dashboard" });
    location.reload();
  } else {
    loginError.value = currentSession.error.message;
  }
};
</script>
<style>
.login {
  display: flex;
  justify-content: space-between;
}

.group label {
  display: block;
  padding: 5px 0;
}

img.login {
  margin: 60px auto;
  width: 120px;
}

.fill-space {
  flex-grow: 1;
  display: flex;
  height: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

form {
  width: 300px;
}

.form {
  justify-items: center;
  display: flex;
  flex-direction: column;
  width: 360px;
  height: 100vh;
  background-color: #fff;
  text-align: center;
  align-items: center;
  border-left: 1px solid #ccc;
}

@media screen and (max-width: 960px) {
  .form {
    width: 100%;
  }
  .fill-space {
    display: none !important;
  }
}
</style>
