<template>
  <template v-if="isPrinter">
    <router-view />
  </template>
  <template v-else>
    <AuthenticatedView v-if="loggedInUser">
      <router-view />
    </AuthenticatedView>
    <NotAuthenticatedView v-else>
      <router-view />
    </NotAuthenticatedView>
  </template>
</template>
<script setup>
  import AuthenticatedView from "@/views/layouts/AuthenticatedView";
  import NotAuthenticatedView from "@/views/layouts/NotAuthenticatedView";

  import { ref, provide, onMounted, onUpdated, computed, onBeforeMount } from "vue";
  import { onBeforeRouteLeave, onBeforeRouteUpdate, useRoute, useRouter } from "vue-router";
  import { useStore } from "vuex";
  import { getCurrentSession } from './scripts/supabase'

  const loggedInUser = ref(false);
  const isPrinter = ref(false);

  const router = useRouter();
  const route = useRoute();

  const store = useStore();
  const pageTitle = computed(()=>{
    return route.meta?.pageTitle ?? (route.name ?? '').toUpperCase()
  });

  const pageSub = computed(()=>{
    return route.meta?.pageSub ?? '';
  });

  const userLogout = () => {
    store.commit('setUser', null);
    store.commit('setToken', null);
    router.push({name: 'login'});
    location.reload();
  }

  const setPrintPage = () => {
    isPrinter.value = true;
    setTimeout(() => {
      // window.print();
    }, 1000);
  }

  const SERVER_LOCATION = computed(() => {
    let isLocal = window.location.href.includes('127.0.0.1') || window.location.href.includes('localhost');
    if(isLocal){
      // return 'https://acidapi.nuvemite.com';
      return 'http://127.0.0.1:8000';
    }
    else{
      return 'https://acidapi.nuvemite.com';
    }
  });

  provide('SERVER_LOCATION', SERVER_LOCATION);
  provide('pageTitle', pageTitle);
  provide('pageSub', pageSub);
  provide('setPrintPage', setPrintPage);

  const currentSession = ref(false);

  onBeforeMount(() => {
    currentSession.value = {"user": store.state.user, "token": store.state.token};
    loggedInUser.value = currentSession.value.user;
    if(store.state.user == null){
      currentSession.value = null;
      loggedInUser.value = null;
      router.push({name: 'login'});
    }
  });

  provide('loggedInUser', loggedInUser)
  provide('userLogout', userLogout);
</script>
<style>
html,
body {
  margin: 0;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

.text-deco-none{
  text-decoration: none !important;
}

h1{
  margin-top: 300px;
}
</style>
