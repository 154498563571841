import { createClient } from '@supabase/supabase-js'

const SUPABASE_KEY = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6ImF0b2RtYmV4cmphdGlmeWxpYWd0Iiwicm9sZSI6ImFub24iLCJpYXQiOjE3MDQ3Mjk4NTUsImV4cCI6MjAyMDMwNTg1NX0.AxqMnlo2rAPYm_a79Ew-tmeOwOg3x4uEJq4huFIEYNk'
export const supabase = createClient('https://atodmbexrjatifyliagt.supabase.co', SUPABASE_KEY);

export const userLogin = async (email, password) => {
	let { data, error } = await supabase.auth.signInWithPassword({ email, password });
	if (error) {
		return { status: false, error: error }
	}
	return { status: true, session: data }
}

export const userRegister = async (email, password) => {
	let { data, error } = await supabase.auth.signUp({ email, password });
	if (error) {
		return { status: false, error: error }
	}
	return { status: true, message: 'Go to Login' }
}

export const userForgotPassword = async (email) => {
	await supabase.auth.resetPasswordForEmail(email)
}

export const getCurrentSession = async () => {
	const { data, error } = await supabase.auth.getSession();
	if (error) {
		return { status: false, error: error }
	}
	return { status: true, session: data }
}

export const userLogout = async () => {
	const { error } = await supabase.auth.signOut();
	if (error) {
		return { status: false, error: error }
	}
	else {
		location.reload();
	}
}

export const userUpdate = async (userDetails) => {
	const { data, error } = await supabase.auth.updateUser(userDetails)
	if (error) {
		return { status: false, error: error }
	}
	else {
		return { status: true, data: data }
	}
}

export const countryList = async () => {
	let { data:countries, error } = await supabase
		.from('countries')
		.select('*');

	if (error) {
		return { status: false, error: error }
	}
	else {
		return { status: true, data: countries }
	}
}

// module.exports = { userLogin, userRegister, userForgotPassword };