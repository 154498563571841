import { createApp } from 'vue'
import App from './App.vue'
import router from './router'

// Vuetify
import 'vuetify/styles'
import { createVuetify } from 'vuetify'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'
import { aliases, mdi } from 'vuetify/iconsets/mdi'
import { createStore } from 'vuex'
import VuexPersistence from 'vuex-persist'

const vuetify = createVuetify({
	icons: {
    defaultSet: 'mdi',
    aliases,
    sets: {
      mdi,
    },
  },
  components,
  directives,
});

const vuexLocal = new VuexPersistence({
  storage: window.localStorage,
});

export const store = createStore({
  state () {
    return {
      user: null,
      token: null
    }
  },
  mutations: {
    setUser (state, user) {
      state.user = user;
    },
    setToken (state, token) {
      state.token = token;
    },
  },
  getters: {
    getUser (){
      return this.$store.state.user;
    },
    getToken (){
      return this.$store.state.token;
    }
  },
  plugins: [vuexLocal.plugin]
})

createApp(App).use(vuetify).use(router).use(store).mount('#app')