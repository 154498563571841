import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import LoginView from '@/views/LoginView'
import LogoutView from '@/views/LogoutView'
import RatesView from '@/views/RatesView'
import DocumentsView from '@/views/DocumentsView'
import ShipmentView from '@/views/ShipmentView'
import IntegrationView from '@/views/IntegrationView'
import RegisterView from '@/views/RegisterView'
import DashboardView from '@/views/DashboardView'
import FormC36 from '@/views/Forms/FormC36'
import FormC36New from '@/views/Forms/Print/FormC36New'
import FormC36Old from '@/views/Forms/Print/FormC36Old'
import ProfileView from '@/views/ProfileView'
import CargoConsolidation from '@/views/Forms/CargoConsolidation.vue'
import ClearingForwarding from '@/views/Forms/ClearingForwarding.vue'
import CargoInsurance from '@/views/Forms/CargoInsurance.vue'

// import { store } from '@/main'

// const currentSession = {"user": store.state.user, "token": store.state.token};
// const USER = currentSession.user ?? null;
// let USER = null;

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
  },
  {
    path: '/profile',
    name: 'profile',
    component: ProfileView,
    meta: {authenticated: true, pageSub: "" }
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    component: DashboardView,
    meta: {authenticated: true}
  },
  {
    path: '/login',
    name: 'login',
    component: LoginView,
    meta: {restrictedForAuth: true}
  },
  {
    path: '/logout',
    name: 'logout',
    component: LogoutView
  },
  {
    path: '/rates',
    name: 'rates',
    component: RatesView,
    meta: {authenticated: true}
  },
  {
    path: '/documents',
    name: 'documents',
    component: DocumentsView,
    meta: {authenticated: true}
  },
  {
    path: '/documents',
    name: 'documents',
    component: DocumentsView,
    meta: {authenticated: true}
  },
  {
    path: '/shipments',
    name: 'shipments',
    component: ShipmentView,
    meta: {authenticated: true}
  },
  {
    path: '/integration',
    name: 'integration',
    component: IntegrationView,
    meta: {authenticated: true}
  },
  {
    path: '/register',
    name: 'register',
    component: RegisterView,
    meta: {restrictedForAuth: true}
  },
  {
    path: '/formC36/:id',
    name: 'formC36',
    component: FormC36,
    meta: {authenticated: true, pageSub: 'DECLARATION OF PARTICULARS RELATING TO CUSTOMS VALUE'},
    props: true
  },
  {
    path: '/formC36/:id/consolidator-offer',
    name: 'consolidation-offer',
    component: CargoConsolidation,
    meta: {authenticated: true, pageSub: 'CARGO CONSOLIDATION SERVICE OFFER'}
  },
  {
    path: '/formC36/:id/consolidator-offer/:offerId',
    name: 'consolidation-offer-id',
    component: CargoConsolidation,
    meta: {authenticated: true, pageSub: 'CARGO CONSOLIDATION SERVICE OFFER'}
  },
  {
    path: '/formC36/:id/insurance-offer',
    name: 'insurance-offer',
    component: CargoInsurance,
    meta: {authenticated: true, pageSub: 'CARGO INSURANCE SERVICE OFFER'}
  },
  {
    path: '/formC36/:id/insurance-offer/:offerId',
    name: 'insurance-offer-id',
    component: CargoInsurance,
    meta: {authenticated: true, pageSub: 'CARGO CONSOLIDATION SERVICE OFFER'}
  },
  {
    path: '/formC36/:id/clearing-offer-form',
    name: 'clearing-offer',
    component: ClearingForwarding,
    meta: {authenticated: true, pageSub: 'CLEARING & FORWARDING SERVICE OFFER'}
  },
  {
    path: '/formC36/:id/clearing-offer/:offerId',
    name: 'clearing-offer-id',
    component: ClearingForwarding,
    meta: {authenticated: true, pageSub: 'CLEARING & FORWARDING SERVICE OFFER'}
  },
  // {
  //   path: '/formC36/:id/data-from-origin/',
  //   name: 'data-from-origin',
  //   component: DataFromOrigin,
  //   meta: {authenticated: true, pageSub: 'DATA FROM ORIGIN COUNTRY'}
  // },
  {
    path: '/formC36/:id/print-new',
    name: 'formC36-new',
    component: FormC36New,
    meta: {authenticated: true, pageSub: 'DECLARATION OF PARTICULARS RELATING TO CUSTOMS VALUE'},
    props: true
  },
  {
    path: '/formC36/:id/print-old',
    name: 'formC36-old',
    component: FormC36Old,
    meta: {authenticated: true, pageSub: 'DECLARATION OF PARTICULARS RELATING TO CUSTOMS VALUE'},
    props: true
  },
  {
    path: '/packing-list/:id',
    name: 'packing-list',
    component: FormC36,
    meta: {authenticated: true, pageSub: 'DECLARATION OF PARTICULARS RELATING TO CUSTOMS VALUE'},
    props: true
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});


router.beforeEach((to, from, next)=>{
  let pageTitle = to.meta?.pageTitle ?? (to.name ?? '').toUpperCase();
  document.title = pageTitle;
  next();
});

export default router
