<template>
  <div class="col-sm-auto sticky-top no-gutters sidebar-bg">
    <div
      class="d-flex flex-sm-column flex-row flex-nowrap align-items-center sticky-top"
    >
      <div class="company-logo mt-2">
        <router-link :to="{ name: 'home' }"
          ><img src="@/assets/images/logo.png"
        /></router-link>
      </div>
      <div class="divider-line"></div>
      <ul
        class="nav nav-pills nav-flush flex-sm-column flex-row flex-nowrap mb-auto mx-auto text-center justify-content-between w-100 px-3 align-items-center"
      >
        <li class="nav-item">
          <router-link :to="{ name: 'dashboard' }">
            <i class="fas fa-home"></i>
          </router-link>
        </li>
        <li class="nav-item">
          <router-link :to="{ name: 'rates' }">
            <i class="fas fa-dollar-sign"></i>
          </router-link>
        </li>
        <li class="nav-item">
          <router-link :to="{ name: 'documents' }">
            <i class="far fa-folder-open"></i>
          </router-link>
        </li>
        <li class="nav-item">
          <router-link :to="{ name: 'shipments' }">
            <i class="fas fa-ship"></i>
          </router-link>
        </li>
        <li class="nav-item">
          <router-link :to="{ name: 'integration' }">
            <i class="fas fa-network-wired"></i>
          </router-link>
        </li>
      </ul>
      <div class="divider-line"></div>
      <div class="d-flex logged-user-div">
        <div class="user-logged m-1">
          <router-link :to="{ name: 'profile' }">
            <i class="fas fa-circle-user"></i>
          </router-link>
        </div>
        <div class="user-logout m-1" @click="logout">
          <i class="fas fa-power-off"></i>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { inject } from "vue";
const logout = inject('userLogout');
</script>
<style>
.company-logo {
  background-color: #fff;
  padding: 8px;
  border-radius: 15px;
  box-shadow: 0px 0px 5px #1e2344;
  align-items: center;
  text-align: center;
  justify-content: center;
}

.user-logged, .user-logout {
  font-size: 0.8em;
  padding: 3px 8px;
  cursor: pointer;
  background-color: #002671;
  box-shadow: 0x 0px 5px #101620;
  border-radius: 10px;
  color: #fff;
}

.user-logout {
  background-color: #a2001f;
}

.nav-item a {
  width: 50px;
  display: block;
  font-size: 1.2em !important;
  padding: 10px;
  border-radius: 10px;
  background-color: rgba(255, 255, 255, 0.1);
  color: rgba(255, 255, 255, 0.85);
  margin: 10px 0;
}

.nav-item a.router-link-active {
  color: #fff;
  background-color: rgba(255, 255, 255, 0.05);
  border: 1px solid rgba(255, 255, 255, 0.5);
}

.divider-line {
  width: 75%;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  margin: 15px auto;
}

.company-logo img {
  max-width: 35px;
}

.sidebar-bg {
  background-color: #112199;
}
</style>