<template>
	<div class="document-toolbar p-1">
		<div
			class="btn-toolbar"
			role="toolbar"
			aria-label="Toolbar with button groups"
		>
			<div
				class="btn-group me-2 btn-group-sm border-none"
				role="group"
				aria-label="First group"
			>
				<button class="btn"></button>
				<button
					type="button"
					class="btn btn-outline-primary"
					@click="fetchDocuments"
				>
					<span v-if="loadingDocuments"
						><i class="fas fa-rotate-right fa-spin"></i
					></span>
					<span v-else><i class="fas fa-rotate-right"></i></span>
					Refresh
				</button>
				<a
					type="button"
					class="btn btn-outline-primary"
					:href="SERVER + '/forms/export'"
				>
					<i class="fas fa-download"></i> Export All
				</a>
				<div class="btn-group btn-group-sm">
					<button
						class="btn btn-outline-default dropdown-toggle"
						type="button"
						data-bs-toggle="dropdown"
						aria-expanded="false"
					>
						<i class="fas fa-add"></i> New Form
					</button>
					<ul class="dropdown-menu">
						<li>
							<router-link
								:to="{ name: 'formC36', params: { id: 'create' } }"
								class="dropdown-item"
								><small>Form C36</small></router-link
							>
						</li>
						<li>
							<router-link
								:to="{ name: 'packing-list', params: { id: 'create' } }"
								class="dropdown-item"
								><small>Packing List</small></router-link
							>
						</li>
					</ul>
				</div>
				<div class="btn-group btn-group-sm">
					<button
						class="btn btn-outline-default dropdown-toggle"
						type="button"
						data-bs-toggle="dropdown"
						aria-expanded="false"
					>
						Currency: <strong>{{ defaultCurrency }}</strong>
					</button>
					<ul class="dropdown-menu">
						<li
							v-for="(curr, key, index) in getCurrencies()"
							:key="index"
							@click="defaultCurrency = key"
							style="cursor: pointer"
						>
							<span class="dropdown-item"
								><small>{{ curr.name }}</small></span
							>
						</li>
					</ul>
				</div>
				<div class="input-group input-group-sm" style="width: 300px">
					<input
						v-model="documentFilter"
						type="search"
						@keyup="fetchDocuments"
						class="form-control form-control-sm"
						placeholder="Search..."
						style="width: 250px; margin: 0 auto"
					/>
				</div>
				<button class="btn"></button>
			</div>
		</div>
	</div>
	<div class="table-responsive" v-if="filteredDocuments?.length">
		<table class="table table-bordered">
			<thead>
				<tr>
					<th style="width: 1%"></th>
					<th><strong>Type</strong></th>
					<th><strong>Doc. No.</strong></th>
					<th><strong>PIN No.</strong></th>
					<th><strong>Invoice</strong></th>
					<th><strong>Invoice Date</strong></th>
					<th><strong>Seller</strong></th>
					<th><strong>Status</strong></th>
					<th style="text-align: right"><strong>Value</strong></th>
					<th></th>
				</tr>
			</thead>
			<tbody>
				<tr v-for="(document, index) in filteredDocuments" :key="document.id" :class="{'text-danger': document.suspicion_flag == 1}">
					<td>
						{{ index + 1 }}</td>
					<td>{{ document.doc_type ?? "Form C36" }}</td>
					<td>
						<i class="fas fa-exclamation-triangle" v-if="document.suspicion_flag == 1"></i> IMD-{{ document.id }}</td>
					<td>{{ document.buyer }}</td>
					<td>{{ document.invoice_number }}</td>
					<td>{{ document.invoice_date }}</td>
					<td>{{ document.seller }}</td>
					<td>{{ document.process_status }}</td>
					<td style="text-align: right">
						<strong>
							{{
								document.invoice_currency +
								parseFloat(
									parseFloat(document.customs_value).toFixed(2)
								).toLocaleString()
							}}
						</strong>
					</td>
					<td>
						<div class="icon-set">
							<div class="ic" v-if="isFormClosed(document)">
								<router-link
									:to="{ name: 'formC36', params: { id: document.id } }"
								>
									<i class="fas fa-eye text-success"></i>
								</router-link>
							</div>
							<div class="ic" v-else>
								<router-link
									:to="{ name: 'formC36', params: { id: document.id } }"
								>
									<i class="fas fa-edit text-danger"></i>
								</router-link>
							</div>
							<div class="ic">
								<a
									class="dropdown-item"
									:href="SERVER + '/forms/export/' + document.id"
								>
									<i class="fas fa-download text-primary"></i>
								</a>
							</div>
							<div
								class="ic is-dropdown"
								title="Make a Service Offer"
							>
								<span>
									<i class="fas fa-comments-dollar text-success"></i>
								</span>
								<div class="the-dropdown">
									<div class="line">
										<router-link
											:to="{ name: 'insurance-offer', params: { id: document.id } }"
										>
                    <i class="fas fa-shield-alt text-green"></i> Offer Insurance Service
										</router-link>
									</div>
									<div class="line">
										<router-link
											:to="{ name: 'consolidation-offer', params: { id: document.id } }"
										>
                    <i class="fas fa-cubes text-info"></i> Offer Consolidation Service
										</router-link>
									</div>
									<div class="line">
										<router-link
											:to="{ name: 'clearing-offer', params: { id: document.id } }"
										>
                    <i class="fas fa-dolly text-primary"></i> Offer C&F Service
										</router-link>
									</div>
								</div>
							</div>
						</div>
					</td>
				</tr>
			</tbody>
			<tfoot>
				<tr>
					<td></td>
					<td></td>
					<td></td>
					<td></td>
					<td></td>
					<td></td>
					<td></td>
					<td style="text-align: right"><strong>TOTAL</strong></td>
					<td style="text-align: right">
						<strong>{{
							defaultCurrency +
							parseFloat(parseFloat(totalValue).toFixed(2)).toLocaleString()
						}}</strong>
					</td>
					<td></td>
				</tr>
			</tfoot>
		</table>
	</div>
	<div v-else class="p-5">
		<div class="text-sm" v-if="loadingDocuments">
			<i class="fas fa-spinner fa-spin"></i> Loading Forms...
		</div>
		<div class="text-sm text-red" v-if="loadingError">
			<i class="fas fa-exclamtion-triangle"></i> {{ loadingError }}
		</div>
		<div class="text-sm" v-else>
			<span class="text-danger" v-if="documentFilter.value"
				><i class="fas fa-info-circle"></i> No documents matching your search
				term : <em>{{ documentFilter }}</em></span
			>
			<span v-else
				><i class="fas fa-info-circle"></i> No forms available yet.</span
			>
		</div>
	</div>
</template>
<script setup>
import { UnitConverter, getCurrencies } from "@/scripts/data";
import axios from "axios";
import { computed, inject, onMounted, ref } from "vue";

const documents = ref([]);
const loadingDocuments = ref(false);
const loadingError = ref("");
const defaultCurrency = ref("KES");

const documentFilter = ref("");

const SERVER = inject("SERVER_LOCATION");
const USER = inject("loggedInUser");

const filteredDocuments = computed(() => {
	return documents.value;
});

const totalValue = computed(() => {
	let total = 0;
	for (let d of documents.value) {
		total += parseFloat(
			UnitConverter.CurrencyConverter(
				d.customs_value,
				d.invoice_currency,
				defaultCurrency.value
			)
		);
	}
	return total;
});

const fetchDocuments = () => {
	if (loadingDocuments.value) {
		return false;
	}
	loadingDocuments.value = true;
	documents.value = [];

	axios
		.get(
			SERVER.value +
				"/api/forms" +
				(documentFilter.value ? "?search=" + documentFilter.value : ""),
			{ headers: { Authorization: `Bearer ${USER.value.token}` } }
		)
		.then((response) => {
			loadingDocuments.value = false;
			if (response.data.status) {
				documents.value = response.data.forms.data;
				loadingError.value = null;
			}
		})
		.catch((err) => {
			loadingError.value = err.message;
			loadingDocuments.value = false;
		});
};

const isFormClosed = (FORM) => {
	let status = FORM.process_status;
	return (
		status != "" &&
		status != "draft" &&
		status != "awaiting_importer_verification"
	);
};

onMounted(() => {
	fetchDocuments();
});
</script>
<style>
.document-toolbar {
	background-color: #fff;
}
.document-toolbar .btn {
	color: #333;
	border: none;
	border-radius: 5px !important;
	margin: 2px 5px;
}

.document-toolbar .btn i {
	font-size: 0.8em;
}

.document-toolbar .btn:hover {
	background-color: #112199;
	color: #fff;
}

.table th,
.table td {
	border-width: 1px;
	vertical-align: middle;
	padding: 2px 7px;
	font-size: 12px;
}

.table thead th {
	background-color: #fff;
	font-weight: 400;
}

.table td .icon-set {
	display: flex;
}

.table td .icon-set .ic {
	text-align: center;
	line-height: 16px;
	padding: 3px 8px;
	font-size: 0.9em;
	border-radius: 5px;
	cursor: pointer;
	margin: 0px 2px;
}

.table td .icon-set .ic.is-dropdown{
  position: relative;
}

.table td .icon-set .ic.is-dropdown .the-dropdown{
  display: none;
  position: absolute;
  bottom: 0; right: 0;
  font-size: 11px;
  background-color: #fff;
  border-radius: 5px;
  border:1px solid #eee;
}

.table td .icon-set .ic.is-dropdown:hover .the-dropdown{
  display: unset;
}

.table td .icon-set .ic:hover {
	background-color: rgba(0, 0, 0, 0.1);
}

tr.text-danger, tr.text-danger td{
	color: rgb(172, 8, 8);
	font-weight: 600;
}

.the-dropdown .line{
  width: 100%;
  padding: 5px 10px;
  font-size: 12px;
  text-wrap: nowrap;
  text-align: left;
}


.the-dropdown .line *{
  text-decoration: none;
  color: #343434;
}

.the-dropdown .line:hover{
	background-color: rgba(0, 0, 0, 0.05);
  cursor: pointer;
}

</style>
