<template>
	<slot></slot>
</template>

<script>
export default {

}
</script>
<style>
html, body{
  background-color: #f2f2f2 !important;
}
</style>