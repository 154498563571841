<template>
  <div class="row item-row mt-1 no-gutters-sm" v-if="ITEM.visible">
    <div class="col-sm-3">
      <label class="control-label">Item</label>
      <input
        type="text"
        @input="itemUpdated"
        v-model="ITEM.name"
        class="text-input small item-name"
        placeholder="Item Name..."
      />
    </div>
    <div class="col-sm-2">
      <label class="control-label">Quantity</label>
      <input
        type="number"
        @input="itemUpdated"
        v-model="ITEM.quantity"
        min="0"
        step="any"
        class="text-input small item-quantity calculate"
        placeholder="Quantity..."
      />
    </div>
    <div class="col-sm-2">
      <label class="control-label">Unit Price</label>
      <input
        type="number"
        @input="itemUpdated"
        v-model="ITEM.price"
        min="0"
        step="any"
        class="text-input small item-price calculate"
        placeholder="Price..."
      />
    </div>
    <div class="col-sm-3">
      <label class="control-label">Category</label>
      <select
        class="small item-category"
        @input="itemUpdated"
        v-model="ITEM.item_category"
      >
        <option value="">Select Category...</option>
        <optgroup :label="cat.category_name" v-for="cat in import_categories" :key="cat.category_name">
          <option v-for="sub in cat.subcategories" :key="cat.category_name+sub" :value="cat.category_name +','+ sub">{{ sub }}</option>
        </optgroup>
      </select>
    </div>
    <div class="col-sm-2">
      <label class="control-label">Condition</label>
      <select
        class="small item-condition"
        @input="itemUpdated"
        v-model="ITEM.item_condition"
      >
        <option value="">Select Condition...</option>
        <option value="New">New</option>
        <option value="Used">Used</option>
      </select>
    </div>

    <div class="col-md-12 mb-1">
      <div
        class="p-2 text-center text-primary"
        style="cursor: pointer"
        @click="toggleMoreOptions"
      >
        <span v-if="!viewMoreOptions"
          ><i class="fas fa-angle-double-down"></i
        ></span>
        <span v-else><i class="fas fa-angle-double-up"></i></span> More Item
        Descriptions
        <div
          class="float-right pull-right text-align-right"
          style="color: #545454"
        >
          <b>Total Item Cost</b> - <strong class="document-currency"></strong>
          <strong class="item-total">{{ ITEM.total_price }}</strong>
          <input type="hidden" v-model="ITEM.total_price" class="document-total" />
        </div>
      </div>
      <div v-if="viewMoreOptions">
        <div class="row">
          <div class="col-sm-2">
            <label class="control-label h25">Make</label>
            <input
              type="text"
              @input="itemUpdated"
              v-model="ITEM.make"
              class="text-input small item-make"
              placeholder="Make..."
            />
          </div>
          <div class="col-sm-2">
            <label class="control-label h25">Model</label>
            <input
              type="text"
              @input="itemUpdated"
              v-model="ITEM.model"
              class="text-input small item-model"
              placeholder="Model..."
            />
          </div>
          <div class="col-sm-6" style="padding-left: 1% !important">
            <label class="control-label"
              >Dimensions
              <ButtonCheckbox
                :multiple="false"
                v-model="dimensions.format"
                :options="['CM', 'M']"
              />
            </label>
            <input
              @input="itemUpdated"
              type="number"
              min="0"
              step="any"
              data-type="L"
              v-model="dimensions.L"
              class="text-input small item-dimensions"
              placeholder="L"
            />
            <input
              @input="itemUpdated"
              type="number"
              min="0"
              step="any"
              data-type="W"
              v-model="dimensions.W"
              class="text-input small item-dimensions"
              placeholder="W"
            />
            <input
              @input="itemUpdated"
              type="number"
              min="0"
              step="any"
              data-type="H"
              v-model="dimensions.H"
              class="text-input small item-dimensions"
              placeholder="H"
            />
          </div>
          <div class="col-sm-2">
            <label class="control-label"
              >Weight
              <ButtonCheckbox
                :multiple="false"
                v-model="weight.format"
                :options="['KG', 'LB']"
              />
            </label>
            <input
              @input="itemUpdated"
              type="number"
              step="any"
              v-model="weight.value"
              min="0"
              class="text-input small item-weight calculate"
              placeholder="Weight..."
            />
          </div>
        </div>
      </div>
    </div>
    <span class="removeIcon" @click="removeItem">
      <i class="fas fa-trash"></i>
    </span>
  </div>
</template>

<script setup>
import ButtonCheckbox from "@/components/layout/Form/ButtonCheckbox";
import { import_categories } from '@/scripts/data';
import { computed, defineProps, ref, defineModel } from "vue";

const viewMoreOptions = ref(false);

const props = defineProps(['data']);

const ITEM = defineModel({default:{}});

ITEM.value = props.data;

if(ITEM.value.dimensions == null){
  ITEM.value.dimensions = { L: "", W: "", H: "", format: originalDimensionsFormat };
}

if(ITEM.value.weight == null){
  ITEM.value.weight = {value: "", format: originalWeightFormat};
}

if(ITEM.value.visible == null){
  ITEM.value.visible = true;
}

const weight = ref(ITEM.value.weight)
const dimensions = ref(ITEM.value.dimensions)

const removeItem = () => {
  ITEM.value.visible = false;
}

const total_price = computed(() => {
  if (ITEM.value.quantity == 0 || ITEM.value.price == 0) {
    return 0;
  }
  if (isNaN(ITEM.value.quantity) || isNaN(ITEM.value.price)) {
    return 0;
  }
  return parseFloat(ITEM.value.price) * parseFloat(ITEM.value.quantity);
});

const itemUpdated = () => {
  ITEM.value.total_price = total_price.value;
  // ITEM.value.weight = weight.value;
  // ITEM.value.dimensions = dimensions.value;
}

const toggleMoreOptions = () => {
  viewMoreOptions.value = !viewMoreOptions.value;
};

</script>

<style>
.h25 {
  height: 25px;
}

.row.item-row {
  position: relative;
  clear: both !important;
}

.row.item-row  .removeIcon {
  position: absolute;
  top: -5px;
  right: 0px;
  width: 25px;
  height: 18px;
  color: rgb(146, 109, 109);
  font-weight: 500;
  transition: 500ms color linear;
}

.row.item-row .removeIcon:hover {
  cursor: pointer;
  transition: 500ms color linear;
  color: crimson;
}
</style>