<template>
	<div class="modal-background" v-if="modal.visible">
		<div class="card" style="width: 450px; max-width: 95%">
			<div class="card-body">
				<h5 class="card-title">
					<img
						:src="`https://flagsapi.com/${ship.flag.slice(0, 2)}/shiny/24.png`"
						v-if="ship.flag"
					/>
					Track Vessel
				</h5>
				<h6 class="card-subtitle mb-2 text-muted">
					<i class="fas fa-ship text-info"></i> {{ modal.vessel.name }}
					<span class="badge bg-primary">MMSI</span>
					<small>{{ modal.vessel.mmsi }}</small>
				</h6>
				<div class="py-5 px-1" v-if="!modal.loading">
					<div class="my-1">
						<span class="badge badge-pill px-3 bg-black" style="font-size: 10px"
							>Call Sign</span
						>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
						<small
							><strong>{{ ship.call_sign }}</strong></small
						>
					</div>
					<div class="my-1">
						<span class="badge badge-pill bg-teal" style="font-size: 10px"
							>IMO</span
						>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
						<small
							><strong>{{ ship.imo }}</strong></small
						>
					</div>
					<div class="my-1">
						<span class="badge badge-pill bg-blue" style="font-size: 10px"
							>Location</span>
							&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
						<small>
							<strong>
								<a :href="`https://www.myshiptracking.com/?mmsi=${modal.vessel.mmsi}`" target="_blank">Go to Location</a>
							</strong
						></small>
					</div>
					<div class="py-2 px-1" v-if="ship.id">
						<div v-if="fetchingLocationData">
							<i class="fas fa-spin fa-spinner"></i> Loading Vessel Location
							information...
						</div>
						<div v-else></div>
					</div>
				</div>
				<div class="py-5 px-1" v-else>
					<i class="fas fa-spinner fa-spin"></i> Loading Vessel Information...
				</div>
				<span
					href="#"
					class="card-link text-danger"
					style="cursor: pointer"
					@click="closeTrackingModal"
					>Close</span
				>
			</div>
		</div>
	</div>
</template>

<script setup>
import { defineModel, onBeforeUpdate, ref } from "vue";
import { fetchVesselDetailsByMMSI } from "@/scripts/globalfishing";

const fetchingLocationData = ref(false);

const modal = defineModel({
	default: {
		visible: true,
		loading: true,
		vessel: { name: "", mmsi: "" },
	},
});

const ship = ref({
	call_sign: "",
	flag: "",
	imo: "",
	id: "",
});

onBeforeUpdate(() => {
	if (modal.value.vessel.mmsi != "" && modal.value.loading) {
		const details = fetchVesselDetailsByMMSI(modal.value.vessel);
		details
			.then((response) => response.json())
			.then((result) => {
				modal.value.loading = false;
				if (result.entries.length > 0) {
					var entry = result.entries[0];
					var selfRep = entry.selfReportedInfo[0];
					if(modal.value.vessel.name == ""){
						modal.value.vessel.name = selfRep?.shipname;
					}
					ship.value = {
						call_sign: selfRep?.callsign ?? "-",
						imo: selfRep?.imo ?? "-",
						flag: selfRep?.flag ?? "-",
						id: selfRep?.id ?? "-",
					};
				}
			})
			.catch((error) => console.error(error));
	}
});

const closeTrackingModal = () => {
	modal.value = {
		visible: false,
		loading: false,
		vessel: { name: "", mmsi: "" },
	};

	ship.value = {
		call_sign: "",
		flag: "",
		imo: "",
		id: "",
	};
};
</script>

<style></style>
