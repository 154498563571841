const baseURL = "https://gateway.api.globalfishingwatch.org/";
const Token = "eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCIsImtpZCI6ImtpZEtleSJ9.eyJkYXRhIjp7Im5hbWUiOiJBQ0lEIFRlc3QiLCJ1c2VySWQiOjMzNzgwLCJhcHBsaWNhdGlvbk5hbWUiOiJBQ0lEIFRlc3QiLCJpZCI6MTQyMSwidHlwZSI6InVzZXItYXBwbGljYXRpb24ifSwiaWF0IjoxNzEzMzQ0NDkyLCJleHAiOjIwMjg3MDQ0OTIsImF1ZCI6ImdmdyIsImlzcyI6ImdmdyJ9.mfB_2jXRaRSOhpLm_51vnv0pgYfIEr0-sWVzboR2lpUlmGqwJxIjXnbLOpaTJrFVK5lVybs7ElndjJkqxP6zSi-ZKOSwX-xTMCb4m9zqFEsddiwjtvClyIx_lq1hU7uLl1cFElrdBTpnYqU-DLuywfR_hzYOVXB-N0VkdVB0z5YKsRjttSzDUJMZGGk0gCuca3xCvweBtsLngXss0b5FrMaI9tIj9rCBZ5fl2FCEkVuRlHkPGIo8mkxeCMIIRH3cgLc6NRCkrfTy5kyqMaT4MAHlp4HdcjqhhxCxBvA4igrKPgVCIrjtuJZy_Padc4UPlV0Vb9a80ZFcNX4anw1XNJkDJBP_kNuABlFph0iWdVF8FMpCqH6e90Q7odFpUxfgd5VRTvwlWKYMFXtT2NDGd1K60PXSEBMfreY-vc97mErNzMg8x0ExrYp8FAg7btOBklsjsQLl-AggDlVvvgpUktNW2y6mXjDvD0RKaMX-cihbCAcvecThkCVmrEoybc6V";

export const fetchVesselDetailsByMMSI = (vessel) => {
	const myHeaders = new Headers();
	myHeaders.append("Authorization", "Bearer "+Token);

	const requestOptions = {
		method: "GET",
		headers: myHeaders,
		redirect: "follow"
	};

	return fetch(baseURL+"/v3/vessels/search?query="+vessel.mmsi+"&datasets[0]=public-global-vessel-identity:latest&includes[0]=MATCH_CRITERIA&includes[1]=OWNERSHIP&includes[2]=AUTHORIZATIONS", requestOptions);
}

// module.exports = { fetchVesselDetailsByMMSI };