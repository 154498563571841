<template>
	<div class="p-2">
		<div v-if="formMsgDiv" class="m-1" v-html="formMsgDiv"></div>
		<form class="row" @submit.prevent="saveFormDetails">
			<div class="col-md-4 col-sm-12 pb-2">
				<div class="card">
					<div class="card-header">
						<i class="fas fa-user-secret text-info"></i> Consignee Details
					</div>
					<div class="card-body">
						<div class="form-group">
							<label class="control-label">Name</label>
							<input
								v-model="FORM.consignee.name"
								type="text"
								placeholder="Name..."
								class="text-input small"
							/>
						</div>
						<div class="form-group">
							<label class="control-label">Email</label>
							<input
								v-model="FORM.consignee.email"
								type="text"
								placeholder="Email..."
								class="text-input small"
							/>
						</div>
						<div class="form-group">
							<label class="control-label">Phone</label>
							<input
								v-model="FORM.consignee.phone"
								type="text"
								placeholder="Phone..."
								class="text-input small"
							/>
						</div>
						<div class="form-group">
							<label class="control-label">Destination</label>
							<input
								v-model="FORM.consignee.destination"
								type="text"
								placeholder="Cargo Destination..."
								class="text-input small"
							/>
						</div>
					</div>
				</div>
			</div>
			<div class="col-md-8 col-sm-12 pb-2">
				<div class="card">
					<div class="card-header">
						<i class="fas fa-cubes text-default"></i> Capture Origin Data
					</div>
					<div class="card-body">
						<div class="form-group">
							<label class="control-label">Invoice Number</label>
							<input
								type="text"
								class="form-control"
								placeholder="Invoice Number..."
								v-model="FORM.invoice_number"
								style="max-width: 300px"
							/>
						</div>
						<div class="items-list mt-2">
							<table class="table table-condensed table-collapsed table-sm">
								<thead>
									<tr>
										<th>Item Description</th>
										<th>Quantity</th>
										<th>Item Price</th>
										<th>Total Weight({{ c36.form_weight_format }})</th>
										<th>Total Price({{ c36.invoice_currency }})</th>
									</tr>
								</thead>
								<tbody>
									<tr v-for="(i, k) in ITEMS" :key="k">
										<td>
											<div class="form-group">
												<textarea
													v-model="i.name"
													type="date"
													style="min-width: 200px;"
													class="text-input small"
												></textarea>
											</div>
										</td>
										<td>
											<div class="form-group">
												<input
													v-model="i.quantity"
													type="number"
													step="any"
													class="text-input small"
												/>
											</div>
										</td>
										<td>
											<div class="form-group">
												<input
													v-model="i.price"
													type="number"
													step="any"
													class="text-input small"
												/>
											</div>
										</td>
										<td>
											<div class="form-group">
												<input
													v-model="i.weight"
													type="number"
													step="any"
													class="text-input small"
												/>
											</div>
										</td>
										<td>
											<div class="form-group">
												<input
													v-model="i.total"
													step="any"
													readonly
													type="number"
													class="text-input small"
												/>
											</div>
										</td>
									</tr>
									<tr>
										<td colspan="5" class="p-1" @click="addRow">
											<span class="btn btn-transparent text-primary">
												<i class="fas fa-plus"></i> Add Item
											</span>
										</td>
									</tr>
								</tbody>
							</table>
						</div>
						<br />
						<div class="d-flex justify-space-between">
							<h6>
								<i class="fas fa-money-bill-wave-alt"></i> ITEM COST:
								<span class="text-red">{{
									c36.invoice_currency + "" + parseFloat(itemCost).toFixed(2)
								}}</span>
							</h6>
							<h6>
								<i class="fas fa-money-check-alt"></i> TOTAL SHIPPING COST:
								<span class="text-red">{{
									c36.invoice_currency +
									"" +
									parseFloat(c36.customs_value).toFixed(2)
								}}</span>
							</h6>
							<h6>
								<i class="fas fa-balance-scale-right"></i> TOTAL WEIGHT:
								<span class="text-red">{{ itemWeight+''+c36.form_weight_format }}</span>
							</h6>
						</div>
						<div clas="form-group">
							<h6>
								<i class="fas fa-money-bill-wave-alt"></i>DECLARED ITEM COST:
								<span class="text-red">{{
									c36.invoice_currency + "" + parseFloat(c36ItemCost).toFixed(2)
								}}</span>
							</h6>
						</div>
					</div>
				</div>
				<br>
				<div class="right">
					<button class="btn btn-success btn-sm rounded-pill px-4"><i class="fas fa-save"></i> SAVE</button>
				</div>
			</div>
		</form>
	</div>
</template>
<script setup>
import { computed, inject, onMounted, ref } from "vue";

import { fetchDataOrigin, postDataOrigin } from "@/scripts/data";

import { useRoute, useRouter } from "vue-router";

const router = useRouter();
const route = useRoute();

const c36 = ref({});
const USER = inject("loggedInUser");
const SERVER_LOCATION = inject("SERVER_LOCATION");
const SERVER = SERVER_LOCATION.value;
const ITEMS = ref([]);
const item = {
	name: "",
	quantity: 0,
	price: 0,
	weight: 0,
	total: 0,
};
const FORM = ref({
	id: null,
	invoice_number: "",
	form_c36_id: 0,
	origin_country: "",
	port_of_origin: "",
	consignee: {
		name: "",
		email: "",
		phone: "",
		pin_number: "",
		destination: "",
		comments: "",
	},
	origin: "",
	destination_country: "",
	items: [],
	total_cost: 0,
	declared_total_cost: 0,
	total_weight: 0,
	total_cost_currency: "",
	total_weight_uom: 0,
});

const props = defineProps(["id"]);

const {id} = props;

const addRow = () => {
	let prevItems = ITEMS.value;
	prevItems.push({ ...item });

	ITEMS.value = prevItems;
};

onMounted(async () => {
	if (parseInt(id) > 0) {
		let dataT = await fetchDataOrigin(SERVER, id, USER.value.token);
		c36.value = dataT.origin.form_c36;
		if(dataT.origin.is_blank == null){
			FORM.value = dataT.origin;
			ITEMS.value = dataT.origin.items.length ? dataT.origin.items : populateItems(JSON.parse(c36.value.items));
			console.log(ITEMS.value);
		}
		else{
			populateItems(c36.value.items);
		}

		FORM.value.form_c36_id = id;
		
		FORM.value.consignee.name = c36.value.name_of_importer;
		FORM.value.consignee.email = c36.value.consignee_email;
		FORM.value.consignee.phone = c36.value.consignee_telephone;
		FORM.value.consignee.pin_number = c36.value.buyer;
		FORM.value.consignee.destination =
			c36.value.destination_town +
			", " +
			c36.value.destination_county +
			", " +
			c36.value.destination_country;
	}
});

const populateItems = (items)=> {
	let itemx = [];
	for(let it of items ?? []){
		itemx.push({
			name: `${it.item_category}, ${it.name}, ${it.make}, ${it.model} (Condition:${it.item_condition})`, 
			quantity: 0,
			price: 0,
			weight: 0,
			total: 0,
		})
	}
	ITEMS.value = itemx;
}

const itemCost = computed(() => {
	let price = 0;
	let itemsN = [];
	for (let it of ITEMS.value ?? []) {
		let linePrice = parseFloat(it.quantity ?? 0) * parseFloat(it.price ?? 0);
		it.total = linePrice;

		itemsN.push(it);

		price += linePrice;
	}
	ITEMS.value = itemsN;

	return price;
});

const c36ItemCost = computed(() => {
	let price = 0;
	let its = typeof c36.value.items == 'string' ? JSON.parse(c36.value.items) : c36.value.items;
	for (let it of its ?? []) {
		let linePrice = parseFloat(it.quantity ?? 0) * parseFloat(it.price ?? 0);
		it.total = linePrice;

		price += linePrice;
	}

	return price;
});

const itemWeight = computed(() => {
	let wght = 0;
	for (let it of ITEMS.value ?? []) {
		let lwght = parseFloat(it.weight ?? 0);
		wght += lwght;
	}

	return wght.toFixed(2);
});

const saveFormDetails = async () => {
	FORM.value.total_cost = itemCost;
	FORM.value.declared_total_cost = c36ItemCost;
	FORM.value.total_weight = itemWeight;
	FORM.value.items = ITEMS.value;
	await postDataOrigin(FORM, USER.value.token, SERVER);
};
</script>
<style>
html {
	background-color: #fcfcfc !important;
}

.muted {
	color: #bbb;
}

.og {
	margin-right: 5px;
}

body {
	color: #2851e6;
}

.card-body label {
	margin-top: 10px;
	width: 100%;
	text-align: left;
}

.card-body label.h25 {
	min-height: 25px;
}

.card {
	overflow: hidden;
	border-radius: 3px !important;
	background-color: #fff;
	box-shadow: 1px 4px 5px #ddd;
	text-align: left !important;
	border: 1px solid #b0b7d2;
}

.card.ns {
	border: none;
	box-shadow: none;
}

.card.complete {
	border: 1px solid #1cad1e;
}

.card .card-header {
	font-weight: 600;
	font-size: 14px;
	background-color: #fff !important;
	border-bottom: 1px solid rgba(0, 0, 0, 0.0863);
}

.card .card-footer {
	background-color: #fff !important;
}

.card .card-footer.bdt-0 {
	border-top: none !important;
}

.card .card-body label:not(.other-toggle-tab label) {
	overflow-x: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
	font-weight: 600;
}

.card .card-body h6 {
	font-size: 12px;
	font-weight: 600;
}

.form36 {
	font-size: 11px !important;
}

form.row {
	width: 100%;
}

textarea {
	font-size: 12px;
	width: 100%;
}

select.small,
.text-input.small {
	margin: 0px;
	border-radius: 1px;
	background-color: #fff;
	border: 1px solid #aaa;
	padding: 5px 7px;
	width: 100%;
}

.text-input.item-dimensions {
	width: 31% !important;
}

.text-input.total-dimensions {
	width: 30.9% !important;
}

.fas.fa05x {
	font-size: 10px !important;
}

.card-body .btn {
	padding-top: 0px !important;
	margin-top: 0px !important;
	font-size: 10px;
	font-weight: 600;
	float: right;
}

.row.item-row {
	position: relative;
	clear: both !important;
}

.row.item-row .removeIcon {
	position: absolute;
	top: -5px;
	right: -5px;
	width: 25px;
	height: 18px;
	color: rgb(146, 109, 109);
	font-weight: 500;
	transition: 500ms color linear;
}

.row.item-row .removeIcon:hover {
	cursor: pointer;
	transition: 500ms color linear;
	color: crimson;
}

input[type="file"].small {
	border: 0px !important;
}

#cargo-items-list .file-attachment:not(:last-child) {
	border-bottom: 1px solid #eee;
	margin-bottom: 20px;
}

#cargo-items-list [class*="col-sm-"],
.no-g [class*="col-sm-"] {
	padding-left: 1px !important;
	padding-right: 1px !important;
}

.ka-section .charge-amount {
	margin-bottom: 20px;
}

span.radioer {
	background-color: rgba(0, 0, 0, 0.12);
	color: #787878;
	padding: 3px 0px 4px;
	margin-left: 10px;
}

span.radioer span {
	font-size: 10px;
	padding: 3px 5px 4px;
	cursor: pointer;
	transition: all 0.1s;
}

span.radioer span.selected {
	background-color: rgb(0, 61, 115);
	transition: all 0.1s;
	color: #fff;
}

.items-list div:nth-child(odd) {
	background-color: rgba(0, 0, 0, 0.03);
	padding: 8px 10px;
	margin: 2px 0px;
	border-radius: 8px;
}

.items-list div:nth-child(even) {
	background-color: rgba(0, 0, 0, 0.07);
	padding: 8px 10px;
	border-radius: 8px;
}
</style>
