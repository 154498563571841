<template>
	<div>
		<div class="p-2 mb-2 text-left">
			<strong style="font-size: 15px;">
				<i class="fab fa-buffer text-muted"></i> Available Offers
				<small class="badge rounded-pill text-bg-danger">{{ offers.length }}</small>
			</strong>
		</div>
		<div v-if="offers.length">
			<table class="table table-sm">
				<caption>Available Offers</caption>
				<thead>
					<tr>
						<th>No</th>
						<th>Service Provider</th>
						<th>Origin</th>
						<th>Destination</th>
						<th>Date</th>
						<th>Offer</th>
						<th></th>
					</tr>
				</thead>
				<tbody>
					<tr v-for="(o, k) in offersFormated" :key="k">
						<td>{{ k+1 }}</td>
						<td>{{ o.consolidator.name }}</td>
						<td>{{ o.origin }}</td>
						<td>{{ o.destination }}</td>
						<td>{{ o.date }}</td>
						<td>{{ o.currency+parseFloat(o.service_cost).toFixed() }}</td>
						<td>
							<div class="btn-group btn-group-sm" role="group" aria-label="Small button group">
								<button type="button" class="btn btn-sm text-info" @click="viewOffer(o.id, o.form_c36_id)"><i class="fas fa-eye"></i></button>
								<button type="button" class="btn btn-sm text-success" @click="acceptOffer(o)" v-if="o.is_accepted_offer == 0">
									<i class="fas fa-check-circle"></i>
								</button>
								<button type="button" class="btn btn-sm text-danger" @click="cancelOffer(o)" v-else>
									<i class="fas fa-cancel"></i>
								</button>
							</div>
						</td>
					</tr>
				</tbody>
			</table>
		</div>
		<div v-else>
			<div v-if="loading">
				<div class="alert alert-secondary text-muted">
					<i class="fas fa-spin fa-spinner"></i> Loading Offers...
					yet.
				</div>
			</div>
			<div v-else>
				<div class="alert alert-secondary text-muted" v-if="id > 0">
					<i class="fas fa-info-circle"></i> No offers added yet.
				</div>
				<div class="alert alert-secondary text-muted" v-else>
					<i class="fas fa-info-circle"></i> Please Complete the Form C36 first.
					yet.
				</div>
			</div>
		</div>
	</div>
</template>

<script setup>
import {
	fetchOffers,postOfferData,createForm
} from "@/scripts/data";
import {
	computed,
	onMounted,
	inject,
	ref,
} from "vue";

import { useRoute, useRouter } from "vue-router";
const router = useRouter();

const loading = ref(false);
const USER = inject("loggedInUser");
const props = defineProps(["typ", "token", "server", "id", "form"]);
const {typ, token, server, id, form } = props;

const offers = ref([]);

const fetchOffersFunc = async () => {
	let OFFERS = await fetchOffers(server, id, token, typ);
	offers.value =  OFFERS?.offers ?? [];
	return true;
}

onMounted(async () => {
	fetchOffersFunc();
});

const offersFormated = computed(() => {
	let arr = [];
	for(let off of offers.value){
		let offJSON = {...off};
		offJSON.form = JSON.parse(offJSON.form);
		offJSON.consolidator = JSON.parse(offJSON.consolidator);
		offJSON.consignee = JSON.parse(offJSON.consignee);

		arr.push(offJSON);
	}
	return arr;
});

const acceptOffer = async (offer) => {
	offer.is_accepted_offer = 1;
	let dd = ref();
	dd.value = offer;
	await postOfferData(dd, token, server);
	fetchOffersFunc();
}

const cancelOffer = async (offer) => {
	offer.is_accepted_offer = 0;
	let dd = ref();
	dd.value = offer;
	await postOfferData(dd, token, server);
	fetchOffersFunc();
}

const viewOffer = (id, formID) => {
	router.push({ name: (typ == 'clearing' ? 'clearing': typ)+"-offer-id", params: { id: formID, offerId: id } });
}

</script>

<style>
	.badge{
		font-weight: 300!important;
	}
</style>
