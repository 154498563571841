<template>
	<div class="form36 p-2">
		<div v-if="formMsgDiv" class="m-1" v-html="formMsgDiv"></div>
		<div class="mt-2 mb-3">
			<div class="d-flex p-2 is-menu">
				<div
					class="tab-toggler"
					:class="{ 'active-link': isActiveTab == 'details' }"
					@click="jumpToTab('details')"
				>
					<i class="fas fa-info-circle"></i> Details
				</div>
				<div
					class="tab-toggler"
					:class="{ 'active-link': isActiveTab == 'insurance' }"
					@click="jumpToTab('insurance')"
				>
					<i class="fas fa-shield-alt"></i> Insurance
				</div>
				<div
					class="tab-toggler"
					:class="{ 'active-link': isActiveTab == 'consolidator' }"
					@click="jumpToTab('consolidator')"
				>
          <i class="fas fa-cubes"></i> Consolidator
				</div>
				<div
					class="tab-toggler"
					:class="{ 'active-link': isActiveTab == 'clearing' }"
					@click="jumpToTab('clearing')"
				>
          <i class="fas fa-dolly"></i> Clearing & Forwading
				</div>
				<div
					class="tab-toggler"
					:class="{ 'active-link': isActiveTab == 'origin' }"
					@click="jumpToTab('origin')"
				>
          <i class="fas fa-shield-alt"></i> Origin Data
				</div>
				<div
					class="tab-toggler"
					:class="{ 'active-link': isActiveTab == 'issues' }" v-if="FORM.suspicion_flag == 1"
					@click="jumpToTab('issues')"
				>
          <i class="fas fa-exclamation-triangle"></i> Issues
				</div>
			</div>
		</div>
		<div v-if="isActiveTab == 'details'">
			<form class="row" @submit.prevent="saveFormDetails">
				<div class="col-lg-6">
					<div class="card">
						<div class="card-header">
							<i class="fas fa-plane-departure text-danger"></i> CONSIGNEE
						</div>
						<div class="card-body">
							<div class="row">
								<div class="col-md-4">
									<div class="form-group">
										<label class="control-label">Country</label>
										<select
											v-model="FORM.seller_country"
											name="seller_country"
											class="text-input small country-list"
										>
											<option value="">Select the Country</option>
											<option
												v-for="country in COUNTRIES"
												:value="country.name"
												:key="country.id"
											>
												{{ country.name }}
											</option>
										</select>
									</div>
								</div>
								<div class="col-md-4">
									<div class="form-group">
										<label class="control-label">Port of Origin</label>
										<input
											v-model="FORM.port_of_origin"
											type="text"
											placeholder="Port of Origin..."
											class="text-input small"
										/>
									</div>
								</div>
								<div class="col-md-4">
									<div class="form-group">
										<label class="control-label">Origin PIN No</label>
										<input
											v-model="FORM.origin_pin"
											type="text"
											placeholder="Origin PIN..."
											class="text-input small"
											name="origin_pin"
										/>
									</div>
								</div>
							</div>
							<div class="row">
								<div class="col-md-4">
									<div class="form-group">
										<label class="control-label">Supplier Name</label>
										<input
											v-model="FORM.seller"
											type="text"
											placeholder="Supplier Name..."
											class="text-input small"
											name="seller"
										/>
										<div class="supplier-autocomplete"></div>
									</div>
								</div>
								<div class="col-md-4">
									<div class="form-group">
										<label class="control-label">Commercial Invoice No.</label>
										<input
											v-model="FORM.invoice_number"
											type="text"
											class="text-input small"
											placeholder="Commercial Invoice Number..."
										/>
									</div>
								</div>
								<div class="col-md-4">
									<div class="form-group">
										<label class="control-label">Commercial Invoice Date</label>
										<input
											v-model="FORM.invoice_date"
											type="date"
											class="text-input small"
										/>
									</div>
								</div>
							</div>
							<div class="row">
								<div class="col-md-4">
									<div class="form-group">
										<label class="control-label">Terms of Delivery</label>
										<select v-model="FORM.terms_of_delivery" class="small">
											<option value="">Select Terms...</option>
											<option value="C&F">C&F</option>
											<option value="C&I">C&I</option>
											<option value="CIF">CIF</option>
											<option value="FOB">FOB</option>
										</select>
									</div>
								</div>
								<div class="col-md-4">
									<label class="control-label">CURRENCY</label>
									<select v-model="FORM.invoice_currency" class="small">
										<option value="">Select the Currency</option>
										<option value="€">EUR</option>
										<option value="KES">KES</option>
										<option value="£">STERLING POUNDS</option>
										<option value="TSH">TSH</option>
										<option value="UGX">UGX</option>
										<option value="$">USD</option>
									</select>
								</div>
							</div>
							<div class="row">
								<div class="col-md-12">
									<div class="form-group">
										<DocumentAttachments v-model="attachments" />
									</div>
								</div>
							</div>
						</div>
					</div>
					<br />
					<div class="card">
						<div class="card-header" style="vertical-align: middle">
							<div class="row no-gutters">
								<div class="col-md-12">
									<i class="fas fa-file-medical text-info"></i> DETAILED
									ITEMIZED CARGO DESCRIPTION
								</div>
							</div>
						</div>
						<div class="card-body">
							<div class="row">
								<div
									class="col-md-9"
									style="text-align: left !important; align-items: start"
								>
									<label>Consolidated Container Cargo</label><br />
									<label>
										<ButtonCheckbox
											:multiple="false"
											v-model="FORM.consolidated_container_cargo"
											:options="['YES', 'NO']"
										/>
									</label>
								</div>
								<div class="col-md-3">
									<div class="form-group">
										<label>Freight Type</label>
										<select
											v-model="FORM.freight_type"
											class="text-input small"
										>
											<option value="">Select Type</option>
											<option value="Air">Air</option>
											<option value="Rail">Rail</option>
											<option value="Road">Road</option>
											<option value="Sea">Sea</option>
										</select>
									</div>
								</div>
							</div>
							<div id="cargo-items-list" class="pt-2">
								<h6>CARGO ITEMS</h6>
								<FormC36Items v-model="cargoItems" />
							</div>
							<br />
						</div>
						<div class="card-footer" style="font-size: 13px">
							<div class="row">
								<div class="col-md-9" style="text-align: right !important">
									<strong>Total Items Amount</strong>
								</div>
								<div class="col-md-3">
									<strong class="document-currency">{{
										FORM.invoice_currency
									}}</strong>
									<strong class="total-item-amount">{{
										totalItemCost.toLocaleString()
									}}</strong>
								</div>
							</div>
						</div>
					</div>
					<br />
					<div class="card">
						<div class="card-header" style="vertical-align: middle">
							<div class="row no-gutters">
								<div class="col-md-9">
									<i class="fas fa-file-invoice-dollar text-danger"></i> Any
									Additional Charges
								</div>
							</div>
						</div>
						<div class="card-body">
							<div
								id="additional-charges-list"
								style="padding-left: 10px; padding-right: 10px"
							>
								<div v-if="additional_charges.length">
									<div
										v-for="(charge, index) in additional_charges"
										class="row item-row mt-1 no-gutters-sm"
										:key="index"
									>
										<div class="col-sm-3">
											<label class="control-label">Title</label>
											<select v-model="charge.title" class="text-input small">
												<option
													:value="charge"
													v-for="charge in chargesList"
													:key="charge"
												>
													{{ charge }}
												</option>
											</select>
											<input
												v-if="charge.titel == 'Other'"
												type="text"
												v-model="charge.title"
												class="text-input small charge-title"
												placeholder="Charge Title..."
											/>
										</div>
										<div class="col-sm-3">
											<label class="control-label">Amount</label>
											<input
												type="number"
												step="any"
												min="0"
												v-model="charge.amount"
												class="text-input small charge-amount"
												placeholder="Charge Amount..."
											/>
										</div>
										<div class="col-sm-6">
											<label class="control-label">Description</label>
											<textarea
												class="text-input small charge-description"
												v-model="charge.description"
												placeholder="(Optional) Provide a description of the charge..."
											></textarea>
										</div>
										<span
											@click="removeAdditionalCharge(index)"
											class="removeIcon"
										>
											<i class="fas fa-trash"></i>
										</span>
									</div>
								</div>
								<div class="alert alert-secondary text-muted" v-else>
									<i class="fas fa-info-circle"></i> No additional charges added
									yet.
								</div>
							</div>
							<br />
							<small
								class="btn btn-transparent btn-sm text-center text-info"
								@click="addAdditionalCharge"
								style="width: 100%; border: 1px solid #d0dbe6"
							>
								<small><i class="fas fa-plus"></i> Add Additional Charge</small>
							</small>
						</div>
						<div class="card-footer" style="font-size: 13px">
							<div class="row">
								<div class="col-md-9" style="text-align: right !important">
									<strong>Additional Amount</strong>
								</div>
								<div class="col-md-3">
									<strong class="document-currency">{{
										FORM.invoice_currency
									}}</strong>
									<strong id="additional-amount">{{
										totalAdditionalCharge.toLocaleString()
									}}</strong>
								</div>
							</div>
						</div>
					</div>
					<br />
					<div class="card">
						<div class="card-header">
							<i class="fas fa-receipt text-primary"></i> TOTAL UNITS, WEIGHT
							AND COST
						</div>
						<div class="card-body">
							<div class="row">
								<div class="col-sm-6">
									<div class="form-group">
										<label class="control-label h25">Units</label>
										<input
											type="number"
											step="any"
											min="0"
											placeholder="Total Units..."
											class="text-input small"
											v-model="totalUnits"
										/>
									</div>
								</div>
								<div class="col-sm-6">
									<div class="form-group">
										<label class="control-label h25"
											>Weight
											<ButtonCheckbox
												:multiple="false"
												v-model="FORM.form_weight_format"
												:options="['KG', 'LB']"
											/>
										</label>
										<input
											type="number"
											step="any"
											min="0"
											placeholder="Total Weight..."
											class="text-input small"
											v-model="totalWeight"
										/>
									</div>
								</div>
							</div>
							<div class="row">
								<div class="col-sm-8">
									<label class="control-label h25"
										>Dimensions
										<ButtonCheckbox
											:multiple="false"
											v-model="FORM.form_dimensions_format"
											:options="['CM', 'M']"
										/>
									</label>
									<input
										type="number"
										step="any"
										v-model="totalDimension.L"
										class="text-input small total-dimensions"
										placeholder="L"
									/>
									<input
										type="number"
										step="any"
										v-model="totalDimension.W"
										class="text-input small total-dimensions"
										placeholder="W"
									/>
									<input
										type="number"
										step="any"
										v-model="totalDimension.H"
										class="text-input small total-dimensions"
										placeholder="H"
									/>
								</div>
								<div class="col-sm-4">
									<div class="form-group">
										<label class="control-label h25">Total Cost</label>
										<input
											type="number"
											step="any"
											min="0"
											class="text-input small document-total"
											v-model="totalCustomValue"
											placeholder="Total Cost..."
										/>
									</div>
								</div>
							</div>
						</div>
					</div>
					<br />
				</div>
				<div class="col-lg-6">
					<div class="card">
						<div class="card-header">
							<i class="fas fa-plane-arrival text-success"></i> DESTINATION
						</div>
						<div class="card-body">
							<div class="row">
								<div class="col-md-4">
									<div class="form-group">
										<label class="control-label">Name of Importer</label>
										<input
											type="text"
											placeholder="Name of Importer..."
											class="text-input small"
											v-model="FORM.name_of_importer"
										/>
									</div>
								</div>
								<div class="col-md-4">
									<div class="form-group">
										<label class="control-label">Importer PIN No</label>
										<input
											type="text"
											class="text-input small"
											v-model="FORM.buyer"
										/>
									</div>
								</div>
								<div class="col-md-4">
									<div class="form-group">
										<label class="control-label">Importer Telephone</label>
										<input
											type="text"
											class="text-input small"
											v-model="FORM.consignee_telephone"
											placeholder="Importer Telephone..."
										/>
									</div>
								</div>
							</div>
							<br />
							<div class="row">
								<div class="col-md-4">
									<div class="form-group">
										<label class="control-label">Importer Email</label>
										<input
											type="text"
											class="text-input small"
											v-model="FORM.consignee_email"
											placeholder="Importer Email..."
										/>
									</div>
								</div>
								<div class="col-md-4">
									<div class="form-group">
										<label class="control-label">Destination Country</label>
										<select
											v-model="FORM.destination_country"
											class="text-input small country-list"
										>
											<option value="Kenya">Kenya</option>
											<option
												v-for="country in COUNTRIES"
												:value="country.name"
												:key="country.id"
											>
												{{ country.name }}
											</option>
										</select>
									</div>
								</div>
								<div class="col-md-4">
									<div class="form-group">
										<label for="buyer">Port of Entry</label>
										<select
											v-model="FORM.port_of_entry"
											class="text-input small"
										>
											<option value="">Select Port of Entry</option>
											<optgroup
												:label="title"
												v-for="(ports, title) in ports_of_entry"
												:key="title"
											>
												<option
													v-for="port in ports"
													:key="title + port"
													:value="port"
												>
													{{ port }}
												</option>
											</optgroup>
										</select>
									</div>
								</div>
							</div>
							<br />
							<div class="row">
								<div class="col-md-4">
									<div class="form-group">
										<label class="control-label">County</label>
										<select
											class="text-input small"
											v-model="FORM.destination_county"
										>
											<option value="">Select Destination County</option>
											<option
												v-for="county in COUNTIES"
												:key="county.id"
												:value="county.name"
											>
												{{ county.name }}
											</option>
										</select>
									</div>
								</div>
								<div class="col-md-4">
									<div class="form-group">
										<label class="control-label">Town</label>
										<input
											type="text"
											placeholder="Destination Town..."
											class="text-input small"
											v-model="FORM.destination_town"
										/>
									</div>
								</div>
								<div class="col-md-4">
									<div class="form-group">
										<label class="control-label">Bank Credit</label>
										<ButtonCheckbox
											:multiple="false"
											v-model="FORM.bank_credit"
											:options="['YES', 'NO']"
										/>
									</div>
								</div>
							</div>
						</div>
					</div>
					<br />
					<div class="card">
						<div class="card-header">
							<i class="fas fa-signature text-danger"></i> DECLARATION
						</div>
						<div class="card-body">
							<label style="font-weight: normal">
								<input
									type="checkbox"
									class="og"
									v-model="FORM.my_declaration"
								/>
								I <span class="my-name"></span>, declare that all particulars
								given in this document are true and complete
							</label>
							<div class="row" :class="{ muted: !FORM.my_declaration }">
								<div class="col-md-6">
									<label>Date</label>
									<input
										type="date"
										:disabled="!FORM.my_declaration"
										class="text-input small"
										v-model="FORM.agreement_date"
										placeholder="Date"
									/>
								</div>
								<div class="col-md-6">
									<label>Place</label>
									<input
										type="text"
										:disabled="!FORM.my_declaration"
										class="text-input small"
										v-model="FORM.agreement_place"
										placeholder="Location Declaration..."
									/>
								</div>
							</div>
							<br />
						</div>
					</div>
					<br />
					<div class="card">
						<div class="card-header" style="vertical-align: middle">
							<div class="row no-gutters">
								<div class="col-md-9">
									<i class="fas fa-info-circle text-info"></i>
									Additional Information
								</div>
							</div>
						</div>
						<div class="card-body p-0">
							<div class="d-flex p-2">
								<div class="p-2 flex-grow-1">
									Are the buyer and seller related?
								</div>
								<div class="p-2">
									<ButtonCheckbox
										:multiple="false"
										v-model="FORM.buyer_seller_related"
										:options="['NO', 'YES']"
									/>
								</div>
							</div>
							<div v-if="FORM.buyer_seller_related == 'YES'" class="p-2">
								<div class="d-flex">
									<div class="p-2 flex-grow-1">
										Did the relationship affect the price?
									</div>
									<div class="p-2">
										<ButtonCheckbox
											:multiple="false"
											v-model="FORM.relationship_influenced_price"
											:options="['NO', 'YES']"
										/>
									</div>
								</div>
								<div class="d-flex">
									<div class="p-2 flex-grow-1">
										Does the transaction value of the imported goods approximate
										to the customs value? If “YES?” give details
									</div>
									<div class="p-2">
										<ButtonCheckbox
											:multiple="false"
											v-model="
												FORM.transaction_value_approximates_customs_value
											"
											:options="['NO', 'YES']"
										/>
									</div>
								</div>
								<div class="row">
									<div class="col-md-6">
										<div class="m-2">
											<label>Previous Customs Number</label>
											<input
												type="text"
												class="text-input small"
												v-model="FORM.previous_relationship_invoice_number"
												placeholder="Previous Customs Number..."
											/>
										</div>
									</div>
									<div class="col-md-6">
										<div class="m-2">
											<label>Previous Customs Date</label>
											<input
												type="date"
												class="text-input small"
												v-model="FORM.previous_relationship_invoice_date"
												placeholder="Date"
											/>
										</div>
									</div>
								</div>
								<div
									class="d-flex flex-column p-2"
									v-if="
										FORM.transaction_value_approximates_customs_value == 'YES'
									"
								>
									<label class="control-label mb-1">Details</label>
									<textarea
										class="text-input small w-100"
										v-model="
											FORM.transaction_value_approximates_customs_value_details
										"
										placeholder="Please provide the details..."
									></textarea>
								</div>
							</div>
							<div class="bg-light p-2 border-top">
								<div class="d-flex">
									<div class="p-2 flex-grow-1">
										Are there any restrictions as to the disposition or use of
										the goods by buyer, other than regulations which
										<ol style="list-style: disc !important">
											<li>
												- are imposed or required by law or by the public
												authorities in the Community
											</li>
											<li>
												- limit the geographical areas in which the goods may be
												re-sold, or
											</li>
											<li>
												- do not substantially affect the value of the goods?
											</li>
										</ol>
									</div>
									<div class="p-2">
										<ButtonCheckbox
											:multiple="false"
											v-model="FORM.restrictions_on_goods"
											:options="['NO', 'YES']"
										/>
									</div>
								</div>
								<div class="d-flex">
									<div class="p-2 flex-grow-1">
										Is the sale or price of goods subject to some condition or
										consideration for which a value cannot be determined with
										respect to the goods being valued?
									</div>
									<div class="p-2">
										<ButtonCheckbox
											:multiple="false"
											v-model="FORM.condition_or_consideration_for_value"
											:options="['NO', 'YES']"
										/>
									</div>
								</div>
								<div
									class="row"
									v-if="
										FORM.restrictions_on_goods == 'YES' ||
										FORM.condition_or_consideration_for_value == 'YES'
									"
								>
									<div class="col-md-6">
										<div class="m-2">
											<label>Previous Customs Number</label>
											<input
												type="text"
												class="text-input small"
												v-model="FORM.previous_restrictions_invoice_number"
												placeholder="Previous Customs Number..."
											/>
										</div>
									</div>
									<div class="col-md-6">
										<div class="m-2">
											<label>Previous Customs Date</label>
											<input
												type="date"
												class="text-input small"
												v-model="FORM.previous_restrictions_invoice_date"
												placeholder="Date"
											/>
										</div>
									</div>
								</div>
								<div
									class="d-flex flex-column p-2"
									v-if="
										FORM.restrictions_on_goods == 'YES' ||
										FORM.condition_or_consideration_for_value == 'YES'
									"
								>
									<label class="control-label mb-1"
										>Specify the nature of the restrictions, conditions of
										considerations as appropriate</label
									>
									<textarea
										class="text-input small w-100"
										v-model="FORM.restriction_details"
										placeholder="Specify the nature of restrictions..."
									></textarea>
								</div>
							</div>
						</div>
					</div>
					<br />
					<div class="card ns bg-light bg-gradient">
						<div class="card-body">
							<div class="d-flex flex-row-reverse">
								<div
									style="font-size: 13px; text-align: center"
									class="col-md-4"
								>
									<strong>CUSTOMS VALUE</strong> &nbsp; &nbsp; &nbsp;
									<strong class="document-currency">
										{{ FORM.invoice_currency }}
									</strong>
									<strong class="document-total">{{
										totalCustomValue.toLocaleString()
									}}</strong>
								</div>
							</div>
						</div>
					</div>
					<br />
					<div class="card" v-if="FORM.process_status != 'confirmed'">
						<div class="card-header">
							<i class="fas fa-check-double text-info" v-if="!isFormClosed"></i>
							VERIFICATION
						</div>
						<div class="card-body" v-if="!isFormClosed">
							<div
								class="row"
								v-if="
									!isFormClosed && !FORM.confirm_otp_code && FORM.has_otp_code
								"
							>
								<div class="col-md-6">
									<label class="text-xl">Enter Verification Code</label>
									<v-otp-input
										length="6"
										v-model="FORM.confirm_otp_code"
										:loading="loading"
										:error="confirmCodeError"
									></v-otp-input>
									<div class="text-caption" v-if="!sendingTheCode">
										Didn't receive the code?
										<a href="#" @click="resendOTPCode()">Resend</a>
									</div>
									<div class="text-caption text-muted" v-else>
										<i class="fas fa-spin fa-spinner"></i> Resending the code...
									</div>
								</div>
							</div>
							<div v-else>
								<div
									class="form-group"
									v-if="!isFormClosed"
									:class="{ muted: !FORM.my_declaration }"
								>
									<label style="font-weight: normal">
										<input
											type="checkbox"
											class="og"
											:disabled="!FORM.my_declaration"
											v-model="FORM.send_confirmation_otp"
										/>
										<span v-if="!FORM.has_otp_code">Send</span
										><span v-else>Resend</span> confirmation code to:
									</label>
								</div>
								<div
									class="row flex-column"
									v-if="!isFormClosed"
									:class="{ muted: !FORM.my_declaration }"
								>
									<div class="col-md-6">
										<label class="control-label">
											<input
												type="checkbox"
												:disabled="!FORM.send_confirmation_otp"
												class="og importer_email"
												v-model="FORM.send_otp_importer_email"
											/>
											<i class="fas fa-envelope"></i>
											<span class="importer_email">{{
												loggedInUser.email
											}}</span>
										</label>
									</div>
									<div class="col-md-6">
										<label class="control-label">
											<input
												type="checkbox"
												:disabled="!FORM.send_confirmation_otp"
												class="og importer_phone_number"
												v-model="FORM.send_otp_importer_phone_number"
											/>
											<i class="fas fa-phone"></i>
											<span class="importer_phone_number">{{
												loggedInUser.data.phone ?? "N/A"
											}}</span>
										</label>
									</div>
								</div>
							</div>
						</div>
					</div>
					<br />
					<div class="card ns">
						<div class="card-footer bdt-0">
							<div
								class="d-flex text-center p-2 text-info"
								v-if="savingFormData"
							>
								<i class="fas fa-spin fa-spinner"></i> Saving Form Data...
							</div>
							<div class="d-flex start-right justify-content-evenly" v-else>
								<span v-if="FORM.id">
									<router-link
										:to="{ name: 'formC36-new', params: { id: formId } }"
										class="dropdown-item"
										v-if="formId"
									>
										<a class="btn-sm btn btn-primary print-btn pull-right">
											<i class="fas fa-print"></i> IDF FORMAT
										</a>
									</router-link>
								</span>
								<span v-if="FORM.id">
									<router-link
										:to="{ name: 'formC36-old', params: { id: formId } }"
										class="dropdown-item"
										v-if="formId"
									>
										<a class="btn-sm btn bg-teal print-btn pull-right">
											<i class="fas fa-print"></i> FORM FORMAT
										</a>
									</router-link>
								</span>
								<div v-if="FORM.process_status != 'confirmed'">
									<span v-if="FORM.id || !isFormClosed">
										<button
											class="btn-sm btn btn-success"
											v-if="
												!FORM.send_confirmation_otp && FORM.confirm_otp_code
											"
										>
											<i class="fas fa-save"></i> VERIFY CODE & SUBMIT FORM
										</button>
										<button class="btn-sm btn btn-danger" v-else>
											<small v-if="FORM.send_confirmation_otp">
												<i class="fas fa-paper-plane"></i> SAVE & SEND CODE
											</small>
											<small v-else> <i class="far fa-save"></i> SAVE </small>
										</button>
									</span>
								</div>
							</div>
						</div>
					</div>
				</div>
			</form>
		</div>
		<div v-if="isActiveTab == 'insurance'">
			<List
				:typ="'insurance'"
				:token="USER.token"
				:server="SERVER"
				:id="FORM.id"
			></List>
		</div>
		<div v-if="isActiveTab == 'consolidator'">
			<List
				:typ="'consolidator'"
				:token="USER.token"
				:server="SERVER"
				:id="FORM.id"
			></List>
		</div>
		<div v-if="isActiveTab == 'clearing'">
			<List
				:typ="'clearing'"
				:token="USER.token"
				:server="SERVER"
				:id="FORM.id"
			></List>
		</div>
		<div v-if="isActiveTab == 'origin'">
			<DataFromOrigin :id="FORM.id"></DataFromOrigin>
		</div>
		<div v-if="isActiveTab == 'issues'">
			<FormIssues :form="FORM" :fid="FORM.id"></FormIssues>
		</div>
	</div>
</template>
<script setup>
import DocumentAttachments from "@/components/layout/Form/DocumentAttachments";
import ButtonCheckbox from "@/components/layout/Form/ButtonCheckbox";
import List from "@/components/layout/Offer/List";
import FormC36Items from "@/components/layout/Form/FormC36Items.vue";
import DataFromOrigin from "@/components/layout/Form/DataFromOrigin.vue";
import FormIssues from "@/components/layout/Form/FormIssues.vue";
import {
	computed,
	inject,
	onMounted,
	provide,
	ref,
	toRefs,
	watchEffect,
} from "vue";

import {
	deleteAttachment,
	fetchCounties,
	fetchCountries,
	fetchFormData,
	ports_of_entry,
	postFormData,
	postFormFiles,
	UnitConverter,
	resendOTP,
} from "@/scripts/data";

import { useRoute, useRouter } from "vue-router";
const formMsgDiv = ref("");
const COUNTRIES = ref([]);
const COUNTIES = ref([]);
const route = useRoute();
const router = useRouter();
const savingFormData = ref(false);
const formId = ref("");
const loading = ref(false);
const attachments = ref([]);
const additional_charges = ref([]);
const cargoItems = ref([]);
const totalDimension = ref({ L: 0, H: 0, W: 0 });
const totalWeight = ref(0);
const totalUnits = ref(0);
const confirmCodeError = ref(true);

const sendingTheCode = ref(false);

const USER = inject("loggedInUser");
const loggedInUser = {
	email: USER.value.email,
	phone: USER.value.phone ?? "",
	data: {
		phone: USER?.value.phone ?? "",
		name: USER?.value.name ?? "",
		country: USER?.value.country,
		pin_number: USER?.value.pin_number ?? "",
	},
};

const chargesList = [
	"Indirect Payments",
	"Royalties and Licences Fees",
	"Proceeds of any subsequent resale, disposal or use accruing to the seller",
	"Transport/Freight",
	"Loading and handling charges",
	"Insurance",
	"Materials, Components, Parts and similar items incorporated in the imported goods",
	"Tools, Dies, Moulds and similar items used in the production of the imported goods",
	"Materials consumed in the production of the imported goods",
	"Engineering, development, art work, design work, plans and sketches undertaken elsewhere other than in the country of the import and necessary for the production of imported goods",
	"Cost of transport after arrival in the Community",
	"Charges of Construction, Maintenance and other related expenses after importation except installation costs",
	"Customs Duty and Taxes in the Community",
	"Commissions incurred when Buying",
	"Commissions incurred when Selling",
	"Brokerage fee incurred",
	"Costs for containers and packing",
	"Other",
];

const FORM = ref({
	id: null,
	buyer_seller_related: false,
	relationship_influenced_price: true,
	seller_country: "",
	port_of_origin: "",
	origin_pin: "",
	seller: "",
	buyer: loggedInUser.data.pin_number,
	invoice_number: "",
	consolidated_container_cargo: "no",
	invoice_date: "",
	terms_of_delivery: "",
	freight_type: "",
	other_charges: [],
	name_of_importer: loggedInUser.data.name,
	importer_pin_number: loggedInUser.data.pin_number,
	consignee_telephone: loggedInUser.data.phone,
	consignee_email: loggedInUser.email,
	destination_country: loggedInUser.data.country.name,
	destination_county: "",
	destination_town: "",
	bank_credit: "no",
	total_units: 0,
	total_weight: 0,
	total_dimensions: { L: 0, W: 0, H: 0 },
	form_weight_format: "KG",
	form_dimensions_format: "CM",
	transaction_value_approximates_customs_value: false,
	transaction_value_approximates_customs_value_details: "",
	customs_value: 0,
	my_declaration: false,
	restriction_details: "",
	restrictions_on_goods: false,
	agreement_date: "",
	agreement_place: "",
	confirm_otp_code: "",
	send_confirmation_otp: false,
	send_otp_importer_email: true,
	send_otp_importer_phone_number: true,
});

const getDocumentTotals = () => {
	let d = { L: 0, W: 0, H: 0 };
	let weight = 0;
	let units = 0;
	for (let item of cargoItems.value) {
		weight += UnitConverter.LBKGConverter(
			parseFloat(item.weight.value),
			item.weight.format,
			FORM.value.form_weight_format
		);
		d.L += UnitConverter.CM_MConverter(
			parseFloat(item.dimensions.L),
			item.dimensions.format,
			FORM.value.form_dimensions_format
		);
		d.W += UnitConverter.CM_MConverter(
			parseFloat(item.dimensions.W),
			item.dimensions.format,
			FORM.value.form_dimensions_format
		);
		d.H += UnitConverter.CM_MConverter(
			parseFloat(item.dimensions.H),
			item.dimensions.format,
			FORM.value.form_dimensions_format
		);

		units += parseFloat(item.quantity);
	}
	totalWeight.value = weight;
	totalDimension.value = d;
	totalUnits.value = units;
};

const isFormClosed = computed(() => {
	return false;

	if (formId == "create") {
		return false;
	}
	let status = FORM.value.process_status;
	return (
		status != "" &&
		status != "draft" &&
		status != "awaiting_importer_verification"
	);
});

watchEffect(() => {
	getDocumentTotals();
});

const isActiveTab = ref("details");

const jumpToTab = (tab) => {
	isActiveTab.value = tab;
};

const SERVER_LOCATION = inject("SERVER_LOCATION");
const SERVER = SERVER_LOCATION.value;

const pageInCreateMode = ref(false);

const addAdditionalCharge = () => {
	additional_charges.value.push({
		title: "",
		amount: 0,
		description: "",
		visible: true,
	});
};

const deleteTheAttachment = async (id) => {
	let del = await deleteAttachment(id, USER.value.token, SERVER);
	return del.status;
};

const resendOTPCode = async () => {
	sendingTheCode.value = true;
	await resendOTP(SERVER, USER.value.token, FORM.value.id);
	sendingTheCode.value = false;
};

const removeAdditionalCharge = (ind) => {
	additional_charges.value[ind].visible = false;
};

const totalAdditionalCharge = computed(() => {
	let total = 0;
	for (let charge of additional_charges.value) {
		total += parseFloat(charge.value?.amount ?? charge.amount ?? 0);
	}
	return total;
});

const totalItemCost = computed(() => {
	let total = 0;
	for (let ind in cargoItems.value) {
		let item = cargoItems.value[ind];
		total += parseFloat(item.value?.total_price ?? item.total_price ?? 0);
	}
	return total;
});

const totalCustomValue = computed(() => {
	return totalItemCost.value + totalAdditionalCharge.value;
});

onMounted(async () => {
	formId.value = route.params.id;
	pageInCreateMode.value = !parseFloat(route.params.id) > 0;

	let countries = await fetchCountries(SERVER);
	COUNTRIES.value = countries.countries;

	if (isFormClosed) {
		document.querySelectorAll("input, textarea, select").forEach((element) => {
			// element.disabled = true;
		});
	}

	let counties = await fetchCounties(SERVER);
	COUNTIES.value = counties.counties;

	if (parseInt(formId.value) > 0) {
		let dataT = await fetchFormData(SERVER, formId.value, USER.value.token);
		FORM.value = dataT.form;
		FORM.value.my_declaration = parseInt(FORM.value.my_declaration) == 1;
		FORM.value.send_confirmation_otp =
			parseInt(FORM.value.send_confirmation_otp) == 1;

		cargoItems.value = dataT.form.items ?? [];

		if (
			!FORM.value.confirm_otp_code ||
			FORM.value.confirm_otp_code == "" ||
			FORM.value.confirm_otp_code == "null"
		) {
			FORM.value.confirm_otp_code = "";
		}

		attachments.value = dataT.form.attachments;

		additional_charges.value = dataT.form.other_charges
			? dataT.form.other_charges
			: [];

		totalDimension.value = dataT.form.form_dimensions
			? dataT.form.form_dimensions
			: [];
		totalWeight.value = dataT.form.form_weight;
		totalCustomValue.value = dataT.form.customs_value;
	}
});

const saveFormDetails = async () => {
	savingFormData.value = true;
	cargoItems.value = cargoItems.value.filter((item) => item.visible == true);
	attachments.value = attachments.value.filter((item) => item.visible == true);
	additional_charges.value = additional_charges.value.filter(
		(item) => item.visible == true
	);

	FORM.value.customs_value = totalCustomValue.value;
	FORM.value.items = cargoItems.value;
	FORM.value.other_charges = additional_charges.value;

	FORM.value.form_dimensions = totalDimension.value;
	FORM.value.form_weight = totalWeight.value;

	FORM.total_units = totalUnits.value;

	let savedForm = await postFormData(FORM, USER.value.token, SERVER);
	attachments.value.forEach(async (file) => {
		let upload = await postFormFiles(
			savedForm.form.id,
			file,
			USER.value.token,
			SERVER
		);
		if (upload.status) {
			attachments.value.push(upload.file);
		}
	});

	savingFormData.value = false;

	if (FORM.value.id == null) {
		router.push({ name: "formC36", params: { id: savedForm.form.id } });
	}
};

provide("removeAdditionalCharge", removeAdditionalCharge);
provide("deleteAttachment", deleteTheAttachment);
</script>
<style>
html {
	background-color: #fcfcfc !important;
}

.muted {
	color: #bbb;
}

.og {
	margin-right: 5px;
}

body {
	color: #2851e6;
}

.card-body label {
	margin-top: 10px;
	width: 100%;
	text-align: left;
}

.card-body label.h25 {
	min-height: 25px;
}

.card {
	overflow: hidden;
	border-radius: 3px !important;
	background-color: #fff;
	box-shadow: 1px 4px 5px #ddd;
	text-align: left !important;
	border: 1px solid #b0b7d2;
}

.card.ns {
	border: none;
	box-shadow: none;
}

.card.complete {
	border: 1px solid #1cad1e;
}

.card .card-header {
	font-weight: 600;
	font-size: 12px;
	background-color: #fff !important;
	border-bottom: 1px solid rgba(0, 0, 0, 0.0863);
}

.card .card-footer {
	background-color: #fff !important;
}

.card .card-footer.bdt-0 {
	border-top: none !important;
}

.card .card-body label:not(.other-toggle-tab label) {
	overflow-x: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
	font-weight: 600;
}

.card .card-body h6 {
	font-size: 12px;
	font-weight: 600;
}

.form36 {
	font-size: 11px !important;
}

form.row {
	width: 100%;
}

textarea {
	font-size: 12px;
	width: 100%;
}

select.small,
.text-input.small {
	margin: 0px;
	border-radius: 1px;
	background-color: #fff;
	border: 1px solid #aaa;
	padding: 5px 7px;
	width: 100%;
}

.text-input.item-dimensions {
	width: 31% !important;
}

.text-input.total-dimensions {
	width: 30.9% !important;
}

.fas.fa05x {
	font-size: 10px !important;
}

.card-body .btn {
	padding-top: 0px !important;
	margin-top: 0px !important;
	font-size: 10px;
	font-weight: 600;
	float: right;
}

.row.item-row {
	position: relative;
	clear: both !important;
}

.row.item-row .removeIcon {
	position: absolute;
	top: -5px;
	right: -5px;
	width: 25px;
	height: 18px;
	color: rgb(146, 109, 109);
	font-weight: 500;
	transition: 500ms color linear;
}

.row.item-row .removeIcon:hover {
	cursor: pointer;
	transition: 500ms color linear;
	color: crimson;
}

input[type="file"].small {
	border: 0px !important;
}

#cargo-items-list .file-attachment:not(:last-child) {
	border-bottom: 1px solid #eee;
	margin-bottom: 20px;
}

#cargo-items-list [class*="col-sm-"],
.no-g [class*="col-sm-"] {
	padding-left: 1px !important;
	padding-right: 1px !important;
}

.ka-section .charge-amount {
	margin-bottom: 20px;
}

span.radioer {
	background-color: rgba(0, 0, 0, 0.12);
	color: #787878;
	padding: 3px 0px 4px;
	margin-left: 10px;
}

span.radioer span {
	font-size: 10px;
	padding: 3px 5px 4px;
	cursor: pointer;
	transition: all 0.1s;
}

span.radioer span.selected {
	background-color: rgb(0, 61, 115);
	transition: all 0.1s;
	color: #fff;
}

.is-menu {
	font-size: 12px;
	color: #777;
	font-weight: 600;
}

.is-menu div.tab-toggler {
	padding: 5px 15px;
	margin: 2px 4px;
	border-radius: 35px;
}

.is-menu div.tab-toggler:hover {
	background-color: rgba(0, 0, 0, 0.05);
	color: #616161;
	cursor: pointer;
}

.is-menu div.active-link {
	color: #343434;
	font-weight: 600;
	background-color: rgba(0, 0, 0, 0.05);
	border: 1px solid rgba(0, 0, 0, 0.08);
}
</style>
