<template>
	<div class="form36 p-2">
		<div v-if="formMsgDiv" class="m-1" v-html="formMsgDiv"></div>
		<form class="row" @submit.prevent="saveFormDetails">
			<div
				class="col-sm-12 d-flex justify-content-between pt-2 pb-2"
				style="font-size: 15px"
			>
				<div class="left">
					<strong>ACID FORM NO:</strong> {{ FORM.form.form_number }} 
					<!-- <router-link
						:to="{ name: 'formC36', params: { id: formId } }"
					>
						<button type="button" class="btn btn-sm text-green">
							<i class="fas fa-eye"></i>
						</button>
					</router-link> -->
				</div>
				<div class="right" v-if="FORM.date">
					<strong>DATE:</strong> {{ FORM.date }}
				</div>
				<div class="right">
					<button class="btn btn-primary btn-sm rounded-pill">
						SAVE OFFER
					</button>
				</div>
			</div>
			<div class="col-md-3 col-sm-12 pb-2">
				<div class="card">
					<div class="card-header">
						<i class="fas fa-people-carry text-primary"></i> Service Provider
						Details
					</div>
					<div class="card-body">
						<div class="form-group">
							<label class="control-label">Company Name</label>
							<input
								v-model="FORM.consolidator.name"
								type="text"
								placeholder="Company Name..."
								class="text-input small"
							/>
						</div>
						<div class="form-group">
							<label class="control-label">Contact Name</label>
							<input
								v-model="FORM.consolidator.contact_name"
								type="text"
								placeholder="Contact Name..."
								class="text-input small"
							/>
						</div>
						<div class="form-group">
							<label class="control-label">Contact Email</label>
							<input
								v-model="FORM.consolidator.contact_email"
								type="text"
								placeholder="Contact Email..."
								class="text-input small"
							/>
						</div>
						<div class="form-group">
							<label class="control-label">Contact Phone</label>
							<input
								v-model="FORM.consolidator.contact_phone"
								type="text"
								placeholder="Contact Phone..."
								class="text-input small"
							/>
						</div>
						<div class="form-group">
							<label class="control-label">Location</label>
							<input
								v-model="FORM.consolidator.location"
								type="text"
								placeholder="Contact Phone..."
								class="text-input small"
							/>
						</div>
						<div class="form-group">
							<label class="control-label">Comments</label>
							<textarea
								rows="5"
								class="text-input small charge-description"
								v-model="FORM.consolidator.comments"
								placeholder="Comments..."
							></textarea>
						</div>
					</div>
				</div>
			</div>
			<div class="col-md-3 col-sm-12 pb-2">
				<div class="card">
					<div class="card-header">
						<i class="fas fa-user-secret text-info"></i> Consignee Details
					</div>
					<div class="card-body">
						<div class="form-group">
							<label class="control-label">Name</label>
							<input
								v-model="FORM.consignee.name"
								type="text"
								placeholder="Name..."
								class="text-input small"
							/>
						</div>
						<div class="form-group">
							<label class="control-label">Email</label>
							<input
								v-model="FORM.consignee.email"
								type="text"
								placeholder="Email..."
								class="text-input small"
							/>
						</div>
						<div class="form-group">
							<label class="control-label">Phone</label>
							<input
								v-model="FORM.consignee.phone"
								type="text"
								placeholder="Phone..."
								class="text-input small"
							/>
						</div>
						<div class="form-group">
							<label class="control-label">Destination</label>
							<input
								v-model="FORM.consignee.destination"
								type="text"
								placeholder="Cargo Destination..."
								class="text-input small"
							/>
						</div>
						<div class="form-group">
							<label class="control-label">Comments</label>
							<textarea
								rows="6"
								class="text-input small charge-description"
								v-model="FORM.consignee.comments"
								placeholder="Comments..."
							></textarea>
						</div>
					</div>
				</div>
			</div>
			<div class="col-md-6 col-sm-12 pb-2">
				<div class="card">
					<div class="card-header">
						<i class="fas fa-cubes text-default"></i> Cargo Description
					</div>
					<div class="card-body">
						<div class="items-list">
							<div v-for="(v, k) in FORM.form.items" :key="k">
								<strong style="font-size: 12px">{{
									k +
									1 +
									". " +
									v.item_category +
									", " +
									v.name +
									", " +
									v.make +
									", " +
									v.model +
									"(Condition: " +
									v.item_condition +
									")"
								}}</strong>
								<br />
								<em
									>Quantity: {{ v.quantity }}, Item Price:
									{{ FORM.form.currency }}{{ v.price }}, Size:
									{{ v.dimensions.L }}{{ v.dimensions.format }} by
									{{ v.dimensions.W }}{{ v.dimensions.format }} by
									{{ v.dimensions.H }}{{ v.dimensions.format }}, Weight:
									{{ v.weight.value }}{{ v.weight.format }}
								</em>
							</div>
						</div>
						<br />
						<div class="d-flex justify-space-between">
							<h6>
								<i class="fas fa-money-bill-wave-alt"></i> ITEM COST:
								<span class="text-red">{{
									FORM.form.currency +
									"" +
									parseFloat(FORM.shipping_cost).toFixed(2)
								}}</span>
							</h6>
							<h6>
								<i class="fas fa-money-check-alt"></i> TOTAL SHIPPING COST:
								<span class="text-red">{{
									FORM.form.currency +
									"" +
									parseFloat(FORM.form.customs_value).toFixed(2)
								}}</span>
							</h6>
							<h6>
								<i class="fas fa-balance-scale-right"></i> TOTAL WEIGHT:
								<span class="text-red">{{ FORM.form.weight }}</span>
							</h6>
						</div>
						<div clas="form-group">
							<div class="pt-2 pb-2">
								<strong>Cargo Handing Services Costs:</strong>
							</div>

							<input
								v-model="FORM.service_cost"
								type="number"
								step="any"
								min="0"
								placeholder="Cargo Handling Cost..."
								class="text-input small text-danger"
								style="font-weight: bold; font-size: 14px"
							/>
						</div>
					</div>
				</div>
			</div>
		</form>
	</div>
</template>
<script setup>
import { computed, inject, onMounted, ref } from "vue";

import { fetchOfferData, fetchFormData, postOfferData } from "@/scripts/data";

import { useRoute, useRouter } from "vue-router";

const router = useRouter();
const route = useRoute();
const SERVER_LOCATION = inject("SERVER_LOCATION");
const SERVER = SERVER_LOCATION.value;

const USER = inject("loggedInUser");

const FORM = ref({
	id: null,
	service_type: 0,
	service_provider_id: 0,
	form_c36_id: 0,
	importer_id: 0,
	date: null,
	form: {
		id: "",
		form_number: "",
		items: "",
		dimensions: {
			dimensions: {},
			format: "",
		},
		weight: "",
		currency: "",
		customs_value: 0,
	},
	consolidator: {
		name: "",
		contact_name: "",
		pin_number: "",
		contact_email: "",
		contact_phone: "",
		location: "",
		comments: "",
	},
	consignee: {
		name: "",
		email: "",
		phone: "",
		pin_number: "",
		destination: "",
		comments: "",
	},
	origin: "",
	destination: "",
	service_cost: 0,
	shipping_cost: 0,
});

const formId = ref("");
const offerId = ref(0);

onMounted(async () => {
	formId.value = route.params.id;
	offerId.value = route.params.offerId;

	if (parseInt(offerId.value) > 0) {
		let offerData = await fetchOfferData(
			SERVER,
			offerId.value,
			USER.value.token
		);
		FORM.value = offerData.offer;
		FORM.value.date = offerData.offer.date;
		FORM.value.shipping_cost = getItemCost(offerData.offer.items);
	} else {
		let dataT = await fetchFormData(SERVER, formId.value, USER.value.token);

		FORM.value.form_c36_id = formId.value;
		FORM.value.importer_id = dataT.form.user_id;

		FORM.value.form.id = dataT.form.id;
		FORM.value.form.form_number = dataT.form.form_number;
		FORM.value.form.items = dataT.form.items;

		FORM.value.shipping_cost = getItemCost(dataT.form.items);

		FORM.value.form.customs_value = dataT.form.customs_value;
		FORM.value.form.currency = dataT.form.invoice_currency;
		FORM.value.form.weight =
			parseFloat(dataT.form.form_weight).toFixed(2) +
			"" +
			dataT.form.form_weight_format;
		FORM.value.form.dimensions = {
			dimensions:
				dataT.form.form_dimensions.L +
				dataT.form.form_dimensions.W +
				dataT.form.form_dimensions.H,
			format: dataT.form.form_dimensions_format,
		};

		FORM.value.origin =
			dataT.form.port_of_origin + ", " + dataT.form.seller_country;
		FORM.value.destination =
			dataT.form.destination_county + ", " + dataT.form.destination_country;

		FORM.value.consignee.name = dataT.form.name_of_importer;
		FORM.value.consignee.email = dataT.form.consignee_email;
		FORM.value.consignee.phone = dataT.form.consignee_telephone;
		FORM.value.consignee.pin_number = dataT.form.buyer;
		FORM.value.consignee.destination =
			dataT.form.destination_town +
			", " +
			dataT.form.destination_county +
			", " +
			dataT.form.destination_country;
		FORM.value.consignee.comments = USER.value.comments;

		FORM.value.consolidator.name = USER.value.name;
		FORM.value.consolidator.contact_name = "";
		FORM.value.consolidator.contact_email = USER.value.email;
		FORM.value.consolidator.contact_phone = USER.value.phone;
		FORM.value.consolidator.pin_number = USER.value.pin_number;
		FORM.value.consolidator.location =
			dataT.form.port_of_origin + ", " + dataT.form.seller_country;
		FORM.value.consolidator.comments = USER.value.comments;
	}
});

const getItemCost = (items) => {
	let price = 0;
	for (let it of FORM.value.form.items) {
		let linePrice = parseFloat(it.quantity) * parseFloat(it.price);
		price += linePrice;
	}
	return price;
};

const saveFormDetails = async () => {
	FORM.value.service_type = "insurance";
	FORM.value.service_provider_id = USER.value.id;
	FORM.value.currency = FORM.value.form.currency;
	FORM.value.shipment_cost = FORM.value.shipping_cost;
	let savedOffer = await postOfferData(FORM, USER.value.token, SERVER);
	router.push({
		name: "insurance-offer-id",
		params: { id: formId.value, offerId: savedOffer.offer.id },
	});
};
</script>
<style>
html {
	background-color: #fcfcfc !important;
}

.muted {
	color: #bbb;
}

.og {
	margin-right: 5px;
}

body {
	color: #2851e6;
}

.card-body label {
	margin-top: 10px;
	width: 100%;
	text-align: left;
}

.card-body label.h25 {
	min-height: 25px;
}

.card {
	overflow: hidden;
	border-radius: 3px !important;
	background-color: #fff;
	box-shadow: 1px 4px 5px #ddd;
	text-align: left !important;
	border: 1px solid #b0b7d2;
}

.card.ns {
	border: none;
	box-shadow: none;
}

.card.complete {
	border: 1px solid #1cad1e;
}

.card .card-header {
	font-weight: 600;
	font-size: 14px;
	background-color: #fff !important;
	border-bottom: 1px solid rgba(0, 0, 0, 0.0863);
}

.card .card-footer {
	background-color: #fff !important;
}

.card .card-footer.bdt-0 {
	border-top: none !important;
}

.card .card-body label:not(.other-toggle-tab label) {
	overflow-x: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
	font-weight: 600;
}

.card .card-body h6 {
	font-size: 12px;
	font-weight: 600;
}

.form36 {
	font-size: 11px !important;
}

form.row {
	width: 100%;
}

textarea {
	font-size: 12px;
	width: 100%;
}

select.small,
.text-input.small {
	margin: 0px;
	border-radius: 1px;
	background-color: #fff;
	border: 1px solid #aaa;
	padding: 5px 7px;
	width: 100%;
}

.text-input.item-dimensions {
	width: 31% !important;
}

.text-input.total-dimensions {
	width: 30.9% !important;
}

.fas.fa05x {
	font-size: 10px !important;
}

.card-body .btn {
	padding-top: 0px !important;
	margin-top: 0px !important;
	font-size: 10px;
	font-weight: 600;
	float: right;
}

.row.item-row {
	position: relative;
	clear: both !important;
}

.row.item-row .removeIcon {
	position: absolute;
	top: -5px;
	right: -5px;
	width: 25px;
	height: 18px;
	color: rgb(146, 109, 109);
	font-weight: 500;
	transition: 500ms color linear;
}

.row.item-row .removeIcon:hover {
	cursor: pointer;
	transition: 500ms color linear;
	color: crimson;
}

input[type="file"].small {
	border: 0px !important;
}

#cargo-items-list .file-attachment:not(:last-child) {
	border-bottom: 1px solid #eee;
	margin-bottom: 20px;
}

#cargo-items-list [class*="col-sm-"],
.no-g [class*="col-sm-"] {
	padding-left: 1px !important;
	padding-right: 1px !important;
}

.ka-section .charge-amount {
	margin-bottom: 20px;
}

span.radioer {
	background-color: rgba(0, 0, 0, 0.12);
	color: #787878;
	padding: 3px 0px 4px;
	margin-left: 10px;
}

span.radioer span {
	font-size: 10px;
	padding: 3px 5px 4px;
	cursor: pointer;
	transition: all 0.1s;
}

span.radioer span.selected {
	background-color: rgb(0, 61, 115);
	transition: all 0.1s;
	color: #fff;
}

.items-list div:nth-child(odd) {
	background-color: rgba(0, 0, 0, 0.03);
	padding: 8px 10px;
	margin: 2px 0px;
	border-radius: 8px;
}

.items-list div:nth-child(even) {
	background-color: rgba(0, 0, 0, 0.07);
	padding: 8px 10px;
	border-radius: 8px;
}
</style>
