<template>
  <div class="p-2">
    <div class="row">
      <div class="col-lg-4">&nbsp;</div>
      <div class="col-lg-4">
        <div class="card">
          <div class="card-header p-2">
            <i class="fas fa-user-circle"></i> USER DETAILS
          </div>
          <div class="card-body">
            <v-text-field
              v-model="loggedInUser.name"
              label="Name"
              variant="outlined"
            ></v-text-field>
            <v-text-field
              v-model="loggedInUser.email"
              label="Email"
              :required="true"
              type="email"
              variant="outlined"
            ></v-text-field>

            <v-select
              v-model="loggedInUser.country"
              :items="COUNTRIES"
              item-title="name"
              item-value="id"
              label="Country"
              persistent-hint
              return-object
              single-line
							variant="outlined"
            ></v-select>
            <v-text-field
              v-model="userPhone"
              label="Phone"
							:prefix="'+'+loggedInUser?.country?.code ?? '254'"
              variant="outlined"
            ></v-text-field>

						<input type="hidden" ref="phonePrefix" :value="loggedInUser?.country?.code" />
            <v-text-field
              v-model="loggedInUser.id_number"
              label="ID Number"
              variant="outlined"
            ></v-text-field>
            <v-text-field
              v-model="loggedInUser.pin_number"
              label="PIN Number"
              variant="outlined"
            ></v-text-field>
            <div class="p-2">
              <label
                ><input
                  type="checkbox"
                  v-model="changePassword"
                  :value="true"
                />
                Do you want to change your password?</label
              >
            </div>
            <div v-if="changePassword">
              <v-text-field
                v-model="password"
                type="password"
                label="Password"
                variant="outlined"
              ></v-text-field>
              <v-text-field
                v-model="confirm_password"
                type="password"
                label="Confirm Password"
                variant="outlined"
              ></v-text-field>
              <p class="text-red p-2" v-if="password != confirm_password">
                Passwords do not match.
              </p>
            </div>
            <div class="text-red my-2 border p-2 text-small border-color-red" v-if="saveError">{{ saveError }}</div>
            <v-btn
              :disabled="loading"
              :loading="loading"
              block
              class="text-none mb-4"
              :color="saved == true ? 'green-darken-3' : 'indigo-darken-3'"
              size="x-large"
              variant="flat"
              @click="updateUserInformation"
            >
              <span v-if="saved">Details saved successfully!</span>
              <span v-else>Save Details</span>
            </v-btn>
          </div>
        </div>
      </div>
      <div class="col-lg-4">&nbsp;</div>
    </div>
  </div>
</template>

<script setup>
import { inject, onMounted, ref } from "vue";
import { useStore } from "vuex";
import { fetchCountries,updateUserToServer } from '../scripts/data.js';

const loading = ref(false);
const saved = ref(false);
const saveError = ref('');

const USER = inject("loggedInUser");
const store = useStore();
const SERVER_LOCATION = inject("SERVER_LOCATION");
const SERVER = SERVER_LOCATION.value;

const phonePrefix = ref(null);

const COUNTRIES = ref([]);

const changePassword = ref(false);
const password = ref("");
const confirm_password = ref("");

const removePrefix = (str, pref) => {
  if(!str){
    return '';
  }
  let parts = str?.toString().split(pref);
  return parts[1] ?? '';
}

const loggedInUser = ref({
  email: USER.value.email,
  phone: USER.value.phone ?? "",
  name: USER?.value.name ?? "",
  country: USER?.value.country,
  pin_number: USER?.value.pin_number ?? "",
  id_number: USER?.value.id_number ?? "",
});

const userPhone = ref(USER.value.phone?.toString().trim() != '' ? removePrefix(USER.value.phone, USER.value?.country?.code) : '');

const updateUserInformation = async () => {
  loading.value = false;
  if (
    changePassword &&
    password == confirm_password &&
    confirm_password.trim() != ""
  ) {
    loggedInUser.value.password = password;
    loggedInUser.value.password_confirmation = confirm_password;
  }
  loggedInUser.value.phone = "+"+loggedInUser.value.country?.code+(userPhone.value.replace(/^0+/, ''));

  let userUpdate = await updateUserToServer(SERVER, loggedInUser.value, USER.value.token);
  loading.value = false;
  if(userUpdate.status){
    saved.value = true;
    userUpdate.user.token = store.state.token;
    store.commit('setUser', userUpdate.user);

    saveError.value = null;
    window.setTimeout(()=> saved.value = false, 1000);
  }
  else{
    saveError.value = userUpdate.error
  }
};

onMounted(async () => {
  let countries = await fetchCountries(SERVER);
	COUNTRIES.value = countries.countries;
});
</script>

<style>
.card-body label {
  margin-top: 10px;
  width: 100%;
  text-align: left;
}

.card-body label.h25 {
  min-height: 25px;
}

.card {
  overflow: hidden;
  border-radius: 3px !important;
  background-color: #fff;
  box-shadow: 1px 4px 5px #ddd;
  text-align: left !important;
  border: 1px solid #b0b7d2;
}

.card.complete {
  border: 1px solid #1cad1e;
}

.card .card-header {
  font-weight: 600;
  font-size: 13px;
  background-color: #fff !important;
  border-bottom: 1px solid rgba(0, 0, 0, 0.0863);
  padding: 15px;
}

.card .card-footer {
  background-color: #fff !important;
}

.card .card-body label:not(.other-toggle-tab label) {
  overflow-x: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-weight: 600;
}

.card .card-body h6 {
  font-size: 12px;
  font-weight: 600;
}
</style>