<template>
	<div class="row">
		<div class="col-md-4 col-sm-12 pb-2">
			<div class="card">
				<div class="card-header">
					<i class="fas fa-info-circle text-info"></i> Form C36 Declaration
				</div>
				<div class="card-body">
					<div class="items-list">
						<div v-for="(v, k) in FORMC36.items" :key="k">
							<strong style="font-size: 12px">{{
								k +
								1 +
								". " +
								v.item_category +
								", " +
								v.name +
								", " +
								v.make +
								", " +
								v.model +
								"(Condition: " +
								v.item_condition +
								")"
							}}</strong>
							<br />
							<em
								>Quantity: {{ v.quantity }}, Item Price: {{ FORMC36.invoice_currency }}{{ v.price }}, Size:
								{{ v.dimensions.L }}{{ v.dimensions.format }} by {{ v.dimensions.W }}{{ v.dimensions.format }} by {{ v.dimensions.H }}{{ v.dimensions.format }},
								Weight: {{ v.weight.value }}{{ v.weight.format }}
							</em>
						</div>
					</div>
					<br />
					<div class="d-flex justify-space-between">
						<h6>
							<i class="fas fa-money-bill-wave-alt"></i> ITEM COST: 
							<span class="text-red">{{ FORMC36.invoice_currency + "" + getItemCost(FORMC36.items).toFixed(2) }}</span>
						</h6>
						<h6>
							<i class="fas fa-money-check-alt"></i> TOTAL SHIPPING COST: 
							<span class="text-red">{{ FORMC36.invoice_currency + "" + parseFloat(FORMC36.customs_value).toFixed(2) }}</span> 
						</h6>
						<h6>
							<i class="fas fa-balance-scale-right"></i> TOTAL WEIGHT: 
							<span class="text-red">{{ FORMC36.total_weight }}{{ FORMC36.form_weight_format }}</span> 
						</h6>
					</div>
				</div>
			</div>
		</div>
		<div class="col-md-4 col-sm-12 pb-2">
			<div class="card">
				<div class="card-header">
					<i class="fas fa-shield-alt text-success"></i> Marine Insurance
				</div>
				<div class="card-body">
					<div class="items-list">
						<div v-for="(v, k) in FORMC36.items" :key="k">
							<strong style="font-size: 12px">{{
								k +
								1 +
								". " +
								v.item_category +
								", " +
								v.name +
								", " +
								v.make +
								", " +
								v.model +
								"(Condition: " +
								v.item_condition +
								")"
							}}</strong>
							<br />
							<em
								>Quantity: {{ v.quantity }}, Item Price: {{ FORMC36.invoice_currency }}{{ v.price }}, Size:
								{{ v.dimensions.L }}{{ v.dimensions.format }} by {{ v.dimensions.W }}{{ v.dimensions.format }} by {{ v.dimensions.H }}{{ v.dimensions.format }},
								Weight: {{ v.weight.value }}{{ v.weight.format }}
							</em>
						</div>
					</div>
					<br />
					<div class="d-flex justify-space-between">
						<h6>
							<i class="fas fa-money-bill-wave-alt"></i> ITEM COST: 
							<span class="text-red">{{ FORMC36.invoice_currency + "" + getItemCost(FORMC36.items).toFixed(2) }}</span>
						</h6>
						<h6>
							<i class="fas fa-money-check-alt"></i> TOTAL SHIPPING COST: 
							<span class="text-red">{{ FORMC36.invoice_currency + "" + parseFloat(FORMC36.customs_value).toFixed(2) }}</span> 
						</h6>
						<h6>
							<i class="fas fa-balance-scale-right"></i> TOTAL WEIGHT: 
							<span class="text-red">{{ FORMC36.total_weight }}{{ FORMC36.form_weight_format }}</span> 
						</h6>
					</div>
				</div>
			</div>
		</div>
		<div class="col-md-4 col-sm-12 pb-2">
			<div class="card">
				<div class="card-header">
					<i class="fas fa-cubes text-default"></i> Data from Origin
				</div>
				<div class="card-body">
					<div class="items-list">
						<div v-for="(v, k) in Origin.items" :key="k">
							<strong style="font-size: 12px">{{
								k +
								1 +
								". " +v.name
							}}</strong>
							<br />
							<em
								>Quantity: {{ v.quantity }}, Item Price: {{ FORMC36.invoice_currency }}{{ v.price }}
							</em>
						</div>
					</div>
					<br />
					<div class="d-flex justify-space-between">
						<h6>
							<i class="fas fa-money-bill-wave-alt"></i> ITEM COST: 
							<span class="text-red">{{ FORMC36.invoice_currency + "" + parseFloat(Origin.total_cost).toFixed(2) }}</span>
						</h6>
						<h6>
							<i class="fas fa-money-check-alt"></i> TOTAL SHIPPING COST: 
							<span class="text-red">{{ FORMC36.invoice_currency + "" + parseFloat(Origin.customs_value).toFixed(2) }}</span> 
						</h6>
						<h6>
							<i class="fas fa-balance-scale-right"></i> TOTAL WEIGHT: 
							<span class="text-red">{{ Origin.total_weight }}{{ FORMC36.form_weight_format }}</span> 
						</h6>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script setup>
import { computed, inject, onMounted, ref } from "vue";
import { fetchDataOrigin, fetchFormData, fetchOfferData } from "@/scripts/data";

const SERVER_LOCATION = inject("SERVER_LOCATION");
const SERVER = SERVER_LOCATION.value;
const USER = inject("loggedInUser");

const props = defineProps(["form", "fid"]);

const { form, fid }  = props;
const FORMC36 = form;
const Origin = ref([{items:[]}]);

const getItemCost = (items) => {
	let price = 0;
	for (let it of items) {
		let linePrice = parseFloat(it.quantity) * parseFloat(it.price);
		price += linePrice;
	}
	return price;
}

// const getItemWeight = (items, type='normal') => {
// 	let weightD = 0;
// 	for (let it of items) {
// 		let weight = parseFloat(it.quantity) * parseFloat(it.price);
// 		weightD += weight;
// 	}
// 	return weightD;
// }

onMounted(async () => {
	if (parseInt(fid) > 0) {
		let dataT = await fetchDataOrigin(SERVER, fid, USER.value.token);
		console.log("===========>", dataT);
		Origin.value = dataT.origin;
		console.log()
	}
});
</script>

<style>

</style>