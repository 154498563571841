<template>
  <div class="items-holder px-2" v-if="cargoItems.length">
    <span v-for="(item, index) in cargoItems" :key="index">
      <FormC36Item :data="item" />
    </span>
  </div>
  <div class="alert alert-secondary text-muted" v-else>
    <i class="fas fa-info-circle"></i> No items added yet.
  </div>
  <small
    class="btn btn-transparent btn-sm text-center text-info"
    @click="addItemRow"
    style="width: 100%; border: 1px solid #d0dbe6"
  >
    <small><i class="fas fa-plus"></i> Add Cargo Item</small>
  </small>
</template>

<script setup>
import { defineModel } from "vue";
import FormC36Item from "./FormC36Item.vue";

const originalWeightFormat = "KG";
const originalDimensionsFormat = "CM";

let newRow = {
  name: "",
  quantity: 0,
  price: 0,
  item_category: "",
  item_condition: "",
  make: "",
  model: "",
  weight: {value: "", format: originalWeightFormat},
  dimensions: {
    L: "",
    W: "",
    H: "",
    format: originalDimensionsFormat
  },
  total_price: 0,
  visible: true,
};

const cargoItems = defineModel({
  default: [
    {
      name: "",
      quantity: 0,
      price: 0,
      item_category: "",
      item_condition: "",
      make: "",
      model: "",
      weight: {value: 0, format: originalWeightFormat},
      dimensions: {
        L: "",
        W: "",
        H: "",
        format: originalDimensionsFormat
      },
      total_price: 0,
      visible: true,
    },
  ],
});

const addItemRow = () => {
  let nR = { ...newRow };
  cargoItems.value = [...cargoItems.value, nR];
};
</script>