<template>
  <div v-if="attachment.visible" class="mx-1">
    <div v-if="deletingFile" class="align-center text-center">
      <i class="fas fa-spin fa-spinner"></i> Please wait as we remove the attachment...
    </div>
    <div v-else>
      <div class="row file-attachment no-gutters details" v-if="hasData">
        <div class="row g-0">
          <div class="col-sm-3 py-2 px-3">{{ attachment.type }}</div>
          <div class="col-sm-3 py-2 px-3">{{ attachment.document_number }}</div>
          <div class="col-sm-3 py-2 px-3">{{ attachment.date }}</div>
          <div class="col-sm-3 py-2 px-3">
            <a :href="attachment.path" class="text-blue" target="_blank">Download <i class="fas fa-download"></i></a>
          </div>
        </div>
        <div class="m-1" v-if="confirmDeleteMsg">
          <div class="alert alert-warning">
            <i class="fas fa-warning"></i> Are you sure you want to remove this
            file?
            <span
              class="badge bg-white text-black"
              @click="confirmDeleteMsg = false"
              >No</span
            >
            &nbsp;&nbsp;
            <span class="badge bg-danger" @click="deleteConfirmed">Yes</span>
          </div>
        </div>
        <span class="file-remove" @click="deleteFile">
          <i class="fas fa-trash"></i>
        </span>
      </div>
      <div class="row file-attachment" v-else>
        <div class="col-sm-3">
          <select class="small" v-model="attachment.type">
            <option value="">Attachment Type</option>
            <option value="Contract">Contract</option>
            <option value="Previous Invoice">Previous Invoice</option>
            <option value="Other">Other</option>
          </select>
        </div>
        <div class="col-sm-3">
          <input
            v-model="attachment.document_number"
            type="text"
            @input="updateFile"
            class="text-input small"
            placeholder="Document No..."
          />
        </div>
        <div class="col-sm-3">
          <input
            v-model="attachment.date"
            type="date"
            @input="updateFile"
            class="text-input small"
            placeholder="Document Date"
          />
        </div>
        <div class="col-sm-3">
          <input
            @change="setFile"
            type="file"
            class="text-input small"
          />
        </div>
        <span class="file-remove" @click="deleteFile">
          <i class="fas fa-trash"></i>
        </span>
      </div>
    </div>
  </div>
</template>

<script setup>
import { defineProps, inject, ref } from "vue";

const deletingFile = ref(false);
const hasData = ref(false);
const deleting = ref(false);
const confirmDeleteMsg = ref(false);
const props = defineProps(['attachment']);
const { attachment } = props;

hasData.value = attachment.id != null;

const setFile = (e) => {
  attachment.file = e.target.files[0];
};

const deleteAttachment = inject('deleteAttachment');

const deleteFile = async () => {
  if(hasData){
    confirmDeleteMsg.value = true;
    deletingFile.value = true;
    let status = await deleteAttachment(attachment.id); 
    if(status){
      deletingFile.value = false;
    }
  }
  confirmDeleteMsg.value = false;
  attachment.visible = false;
}

</script>

<style>
.row.file-attachment {
  position: relative;
  clear: both !important;
}

.file-attachment.details{
  font-family: 'Courier New', Courier, monospace;
  font-weight: 500;
  font-size: 12px;
  background-color: #fcfcfc;
  border-bottom: 1px solid #f1f1f1;
}

.file-attachment.details:first-child{
  border-top: 1px solid #f9f9f9;
}

.file-attachment.details:nth-child(even){
  background-color: #e9e9e9;
}

.file-attachment:not(.row) {
  position: relative;
  width: unset !important;
  float: left;
}
.file-attachment a {
  font-size: 11px !important;
  padding: 5px 7px;
  margin-right: 5px;
  text-decoration: none;
}

.row.file-attachment .badge {
  cursor: pointer;
}

.file-remove {
  position: absolute;
  top: 10px;
  right: 0px;
  width: 25px;
  height: 18px;
  color: rgb(146, 109, 109);
  font-weight: 500;
  transition: 500ms color linear;
}

.file-remove:hover {
  cursor: pointer;
  transition: 500ms color linear;
  color: crimson;
}
</style>