<template>
  <TrackingVesselModal v-model="modalData"></TrackingVesselModal>
	<div class="home">
    <div class="document-toolbar p-1">
      <div
        class="btn-toolbar"
        role="toolbar"
        aria-label="Toolbar with button groups"
      >
        <div
          class="btn-group me-2 btn-group-sm border-none"
          role="group"
          aria-label="First group"
        >
          <button class="btn"></button>
          <button
            type="button"
            class="btn btn-outline-primary"
            @click="fetchDocuments"
          >
            <span v-if="loadingDocuments"
              ><i class="fas fa-rotate-right fa-spin"></i
            ></span>
            <span v-else><i class="fas fa-rotate-right"></i></span>
            Refresh
          </button>
          <a
            type="button"
            class="btn btn-outline-primary"
            :href="SERVER + '/forms/export'"
          >
            <i class="fas fa-download"></i> Export All
          </a>
          <div class="input-group input-group-sm" style="width: 300px">
            <input
              v-model="FILTER"
              type="search"
              class="form-control form-control-sm"
              placeholder="Search..."
              style="width: 250px; margin: 0 auto"
            />
          </div>
          <div class="input-group input-group-sm ml-1" style="width: 300px">
            <input
              v-model="search_mmsi"
              type="search"
              class="form-control form-control-sm"
              placeholder="Track vessel by MMSI..."
              style="width: 250px; margin: 0 auto"
            />
          </div>
            <button class="btn btn-success ml-1 text-white" @click="fetchVesselByMMSI"><i class="fas fa-search"></i> FIND</button>
        </div>
      </div>
    </div>
		<div class="table-responsive">
			<table class="table table-striped table-bordered">
				<thead>
					<tr>
						<th>#</th>
						<th>Tracking Number</th>
						<th>Vessel Name</th>
						<th>Vessel MMSI Number</th>
						<th>Origin Port</th>
						<th>Destination Port</th>
						<th>Estimated Arrival Date</th>
						<th>Shipper Name</th>
						<th>Consignee Name</th>
						<th>Shipment Description</th>
						<th>Container Number</th>
						<th>Container Type</th>
						<th>Shipment Weight</th>
						<th>Shipment Volume</th>
						<th>Shipping Line</th>
						<th>Shipment Status</th>
						<th>Actions</th>
					</tr>
				</thead>
				<tbody v-if="shipmentsVisible.length">
					<tr v-for="(shipment, index) in shipmentsVisible" :key="index">
						<td>{{ index + 1 }}</td>
						<td width="1%" style="white-space: nowrap; cursor:pointer" @click="startVesselTracking(shipment)">
							<span class="text-green">
								<i class="fas fa-map-marker"></i> {{ shipment.tracking_number }}
							</span>
						</td>
						<td>{{ shipment.vessel_name }}</td>
						<td>{{ shipment.vessel_mmsi_number }}</td>
						<td>{{ shipment.origin_port }}</td>
						<td>{{ shipment.destination_port }}</td>
						<td>{{ shipment.estimated_arrival_date }}</td>
						<td>{{ shipment.shipper_name }}</td>
						<td>{{ shipment.consignee_name }}</td>
						<td>{{ shipment.shipment_description }}</td>
						<td>{{ shipment.container_number }}</td>
						<td>{{ shipment.container_type }}</td>
						<td>{{ shipment.shipment_weight }}</td>
						<td>{{ shipment.shipment_volume }}</td>
						<td>{{ shipment.shipping_line }}</td>
						<td>{{ shipment.shipment_status }}</td>
						<td></td>
					</tr>
				</tbody>
				<tbody v-else>
					<tr>
						<td colspan="17">
							<div class="py-3 px-2 text-center align-center" v-if="shipmentsVisible.length">
								<i class="fas fa-info-circle"></i> No records available
							</div>
							<div class="py-3 px-2 text-center align-center" v-else>
								<i class="fas fa-info-circle"></i> No records matching the filter
							</div>
						</td>
					</tr>
				</tbody>
			</table>
		</div>
	</div>
</template>

<style scoped>
th {
	font-weight: 700 !important;
}

.modal-background{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0,0,0,0.2);
  z-index: 999999;
}
</style>

<script setup>
import { getShipments } from "@/scripts/data";
import { ref, computed } from "vue";
import TrackingVesselModal from '@/components/layout/Shipment/TrackVesselModal';

const shipments = ref(getShipments());
const FILTER = ref('');
const tracked_vessel = ref(null);
const trackingVisible = ref(false);
const loadingTrackingData = ref(false);
const search_mmsi = ref('');

const modalData = ref({
  visible: false,
  loading: false,
  vessel: {name: '', mmsi: ''}
});

const startVesselTracking = (shipment) => {
  modalData.value.vessel = { name: shipment.vessel_name, mmsi: shipment.vessel_mmsi_number };
  modalData.value.visible = true;
  modalData.value.loading = true;
}

const fetchVesselByMMSI = () => {
  if(search_mmsi.value >= ""){
    startVesselTracking({vessel_mmsi_number: search_mmsi.value, vessel_name: ""})
  }
}

const shipmentsVisible = computed(() => {
  return shipments.value.filter(shipment => {
    if(FILTER.value == '' || FILTER.value == null){
      return true;
    }

    const trackingNumber = shipment.tracking_number.toLowerCase().includes(FILTER.value);
    const vesselName = shipment.vessel_name.toLowerCase().includes(FILTER.value);
    const vesselIMO = shipment.vessel_imo_number.toLowerCase().includes(FILTER.value);

    return trackingNumber || vesselName || vesselIMO;
  });
})

</script>
