<template>
	<div style="display: flex">
		<div style="padding: 10px; width: 33.33%;">
			<div id="qrcode"></div>
		</div>
		<div style="padding: 10px; width: 66.66%;">
			<strong>REPUBLIC OF KENYA</strong><br>
			<strong>KENYA REVENUE AUTHORITY</strong>
		</div>
		<div style="padding: 10px; width: 33.33%;">
			<span style="font-size:22px">FORM C36</span><br>
			FORM NO : <span style="font-style: italic;"> {{ FORM.form_number }}</span>
		</div>
	</div>
	<p>
		Customs Services Department<br>
		<strong>DECLARATION OF PARTICULARS RELATING TO CUSTOMS VALUE</strong>
	</p>
	<!-- Bootstrap JS -->
	<table class="myTable">
		<thead>
			<tr>
				<td colspan="7">
					<strong>Import Name</strong><br>
					{{ FORM.name_of_importer }}
				</td>
				<td colspan="5">
					<table class="innerTable">
						<tr>
							<td class="noborder-right noborder-left"><strong>PIN</strong> <span class="importer-pin">{{ FORM.buyer }}</span></td>
						</tr>
						<tr>
							<td class="noborder-right noborder-left"><strong>Email</strong> <span class="importer-email">{{ FORM.consignee_email }}</span></td>
						</tr>
						<tr>
							<td class="noborder-right noborder-left"><strong>Phone</strong> <span class="importer-phone">{{ FORM.consignee_telephone }}</span></td>
						</tr>
					</table>
				</td>
			</tr>
			<tr>
				<td colspan="3">
					<strong>Seller Name</strong><br>
					<span class="seller-name">{{ FORM.seller }}</span>
				</td>
				<td colspan="3">
					<strong>Seller Country</strong><br>
					<span class="seller-country">{{ FORM.seller_country }}</span>
				</td>
				<td colspan="3">
					<strong>Port of Entry</strong><br>
					<span class="port-of-entry">{{ FORM.port_of_entry }}</span>
				</td>
				<td colspan="3">
					<strong>Terms of Delivery</strong><br>
					<span class="terms_of_delivery">{{ FORM.terms_of_delivery }}</span>
				</td>
			</tr>
			<tr>
				<td colspan="2">
					<strong>Invoice</strong><br>
					<span class="invoice_number">{{ FORM.invoice_number }}</span>
				</td>
				<td colspan="2">
					<strong>Invoice Date</strong><br>
					<span class="invoice_date">{{ FORM.invoice_date }}</span>
				</td>
				<td colspan="2">
					<strong>Invoice Currency</strong><br>
					<span class="invoice_currency">{{ FORM.invoice_currency }}</span>
				</td>
				<td colspan="2">
					<strong>Invoice Net Price</strong><br>
					<span class="invoice_net_price">{{ FORM.invoice_net_price }}</span>
				</td>
				<td colspan="2">
					<strong>Contract</strong><br>
					<span class="invoice_number">{{ FORM.contract_number }}</span>
				</td>
				<td colspan="2">
					<strong>Contract Date</strong><br>
					<span class="invoice_number">{{ FORM.contract_date }}</span>
				</td>
			</tr>
			<tr v-for="(kys,index) in formKeys" :key="index">
					<td v-for="k in kys" :key="k" colspan="2">
						<strong>{{ removeUnderscore(k) }}</strong><br>
						<span class="smt">{{ FORM[k] }}</span>
					</td>
			</tr>
		</thead>
	</table>
	<br>
	<div class="w-100" style="text-align: left !important;">
		<strong>Items</strong>
	</div>
	<table class="myTable">
		<thead>
			<tr>
				<th>Name</th>
				<th>Model</th>
				<th>Make</th>
				<th>Category</th>
				<th>Status</th>
				<th>Dimensions</th>
				<th>Weight</th>
				<th>Qty</th>
				<th>Price({{ FORM.invoice_currency }})</th>
				<th>Total({{ FORM.invoice_currency }})</th>
			</tr>
		</thead>
		<tbody>
			<tr v-for="(item, index) in FORM.items" :key="index">
				<td>{{ item.name }}</td>
				<td>{{ item.model }}</td>
				<td>{{ item.make }}</td>
				<td>{{ item.item_category }}</td>
				<td>{{ item.item_condition }}</td>
				<td>{{ item.dimensions.L }} x {{ item.dimensions.W }} x {{ item.dimensions.H }} {{ item.dimensions.format }}</td>
				<td class="text-right">{{ item.weight.value.toLocaleString() }}{{ item.weight.format }}</td>
				<td class="text-right">{{ item.quantity.toLocaleString() }}</td>
				<td class="text-right">{{ item.price.toLocaleString() }}</td>
				<td class="text-right">{{ item.total_price.toLocaleString() }}</td>
			</tr>
		</tbody>
		<tfoot>
			<tr class="text-bold">
				<td colspan="6" class="text-right">TOTAL</td>
				<td class="text-right">{{ itemTotals.weight }} {{ FORM.form_weight_format }}</td>
				<td class="text-right">{{ itemTotals.quantity.toLocaleString() }}</td>
				<td colspan="2" class="text-right">{{ itemTotals.total_price.toLocaleString() }}</td>
			</tr>
		</tfoot>
	</table>
	<br>
	<div class="w-100" style="text-align: left !important;">
		<strong>Additional Charges</strong>
	</div>
	<table class="myTable" id="other-fields">
		<thead>
			<tr>
				<th>Name</th>
				<th>Description</th>
				<th>Charge Amount({{ FORM.invoice_currency }})</th>
			</tr>
		</thead>
		<tbody>
			<tr v-for="(charge, index) in FORM.other_charges" :key="index">
				<td>{{ charge.title }}</td>
				<td>{{ charge.description }}</td>
				<td class="text-right">{{ charge.amount.toLocaleString() }}</td>
			</tr>
		</tbody>
		<tfoot>
			<tr class="text-bold">
				<td colspan="2" class="text-right">TOTAL</td>
				<td class="text-right">{{ totalAdditionalCharge.toLocaleString() }}</td>
			</tr>
		</tfoot>
	</table>
	<br>
	<hr>
	<div style="display: flex;">
		<div style="text-align: right !important; width:80%"><strong>TOTAL</strong></div>
		<div style="text-align: left !important; width: 20%;"><strong class="customs-value">
			{{ FORM.invoice_currency+''+parseFloat(FORM.customs_value).toLocaleString() }}</strong>
		</div>
	</div>
</template>

<script setup>
import { fetchFormData, UnitConverter } from "@/scripts/data";
import { inject, onMounted, ref } from "vue";

const USER = inject("loggedInUser");
const setPrintPage = inject("setPrintPage");
const SERVER_LOCATION = inject("SERVER_LOCATION");
const SERVER = SERVER_LOCATION.value;

const props = defineProps(['id']);
const FORM = ref({});
let totalAdditionalCharge = ref(0);
let itemTotals = ref({"weight": 0, "quantity": 0, "total_price": 0});
const formProperties = ["form_number", "created_at", "updated_at", "deleted_at", "has_otp_code", "attachments", "process_status", "id", "form_type", "otp_methods","confirm_otp_code", "user_id","form_number", "name_of_importer", "buyer", "consignee_email", "consignee_telephone", "seller", "seller_country", "port_of_entry", "terms_of_delivery", "invoice_number", "invoice_date", "invoice_currency", "invoice_net_price", "contract_number", "contract_date", "items", "other_charges"];
const getItemTotals = () => {
	let totals = {"weight": 0, "quantity": 0, "total_price": 0};
	for(let i in FORM.value.items){
		let item = FORM.value.items[i];
		totals.weight += UnitConverter.LBKGConverter(item.weight.value, item.weight.format, FORM.value.form_weight_format);
		totals.quantity += parseFloat(item.quantity);
		totals.total_price += parseFloat(item.total_price);
	}
	return totals;
};

const removeUnderscore = (str) => {
	// let strP = JSON.stringify(str);
	return (str.split('_')).join(' ');
}

const groupByLength = (arr, length) => {
  if (!Array.isArray(arr) || length <= 0) {
    throw new Error("Invalid arguments: Input must be an array and length must be a positive number");
  }

  const result = [];
  let currentGroup = [];

  for (const element of arr) {
		if(formProperties.includes(element)){
			continue;
		}
    currentGroup.push(element);

    if (currentGroup.length === length) {
      result.push(currentGroup);
      currentGroup = []; // Reset the current group for the next elements
    }
  }

  // Add the remaining elements from the last incomplete group (if any)
  if (currentGroup.length > 0) {
    result.push(currentGroup);
  }

  return result;
}

const getAdditionalChargesTotal = () => {
	let additionalCharges = 0;
	for(let i in FORM.value.other_charges){
		let charge = FORM.value.other_charges[i];
		additionalCharges += parseFloat(charge.amount) 
	}

	return additionalCharges;
}

const formKeys = ref([]);

onMounted(async ()=>{
	let data = await fetchFormData(SERVER, props.id, USER.value.token);
	FORM.value = data.form;

	formKeys.value = groupByLength(Object.keys(data.form), 6);

	itemTotals = getItemTotals();
	totalAdditionalCharge = getAdditionalChargesTotal();

	setPrintPage();
});

</script>
<style>
html,body,#app{
	background-color: #fff;
}

.text-bold{
	font-weight:600;
}

.text-right{
	text-align: right !important;
}

html {
  height: 100%;
  min-height: 800px;
}

body {
  font-family: 'Noto Sans', sans-serif;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.table th {
  padding: 15px 10px;
}

table th,
table tr {
  font-size: 12px !important;
}

td span[class]{
	font-family:'Courier New', Courier, monospace;
	font-style: italic;
}

td strong{
	text-transform: capitalize;
}

.myTable{
  width: 100%;
  border-collapse: collapse;
}

.myTable td, .innerTable td{
  border: 1px solid #000;
  text-align: left !important;
  padding: 3px 5px;
  vertical-align: top;
}

.innerTable{
  margin: -3px -5px;
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0cm;
}

.innerTable td{
  padding: 3px;
}

.innerTable tr:first-child td, .innerTable tr:first-child td{
  border: 1px solid #000;
}

.innerTable tr:last-child td, .innerTable tr:last-child td{
  border: 1px solid #000;
  border-bottom: none !important;
}

td.noborder-left{
  border-left: none !important;
}

td.noborder-right{
  border-left: none !important;
}

.myTable td{
  font-style: italic;
}

.myTable td strong{
  font-style: normal !important;
}
</style>