<template>
  <div class="rates">
    <h1>Logging you out...</h1>
  </div>
</template>
<style>
</style>
<script setup>
import { onBeforeMount } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
	const store = useStore();
	const router = useRouter();
	onBeforeMount(() => {
		store.commit('setUser', null);
    store.commit('setToken', null);
    router.push({name: 'login'});
    location.reload();
	});
</script>
