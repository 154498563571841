<template>
  <div class="home">
    <h1>This is shipment page</h1>
  </div>
</template>

<style>
#app{
	
}
</style>