<template>
	<p style="padding-top: 3pt;padding-left: 22pt;text-indent: 0pt;line-height: 11pt;text-align: left;">EAST AFRICAN COMMUNITY                                      C.36 A</p>
	<p style="padding-left: 468pt;text-indent: 0pt;line-height: 11pt;text-align: left;">CUSTOMS</p>
	<p style="text-indent: 0pt;text-align: left;"><br/></p>
	<p class="s1" style="text-indent: 0pt;text-align: right;">Regulation 197 (1), (2)</p>
	<p class="s2" style="padding-top: 1pt;padding-left: 115pt;text-indent: 0pt;text-align: left;">DECLARATION OF PARTICULARS RELATING TO CUSTOMS VALUE</p>
	<p style="text-indent: 0pt;text-align: left;"><br/></p>
	<table style="border-collapse:collapse;margin-left:6.40999pt" cellspacing="0">
			<tr style="height:36pt">
				<td style="width:384pt;border-top-style:solid;border-top-width:1pt;border-left-style:solid;border-left-width:1pt;border-bottom-style:solid;border-bottom-width:1pt;border-right-style:solid;border-right-width:1pt">
						<p class="s3" style="padding-left: 1pt;text-indent: 0pt;line-height: 9pt;text-align: left;">1.  Buyer <span class="hand-written">{{ FORM.name_of_importer }} - {{ FORM.buyer }}</span></p>
				</td>
				<td style="width:164pt;border-top-style:solid;border-top-width:1pt;border-left-style:solid;border-left-width:1pt;border-bottom-style:solid;border-bottom-width:1pt;border-right-style:solid;border-right-width:1pt" rowspan="3">
						<p class="s3" style="padding-left: 45pt;text-indent: 0pt;line-height: 9pt;text-align: left;">FOR OFFICIAL USE</p>
				</td>
			</tr>
			<tr style="height:34pt">
				<td style="width:384pt;border-top-style:solid;border-top-width:1pt;border-left-style:solid;border-left-width:1pt;border-bottom-style:solid;border-bottom-width:1pt;border-right-style:solid;border-right-width:1pt">
						<p class="s3" style="padding-left: 1pt;text-indent: 0pt;line-height: 9pt;text-align: left;">2.  Seller <span class="hand-written">{{ FORM.seller }} - {{ FORM.seller_country }}</span></p>
				</td>
			</tr>
			<tr style="height:36pt">
				<td style="width:384pt;border-top-style:solid;border-top-width:1pt;border-left-style:solid;border-left-width:1pt;border-bottom-style:solid;border-bottom-width:1pt;border-right-style:solid;border-right-width:1pt">
						<p class="s3" style="padding-left: 1pt;text-indent: 0pt;line-height: 9pt;text-align: left;">3.  Number and date of invoice</p>
						<div style="display: flex; flex-direction: row; width: 100%;">
							<div style="text-align: left; padding: 7px 5px; width: 50%;">Number: <span class="hand-written"> {{ FORM.invoice_number  }}</span></div>
							<div style="text-align: left; padding: 7px 5px; width: 50%">Date: <span class="hand-written"> {{ FORM.invoice_date }}</span></div>
						</div>
				</td>
			</tr>
			<tr style="height:35pt">
				<td style="width:384pt;border-top-style:solid;border-top-width:1pt;border-left-style:solid;border-left-width:1pt;border-bottom-style:solid;border-bottom-width:1pt;border-right-style:solid;border-right-width:1pt">
						<p class="s3" style="padding-left: 1pt;text-indent: 0pt;line-height: 9pt;text-align: left;">4.  Number and date of contract</p>
						<div style="display: flex; flex-direction: row; width: 100%;">
							<div style="text-align: left; padding: 7px 5px; width: 50%;">Number: <span class="hand-written"> {{ FORM.contract_number  }}</span></div>
							<div style="text-align: left; padding: 7px 5px; width: 50%">Date: <span class="hand-written"> {{ FORM.contract_date }}</span></div>
						</div>
				</td>
				<td style="width:164pt;border-top-style:solid;border-top-width:1pt;border-left-style:solid;border-left-width:1pt;border-bottom-style:solid;border-bottom-width:1pt;border-right-style:solid;border-right-width:1pt">
						<p class="s3" style="padding-left: 1pt;text-indent: 0pt;line-height: 9pt;text-align: left;">5. Terms of delivery (CIF, FOB, C&amp;F, C&amp;I)</p>
						<span class="hand-written">{{ FORM.terms_of_delivery }}</span>
				</td>
			</tr>
			<tr style="height:35pt">
				<td style="width:470pt;border-top-style:solid;border-top-width:1pt;border-left-style:solid;border-left-width:1pt;border-bottom-style:solid;border-bottom-width:1pt;border-right-style:solid;border-right-width:1pt" colspan="2">
					<p class="s3" style="padding-left: 1pt;text-indent: 0pt;line-height: 9pt;text-align: left;">6.  Number and date of previous Customs decision covering boxes 7 to 8</p>
					<div style="display: flex; flex-direction: row; width: 100%;">
						<div style="text-align: left; padding: 2px 10px; width: 50%;">Previous Relationship Number: <span class="hand-written"> {{ FORM.previous_relationship_invoice_number }}</span></div>
						<div style="text-align: left; padding: 2px 10px; width: 50%">Previous Relationship Date: <span class="hand-written"> {{ FORM.previous_relationship_invoice_date }}</span></div>
					</div>
					<div style="display: flex; flex-direction: row; width: 100%;">
						<div style="text-align: left; padding: 2px 10px; width: 50%;">Previous Restriction Number: <span class="hand-written"> {{ FORM.previous_restrictions_invoice_number }}</span></div>
						<div style="text-align: left; padding: 2px 10px; width: 50%">Previous Restriction Date: <span class="hand-written"> {{ FORM.previous_restrictions_invoice_date }}</span></div>
					</div>
				</td>
				<td style="width:78pt;border-top-style:solid;border-top-width:1pt;border-left-style:solid;border-left-width:1pt;border-bottom-style:solid;border-bottom-width:1pt;border-right-style:solid;border-right-width:1pt">
					<span class="hand-written" v-if="FORM.previous_relationship_invoice_date || FORM.previous_relationship_invoice_number || FORM.previous_restrictions_invoice_number || FORM.previous_restrictions_invoice_date">YES</span>
					<span class="hand-written" v-else>NO</span>
				</td>
			</tr>
			<tr style="height:23pt">
				<td style="width:470pt;border-top-style:solid;border-top-width:1pt;border-left-style:solid;border-left-width:1pt;border-bottom-style:solid;border-bottom-width:1pt;border-right-style:solid;border-right-width:1pt" colspan="2" rowspan="3">
						<ol id="l1">
							<li data-list-text="7.">
									<p class="s3" style="padding-left: 23pt;text-indent: -22pt;line-height: 9pt;text-align: left;">(a) Is the buyer and seller related (as per paragraph 3 &amp; 4 of the 4th Schedule to the Act)? If “NO” go to box 8</p>
									<p style="text-indent: 0pt;text-align: left;"><br/></p>
									<ol id="l2">
										<li data-list-text="(b)">
												<p class="s3" style="padding-left: 35pt;text-indent: -12pt;text-align: left;">Did the relationship influence the price of the imported goods?</p>
												<p style="text-indent: 0pt;text-align: left;"><br/></p>
										</li>
										<li data-list-text="(c)">
											<p class="s3" style="padding-left: 35pt;text-indent: -11pt;text-align: left;">Does the transaction value of the imported goods approximate to the customs value? If “YES?” give details
												<br>
												<span class="hand-written">{{ FORM.transaction_value_approximates_customs_value_details }}</span>
											</p>
										</li>
									</ol>
							</li>
						</ol>
				</td>
				<td style="width:78pt;border-top-style:solid;border-top-width:1pt;border-left-style:solid;border-left-width:1pt;border-bottom-style:solid;border-bottom-width:1pt;border-right-style:solid;border-right-width:1pt">
						<p class="s4" style="padding: 5pt 21pt;text-indent: 0pt;line-height: 9pt;text-align: left;"><span class="s5"><span class="hand-written">{{ FORM.buyer_seller_related }}</span></span></p>
				</td>
			</tr>
			<tr style="height:23pt">
				<td style="width:78pt;border-top-style:solid;border-top-width:1pt;border-left-style:solid;border-left-width:1pt;border-bottom-style:solid;border-bottom-width:1pt;border-right-style:solid;border-right-width:1pt">
						<p class="s3" style="padding: 5pt 21pt;text-indent: 0pt;line-height: 9pt;text-align: left;"><span class="hand-written">{{ FORM.relationship_influenced_price }}</span></p>
				</td>
			</tr>
			<tr style="height:34pt">
				<td style="width:78pt;border-top-style:solid;border-top-width:1pt;border-left-style:solid;border-left-width:1pt;border-bottom-style:solid;border-bottom-width:1pt;border-right-style:solid;border-right-width:1pt">
						<p class="s4" style="padding: 5pt 21pt;text-indent: 0pt;line-height: 9pt;text-align: left;"><span class="s5 hand-written">{{ FORM.transaction_value_approximates_customs_value }}</span></p>
				</td>
			</tr>
			<tr style="height:60pt">
				<td style="width:470pt;border-top-style:solid;border-top-width:1pt;border-left-style:solid;border-left-width:1pt;border-bottom-style:solid;border-bottom-width:1pt;border-right-style:solid;border-right-width:1pt" colspan="2" rowspan="2">
						<ol id="l3">
							<li data-list-text="8.">
									<p class="s3" style="padding-left: 23pt;text-indent: -22pt;line-height: 9pt;text-align: left;">(a) Are there any restrictions as to the disposition or use of the goods by buyer, other than regulations which</p>
									<ul id="l4">
										<li data-list-text="-">
												<p class="s3" style="padding-top: 3pt;padding-left: 28pt;text-indent: -4pt;text-align: left;">are imposed or required by law or by the public authorities in the Community</p>
										</li>
										<li data-list-text="-">
												<p class="s3" style="padding-top: 2pt;padding-left: 28pt;text-indent: -4pt;text-align: left;">limit the geographical areas in which the goods may be re-sold, or</p>
										</li>
										<li data-list-text="-">
												<p class="s3" style="padding-top: 3pt;padding-left: 28pt;text-indent: -4pt;text-align: left;">do not substantially affect the value of the goods?</p>
										</li>
									</ul>
							</li>
						</ol>
						<p style="text-indent: 0pt;text-align: left;"><br/></p>
						<p class="s3" style="padding-left: 23pt;padding-right: 28pt;text-indent: 0pt;line-height: 127%;text-align: left;">(b) is the sale or price of goods subject to some condition or consideration for which a value cannot be determined with respect to the goods being valued?</p>
						<p style="text-indent: 0pt;text-align: left;"><br/></p>
						<p class="s3" style="padding-left: 23pt;text-indent: 0pt;text-align: left;">Specify the nature of the restrictions, conditions of considerations as appropriate:
							<br/><span class="hand-written">{{ FORM.restriction_details }}</span>
						</p>
						<p style="text-indent: 0pt;text-align: left;"><br/></p>
						<p class="s3" style="padding-top: 7pt;padding-left: 23pt;text-indent: 0pt;text-align: left;">If the value of conditions or considerations can be determined, indicate the amount in box 11(b)</p>
				</td>
				<td style="width:78pt;border-top-style:solid;border-top-width:1pt;border-left-style:solid;border-left-width:1pt;border-bottom-style:solid;border-bottom-width:1pt;border-right-style:solid;border-right-width:1pt">
						<p class="s4" style="padding: 5pt 21pt;text-indent: 0pt;line-height: 9pt;text-align: left;"><span class="s5 hand-written">{{ FORM.restrictions_on_goods }}</span></p>
				</td>
			</tr>
			<tr style="height:106pt">
				<td style="width:78pt;border-top-style:solid;border-top-width:1pt;border-left-style:solid;border-left-width:1pt;border-bottom-style:solid;border-bottom-width:1pt;border-right-style:solid;border-right-width:1pt">
						<p class="s4" style="padding: 5pt 21pt;text-indent: 0pt;line-height: 9pt;text-align: left;"><span class="s5 hand-written">{{ FORM.condition_or_consideration_for_value }}</span></p>
				</td>
			</tr>
			<tr style="height:35pt">
				<td style="width:470pt;border-top-style:solid;border-top-width:1pt;border-left-style:solid;border-left-width:1pt;border-bottom-style:solid;border-bottom-width:1pt;border-right-style:solid;border-right-width:1pt" colspan="2" rowspan="2">
						<p class="s3" style="padding-left: 23pt;padding-right: 72pt;text-indent: -22pt;line-height: 127%;text-align: left;">9.  (a) Have any ROYALITIES and LICENCE FEES on the imported goods been paid either directly or indirectly by the buyer as a condition of the sale?</p>
						<p style="text-indent: 0pt;text-align: left;"><br/></p>
						<p class="s3" style="padding-left: 23pt;padding-right: 72pt;text-indent: 0pt;line-height: 134%;text-align: left;">(b) Is the sale subject to an arrangement under which part of the proceeds of any subsequent RESALE DISPOSAL or USE accrues directly or indirectly to the seller?</p>
						<p style="text-indent: 0pt;text-align: left;"><br/></p>
						<p class="s3" style="padding-left: 23pt;text-indent: 0pt;text-align: left;">If “YES” to either of these questions, specify conditions and if possible indicate the amount in boxes 15 and 16.</p>
				</td>
				<td style="width:78pt;border-top-style:solid;border-top-width:1pt;border-left-style:solid;border-left-width:1pt;border-bottom-style:solid;border-bottom-width:1pt;border-right-style:solid;border-right-width:1pt">
					<p class="s4" style="padding: 5pt 21pt;text-indent: 0pt;line-height: 9pt;text-align: left;"><span class="s5 hand-written">{{ otherCharges['Royalties and Licences Fees'] ? 'YES' : 'NO' }}</span></p>
				</td>
			</tr>
			<tr style="height:59pt">
				<td style="width:78pt;border-top-style:solid;border-top-width:1pt;border-left-style:solid;border-left-width:1pt;border-bottom-style:solid;border-bottom-width:1pt;border-right-style:solid;border-right-width:1pt">
					<p class="s4" style="padding: 5pt 21pt;text-indent: 0pt;line-height: 9pt;text-align: left;"><span class="s5 hand-written">{{ otherCharges['Proceeds of any subsequent resale, disposal or use accruing to the seller'] ? 'YES' : 'NO' }}</span></p>
				</td>
			</tr>
			<tr style="height:119pt">
				<td style="width:548pt;border-top-style:solid;border-top-width:1pt;border-left-style:solid;border-left-width:1pt;border-bottom-style:solid;border-bottom-width:1pt;border-right-style:solid;border-right-width:1pt" colspan="2">
					<p class="s3" style="padding-left: 1pt;text-indent: 0pt;line-height: 9pt;text-align: left;">10.  I, the undersigned, declare that all particulars given in this document are true and complete.</p>
					<p style="text-indent: 0pt;text-align: left;"><br/></p>
					<p class="s3" style="padding-left: 23pt;text-indent: 0pt;text-align: left;">
						<div style="display: flex; flex-direction: row; width: 100%;">
							<div style="padding: 7px 0px; width: 45%;">Place: <span class="hand-written"> {{ FORM.agreement_place  }}</span></div>
							<div style="padding: 7px 0px; width: 45%">Date: <span class="hand-written"> {{ FORM.agreement_date }}</span></div>
						</div>
					</p>
					<p style="text-indent: 0pt;text-align: left;"><br/></p>
					<p class="s3" style="padding-top: 5pt;padding-left: 23pt;text-indent: 0pt;text-align: left;">Signature:</p>
				</td>
			</tr>
	</table>
	<p style="text-indent: 0pt;text-align: left;"><br/></p>
	<h1 style="text-indent: 0pt;text-align: right;">[FORM CONTINUED OVERLEAF]</h1>
	<table style="border-collapse:collapse;margin-left:6.40999pt" cellspacing="0">
			<tr style="height:36pt">
				<td style="width:548pt;border-top-style:solid;border-top-width:1pt;border-left-style:solid;border-left-width:1pt;border-bottom-style:solid;border-bottom-width:1pt;border-right-style:solid;border-right-width:1pt" colspan="4">
						<p class="s3" style="padding-left: 1pt;text-indent: 0pt;text-align: left;">FOR OFFICIAL USE</p>
				</td>
			</tr>
			<tr style="height:11pt">
				<td style="width:88pt;border-top-style:solid;border-top-width:1pt;border-left-style:solid;border-left-width:1pt;border-right-style:solid;border-right-width:1pt">
						<p class="s3" style="padding-left: 1pt;text-indent: 0pt;line-height: 9pt;text-align: left;">A. Basis of</p>
				</td>
				<td style="width:14pt;border-top-style:solid;border-top-width:1pt;border-left-style:solid;border-left-width:1pt">
						<p class="s3" style="text-indent: 0pt;line-height: 9pt;text-align: center;">11.</p>
				</td>
				<td style="width:354pt;border-top-style:solid;border-top-width:1pt;border-right-style:solid;border-right-width:1pt">
						<p class="s3" style="padding-left: 1pt;text-indent: 0pt;line-height: 9pt;text-align: left;">(a) Net prices on the INVOICES</p>
				</td>
				<td style="width:92pt;border-top-style:solid;border-top-width:1pt;border-left-style:solid;border-left-width:1pt;border-bottom-style:solid;border-bottom-width:1pt;border-right-style:solid;border-right-width:1pt" rowspan="2">
						<p class="s6" style="text-indent: 0pt;text-align: left;"><span class="s5 hand-written">{{ showAmountInCurreny(totalItemCost) }}</span> </p>
				</td>
			</tr>
			<tr style="height:25pt">
				<td style="width:88pt;border-left-style:solid;border-left-width:1pt;border-right-style:solid;border-right-width:1pt">
						<p class="s3" style="padding-left: 1pt;text-indent: 0pt;text-align: left;">calculation</p>
				</td>
				<td style="width:14pt;border-left-style:solid;border-left-width:1pt">
						<p style="text-indent: 0pt;text-align: left;"><br/></p>
				</td>
				<td style="width:354pt;border-right-style:solid;border-right-width:1pt">
						<p class="s3" style="padding-left: 1pt;text-indent: 0pt;text-align: left;">(Prices actually paid or payable for settlement)</p>
				</td>
			</tr>
			<tr style="height:37pt">
				<td style="width:88pt;border-left-style:solid;border-left-width:1pt;border-right-style:solid;border-right-width:1pt">
						<p style="text-indent: 0pt;text-align: left;"><br/></p>
				</td>
				<td style="width:14pt;border-left-style:solid;border-left-width:1pt;border-bottom-style:solid;border-bottom-width:1pt">
						<p style="text-indent: 0pt;text-align: left;"><br/></p>
				</td>
				<td style="width:354pt;border-bottom-style:solid;border-bottom-width:1pt;border-right-style:solid;border-right-width:1pt">
						<p class="s3" style="padding-left: 1pt;text-indent: 0pt;line-height: 9pt;text-align: left;">(b) Indirect payments – see Box 8 (b)</p>
				</td>
				<td style="width:92pt;border-top-style:solid;border-top-width:1pt;border-left-style:solid;border-left-width:1pt;border-bottom-style:solid;border-bottom-width:1pt;border-right-style:solid;border-right-width:1pt">
						<p class="s6" style="text-indent: 0pt;text-align: left;"><span class="s5 hand-written">{{ showAmountInCurreny(otherCharges['Indirect Payments'] ? otherCharges['Indirect Payments'].amount : 0) }}</span></p>
				</td>
			</tr>
			<tr style="height:34pt">
				<td style="width:88pt;border-left-style:solid;border-left-width:1pt;border-bottom-style:solid;border-bottom-width:1pt;border-right-style:solid;border-right-width:1pt">
						<p style="text-indent: 0pt;text-align: left;"><br/></p>
				</td>
				<td style="width:14pt;border-top-style:solid;border-top-width:1pt;border-left-style:solid;border-left-width:1pt;border-bottom-style:solid;border-bottom-width:1pt">
						<p class="s3" style="text-indent: 0pt;line-height: 9pt;text-align: center;"></p>
				</td>
				<td style="width:354pt;border-top-style:solid;border-top-width:1pt;border-bottom-style:solid;border-bottom-width:1pt;border-right-style:solid;border-right-width:1pt">
						<p class="s3" style="padding-left: 1pt;text-indent: 0pt;line-height: 9pt;text-align: left;">TOTAL A (in currency of invoice)</p>
				</td>
				<td style="width:92pt;border-top-style:solid;border-top-width:1pt;border-left-style:solid;border-left-width:1pt;border-bottom-style:solid;border-bottom-width:1pt;border-right-style:solid;border-right-width:1pt">
						<p class="s6" style="text-indent: 0pt;text-align: left;">
							<strong class="hand-written" style="font-size: 12px">
								{{ showAmountInCurreny(TOTAL.A = parseFloat(otherCharges['Indirect Payments'] ? otherCharges['Indirect Payments'].amount : 0) + totalItemCost) }}
							</strong>
						</p>
				</td>
			</tr>
			<tr style="height:10pt">
				<td style="width:88pt;border-top-style:solid;border-top-width:1pt;border-left-style:solid;border-left-width:1pt;border-right-style:solid;border-right-width:1pt">
						<p class="s3" style="padding-left: 1pt;text-indent: 0pt;line-height: 9pt;text-align: left;">B. Adjustments to</p>
				</td>
				<td style="width:14pt;border-top-style:solid;border-top-width:1pt;border-left-style:solid;border-left-width:1pt">
						<p class="s3" style="text-indent: 0pt;line-height: 9pt;text-align: center;">13.</p>
				</td>
				<td style="width:354pt;border-top-style:solid;border-top-width:1pt;border-right-style:solid;border-right-width:1pt">
						<p class="s3" style="padding-left: 1pt;text-indent: 0pt;line-height: 9pt;text-align: left;">Cost incurred by the buyer:</p>
				</td>
				<td style="width:92pt;border-top-style:solid;border-top-width:1pt;border-left-style:solid;border-left-width:1pt;border-bottom-style:solid;border-bottom-width:1pt;border-right-style:solid;border-right-width:1pt" rowspan="3">
					<p class="s6" style="padding-top: 1pt;text-indent: 0pt;text-align: left;">
						<span class="s5 hand-written">{{ showAmountInCurreny(parseFloat(otherCharges['Commissions incurred when Buying'] ? otherCharges['Commissions incurred when Buying'].amount : 0) + parseFloat(otherCharges['Commissions incurred when Selling'] ? otherCharges['Commissions incurred when Selling'].amount : 0)) }}</span>
					</p>
				</td>
			</tr>
			<tr style="height:10pt">
				<td style="width:88pt;border-left-style:solid;border-left-width:1pt;border-right-style:solid;border-right-width:1pt">
						<p class="s3" style="padding-left: 1pt;text-indent: 0pt;line-height: 9pt;text-align: left;">the price paid or</p>
				</td>
				<td style="width:14pt;border-left-style:solid;border-left-width:1pt">
						<p style="text-indent: 0pt;text-align: left;"><br/></p>
				</td>
				<td style="width:354pt;border-right-style:solid;border-right-width:1pt">
						<p class="s3" style="padding-left: 1pt;text-indent: 0pt;line-height: 9pt;text-align: left;">(a) Commissions (Enter “X” as applicable Selling [ ] and /or buying [ ]</p>
				</td>
			</tr>
			<tr style="height:11pt">
				<td style="width:88pt;border-left-style:solid;border-left-width:1pt;border-right-style:solid;border-right-width:1pt">
						<p class="s3" style="padding-left: 1pt;text-indent: 0pt;line-height: 9pt;text-align: left;">payable (4th</p>
				</td>
				<td style="width:14pt;border-left-style:solid;border-left-width:1pt">
						<p style="text-indent: 0pt;text-align: left;"><br/></p>
				</td>
				<td style="width:354pt;border-right-style:solid;border-right-width:1pt">
						<p style="text-indent: 0pt;text-align: left;"><br/></p>
				</td>
			</tr>
			<tr style="height:10pt">
				<td style="width:88pt;border-left-style:solid;border-left-width:1pt;border-right-style:solid;border-right-width:1pt">
						<p class="s3" style="padding-left: 1pt;text-indent: 0pt;line-height: 9pt;text-align: left;">schedule of the</p>
				</td>
				<td style="width:14pt;border-left-style:solid;border-left-width:1pt">
						<p style="text-indent: 0pt;text-align: left;"><br/></p>
				</td>
				<td style="width:354pt;border-right-style:solid;border-right-width:1pt">
						<p class="s3" style="padding-left: 1pt;text-indent: 0pt;line-height: 9pt;text-align: left;">(b) Brokerage</p>
				</td>
				<td style="width:92pt;border-top-style:solid;border-top-width:1pt;border-left-style:solid;border-left-width:1pt;border-bottom-style:solid;border-bottom-width:1pt;border-right-style:solid;border-right-width:1pt" rowspan="2">
						<p class="s6" style="padding-top: 1pt;text-indent: 0pt;text-align: left;"><span class="s5 hand-written">{{ showAmountInCurreny(parseFloat(otherCharges['Brokerage fee incurred'] ? otherCharges['Brokerage fee incurred'].amount : 0)) }}</span></p>
				</td>
			</tr>
			<tr style="height:21pt">
				<td style="width:88pt;border-left-style:solid;border-left-width:1pt;border-right-style:solid;border-right-width:1pt">
						<p class="s3" style="padding-left: 1pt;text-indent: 0pt;text-align: left;">act)</p>
				</td>
				<td style="width:14pt;border-left-style:solid;border-left-width:1pt">
						<p style="text-indent: 0pt;text-align: left;"><br/></p>
				</td>
				<td style="width:354pt;border-right-style:solid;border-right-width:1pt">
						<p style="text-indent: 0pt;text-align: left;"><br/></p>
				</td>
			</tr>
			<tr style="height:21pt">
				<td style="width:88pt;border-left-style:solid;border-left-width:1pt;border-right-style:solid;border-right-width:1pt">
						<p style="text-indent: 0pt;text-align: left;"><br/></p>
				</td>
				<td style="width:14pt;border-left-style:solid;border-left-width:1pt;border-bottom-style:solid;border-bottom-width:1pt">
						<p style="text-indent: 0pt;text-align: left;"><br/></p>
				</td>
				<td style="width:354pt;border-bottom-style:solid;border-bottom-width:1pt;border-right-style:solid;border-right-width:1pt">
						<p class="s3" style="padding-left: 1pt;text-indent: 0pt;text-align: left;">(c) Costs for containers and packing</p>
				</td>
				<td style="width:92pt;border-top-style:solid;border-top-width:1pt;border-left-style:solid;border-left-width:1pt;border-bottom-style:solid;border-bottom-width:1pt;border-right-style:solid;border-right-width:1pt">
						<p class="s6" style="padding-top: 1pt;text-indent: 0pt;text-align: left;">
							<span class="s5 hand-written">{{ showAmountInCurreny(parseFloat(otherCharges['Costs for containers and packing'] ? otherCharges['Costs for containers and packing'].amount : 0)) }}</span>
						</p>
				</td>
			</tr>
			<tr style="height:10pt">
				<td style="width:88pt;border-left-style:solid;border-left-width:1pt;border-right-style:solid;border-right-width:1pt">
						<p style="text-indent: 0pt;text-align: left;"><br/></p>
				</td>
				<td style="width:14pt;border-top-style:solid;border-top-width:1pt;border-left-style:solid;border-left-width:1pt">
						<p class="s3" style="text-indent: 0pt;line-height: 9pt;text-align: center;">14.</p>
				</td>
				<td style="width:354pt;border-top-style:solid;border-top-width:1pt;border-right-style:solid;border-right-width:1pt">
						<p class="s3" style="padding-left: 1pt;text-indent: 0pt;line-height: 9pt;text-align: left;">Goods and services supplied by the buyer free of charge or at reduced costs for use in</p>
				</td>
				<td style="width:92pt;border-top-style:solid;border-top-width:1pt;border-left-style:solid;border-left-width:1pt;border-bottom-style:solid;border-bottom-width:1pt;border-right-style:solid;border-right-width:1pt" rowspan="4">
					<p class="s6" style="padding-top: 1pt;text-indent: 0pt;text-align: left;">
						<span class="s5 hand-written">
							{{ showAmountInCurreny(parseFloat(otherCharges['Materials, Components, Parts and similar items incorporated in the imported goods'] ? otherCharges['Materials, Components, Parts and similar items incorporated in the imported goods'].amount : 0)) }}
						</span>
					</p>
				</td>
			</tr>
			<tr style="height:10pt">
				<td style="width:88pt;border-left-style:solid;border-left-width:1pt;border-right-style:solid;border-right-width:1pt">
						<p style="text-indent: 0pt;text-align: left;"><br/></p>
				</td>
				<td style="width:14pt;border-left-style:solid;border-left-width:1pt">
						<p style="text-indent: 0pt;text-align: left;"><br/></p>
				</td>
				<td style="width:354pt;border-right-style:solid;border-right-width:1pt">
						<p class="s3" style="padding-left: 1pt;text-indent: 0pt;line-height: 9pt;text-align: left;">connection with the production and sale for export of the imported goods:</p>
				</td>
			</tr>
			<tr style="height:10pt">
				<td style="width:88pt;border-left-style:solid;border-left-width:1pt;border-right-style:solid;border-right-width:1pt">
						<p style="text-indent: 0pt;text-align: left;"><br/></p>
				</td>
				<td style="width:14pt;border-left-style:solid;border-left-width:1pt">
						<p style="text-indent: 0pt;text-align: left;"><br/></p>
				</td>
				<td style="width:354pt;border-right-style:solid;border-right-width:1pt">
						<p class="s3" style="padding-left: 1pt;text-indent: 0pt;line-height: 9pt;text-align: left;">(N.B: the values shown represent an apportionment where appropriate)</p>
				</td>
			</tr>
			<tr style="height:21pt">
				<td style="width:88pt;border-left-style:solid;border-left-width:1pt;border-right-style:solid;border-right-width:1pt">
						<p style="text-indent: 0pt;text-align: left;"><br/></p>
				</td>
				<td style="width:14pt;border-left-style:solid;border-left-width:1pt">
						<p style="text-indent: 0pt;text-align: left;"><br/></p>
				</td>
				<td style="width:354pt;border-right-style:solid;border-right-width:1pt">
						<p class="s3" style="padding-left: 1pt;text-indent: 0pt;text-align: left;">(a) Materials, Components, Parts and similar items incorporated in the imported goods</p>
				</td>
			</tr>
			<tr style="height:32pt">
				<td style="width:88pt;border-left-style:solid;border-left-width:1pt;border-right-style:solid;border-right-width:1pt">
						<p style="text-indent: 0pt;text-align: left;"><br/></p>
				</td>
				<td style="width:14pt;border-left-style:solid;border-left-width:1pt">
						<p style="text-indent: 0pt;text-align: left;"><br/></p>
				</td>
				<td style="width:354pt;border-right-style:solid;border-right-width:1pt">
						<p class="s3" style="padding-left: 1pt;text-indent: 0pt;text-align: left;">(b) Tools, Dies, Moulds and similar items used in the production of the imported goods</p>
				</td>
				<td style="width:92pt;border-top-style:solid;border-top-width:1pt;border-left-style:solid;border-left-width:1pt;border-bottom-style:solid;border-bottom-width:1pt;border-right-style:solid;border-right-width:1pt">
					<p class="s6" style="padding-top: 1pt;text-indent: 0pt;text-align: left;">
						<span class="s5 hand-written">
							{{ showAmountInCurreny(parseFloat(otherCharges['Tools, Dies, Moulds and similar items used in the production of the imported goods'] ? otherCharges['Tools, Dies, Moulds and similar items used in the production of the imported goods'].amount : 0)) }}
						</span>
					</p>
				</td>
			</tr>
			<tr style="height:32pt">
				<td style="width:88pt;border-left-style:solid;border-left-width:1pt;border-right-style:solid;border-right-width:1pt">
						<p style="text-indent: 0pt;text-align: left;"><br/></p>
				</td>
				<td style="width:14pt;border-left-style:solid;border-left-width:1pt">
						<p style="text-indent: 0pt;text-align: left;"><br/></p>
				</td>
				<td style="width:354pt;border-right-style:solid;border-right-width:1pt">
						<p class="s3" style="padding-left: 1pt;text-indent: 0pt;text-align: left;">(c) Materials consumed in the production of the imported goods.</p>
				</td>
				<td style="width:92pt;border-top-style:solid;border-top-width:1pt;border-left-style:solid;border-left-width:1pt;border-bottom-style:solid;border-bottom-width:1pt;border-right-style:solid;border-right-width:1pt">
					<p class="s6" style="padding-top: 1pt;text-indent: 0pt;text-align: left;">
						<span class="s5 hand-written">
							{{ showAmountInCurreny(parseFloat(otherCharges['Materials consumed in the production of the imported goods'] ? otherCharges['Materials consumed in the production of the imported goods'].amount : 0)) }}
						</span>
					</p>
				</td>
			</tr>
			<tr style="height:10pt">
				<td style="width:88pt;border-left-style:solid;border-left-width:1pt;border-right-style:solid;border-right-width:1pt">
						<p style="text-indent: 0pt;text-align: left;"><br/></p>
				</td>
				<td style="width:14pt;border-left-style:solid;border-left-width:1pt">
						<p style="text-indent: 0pt;text-align: left;"><br/></p>
				</td>
				<td style="width:354pt;border-right-style:solid;border-right-width:1pt">
						<p class="s3" style="padding-left: 1pt;text-indent: 0pt;line-height: 9pt;text-align: left;">(d) Engineering, development, art work, design work, plans and sketches</p>
				</td>
				<td style="width:92pt;border-top-style:solid;border-top-width:1pt;border-left-style:solid;border-left-width:1pt;border-bottom-style:solid;border-bottom-width:1pt;border-right-style:solid;border-right-width:1pt" rowspan="3">
						<p class="s6" style="padding-top: 1pt;text-indent: 0pt;text-align: left;">
							<span class="s5 hand-written">
								{{ showAmountInCurreny(parseFloat(otherCharges['Engineering, development, art work, design work, plans and sketches undertaken elsewhere other than in the country of the import and necessary for the production of imported goods'] ? otherCharges['Engineering, development, art work, design work, plans and sketches undertaken elsewhere other than in the country of the import and necessary for the production of imported goods'].amount : 0)) }}
							</span>
						</p>
				</td>
			</tr>
			<tr style="height:10pt">
				<td style="width:88pt;border-left-style:solid;border-left-width:1pt;border-right-style:solid;border-right-width:1pt">
						<p style="text-indent: 0pt;text-align: left;"><br/></p>
				</td>
				<td style="width:14pt;border-left-style:solid;border-left-width:1pt">
						<p style="text-indent: 0pt;text-align: left;"><br/></p>
				</td>
				<td style="width:354pt;border-right-style:solid;border-right-width:1pt">
						<p class="s3" style="padding-left: 1pt;text-indent: 0pt;line-height: 9pt;text-align: left;">undertaken elsewhere other than in the country of the import and necessary for</p>
				</td>
			</tr>
			<tr style="height:11pt">
				<td style="width:88pt;border-left-style:solid;border-left-width:1pt;border-right-style:solid;border-right-width:1pt">
						<p style="text-indent: 0pt;text-align: left;"><br/></p>
				</td>
				<td style="width:14pt;border-left-style:solid;border-left-width:1pt;border-bottom-style:solid;border-bottom-width:1pt">
						<p style="text-indent: 0pt;text-align: left;"><br/></p>
				</td>
				<td style="width:354pt;border-bottom-style:solid;border-bottom-width:1pt;border-right-style:solid;border-right-width:1pt">
						<p class="s3" style="padding-left: 1pt;text-indent: 0pt;line-height: 9pt;text-align: left;">the production of imported goods</p>
				</td>
			</tr>
			<tr style="height:21pt">
				<td style="width:88pt;border-left-style:solid;border-left-width:1pt;border-right-style:solid;border-right-width:1pt">
						<p style="text-indent: 0pt;text-align: left;"><br/></p>
				</td>
				<td style="width:14pt;border-top-style:solid;border-top-width:1pt;border-left-style:solid;border-left-width:1pt;border-bottom-style:solid;border-bottom-width:1pt">
						<p class="s3" style="text-indent: 0pt;text-align: center;">15.</p>
				</td>
				<td style="width:354pt;border-top-style:solid;border-top-width:1pt;border-bottom-style:solid;border-bottom-width:1pt;border-right-style:solid;border-right-width:1pt">
						<p class="s3" style="padding-left: 1pt;text-indent: 0pt;text-align: left;">Royalties and licences fees – see Box 9(a)</p>
				</td>
				<td style="width:92pt;border-top-style:solid;border-top-width:1pt;border-left-style:solid;border-left-width:1pt;border-bottom-style:solid;border-bottom-width:1pt;border-right-style:solid;border-right-width:1pt">
					<p class="s6" style="padding-top: 1pt;text-indent: 0pt;text-align: left;">
						<span class="s5 hand-written">
							{{ showAmountInCurreny(parseFloat(otherCharges['Royalties and Licences Fees'] ? otherCharges['Royalties and Licences Fees'].amount : 0)) }}
						</span>
					</p>
				</td>
			</tr>
			<tr style="height:21pt">
				<td style="width:88pt;border-left-style:solid;border-left-width:1pt;border-right-style:solid;border-right-width:1pt">
						<p style="text-indent: 0pt;text-align: left;"><br/></p>
				</td>
				<td style="width:14pt;border-top-style:solid;border-top-width:1pt;border-left-style:solid;border-left-width:1pt;border-bottom-style:solid;border-bottom-width:1pt">
						<p class="s3" style="text-indent: 0pt;text-align: center;">16.</p>
				</td>
				<td style="width:354pt;border-top-style:solid;border-top-width:1pt;border-bottom-style:solid;border-bottom-width:1pt;border-right-style:solid;border-right-width:1pt">
						<p class="s3" style="padding-left: 1pt;text-indent: 0pt;text-align: left;">Proceeds of any subsequent resale, disposal or use accruing to the seller – see box 9(b)</p>
				</td>
				<td style="width:92pt;border-top-style:solid;border-top-width:1pt;border-left-style:solid;border-left-width:1pt;border-bottom-style:solid;border-bottom-width:1pt;border-right-style:solid;border-right-width:1pt">
						<p class="s6" style="padding-top: 1pt;text-indent: 0pt;text-align: left;">
							<span class="s5 hand-written">
								{{ showAmountInCurreny(parseFloat(otherCharges['Proceeds of any subsequent resale, disposal or use accruing to the seller'] ? otherCharges['Proceeds of any subsequent resale, disposal or use accruing to the seller'].amount : 0)) }}
							</span>
						</p>
				</td>
			</tr>
			<tr style="height:10pt">
				<td style="width:88pt;border-left-style:solid;border-left-width:1pt;border-right-style:solid;border-right-width:1pt">
						<p style="text-indent: 0pt;text-align: left;"><br/></p>
				</td>
				<td style="width:14pt;border-top-style:solid;border-top-width:1pt;border-left-style:solid;border-left-width:1pt">
						<p class="s3" style="text-indent: 0pt;line-height: 9pt;text-align: center;">17.</p>
				</td>
				<td style="width:354pt;border-top-style:solid;border-top-width:1pt;border-right-style:solid;border-right-width:1pt">
						<p class="s3" style="padding-left: 1pt;text-indent: 0pt;line-height: 9pt;text-align: left;">Costs of delivery to the Community</p>
				</td>
				<td style="width:92pt;border-top-style:solid;border-top-width:1pt;border-left-style:solid;border-left-width:1pt;border-bottom-style:solid;border-bottom-width:1pt;border-right-style:solid;border-right-width:1pt" rowspan="2">
						<p class="s6" style="padding-top: 1pt;text-indent: 0pt;text-align: left;">
							<span class="s5 hand-written">
								{{ showAmountInCurreny(parseFloat(otherCharges['Transport/Freight'] ? otherCharges['Transport/Freight'].amount : 0)) }}
							</span>
						</p>
				</td>
			</tr>
			<tr style="height:21pt">
				<td style="width:88pt;border-left-style:solid;border-left-width:1pt;border-right-style:solid;border-right-width:1pt">
						<p style="text-indent: 0pt;text-align: left;"><br/></p>
				</td>
				<td style="width:14pt;border-left-style:solid;border-left-width:1pt">
						<p style="text-indent: 0pt;text-align: left;"><br/></p>
				</td>
				<td style="width:354pt;border-right-style:solid;border-right-width:1pt">
						<p class="s3" style="padding-left: 1pt;text-indent: 0pt;text-align: left;">(a) Transport/Freight</p>
				</td>
			</tr>
			<tr style="height:32pt">
				<td style="width:88pt;border-left-style:solid;border-left-width:1pt;border-right-style:solid;border-right-width:1pt">
						<p style="text-indent: 0pt;text-align: left;"><br/></p>
				</td>
				<td style="width:14pt;border-left-style:solid;border-left-width:1pt">
						<p style="text-indent: 0pt;text-align: left;"><br/></p>
				</td>
				<td style="width:354pt;border-right-style:solid;border-right-width:1pt">
						<p class="s3" style="padding-left: 1pt;text-indent: 0pt;text-align: left;">(b) Loading and handling charges</p>
				</td>
				<td style="width:92pt;border-top-style:solid;border-top-width:1pt;border-left-style:solid;border-left-width:1pt;border-bottom-style:solid;border-bottom-width:1pt;border-right-style:solid;border-right-width:1pt">
						<p class="s6" style="padding-top: 1pt;text-indent: 0pt;text-align: left;">
							<span class="s5 hand-written">
								{{ showAmountInCurreny(parseFloat(otherCharges['Loading and handling charges'] ? otherCharges['Loading and handling charges'].amount : 0)) }}
							</span>
						</p>
				</td>
			</tr>
			<tr style="height:32pt">
				<td style="width:88pt;border-left-style:solid;border-left-width:1pt;border-bottom-style:solid;border-bottom-width:1pt;border-right-style:solid;border-right-width:1pt">
						<p style="text-indent: 0pt;text-align: left;"><br/></p>
				</td>
				<td style="width:14pt;border-left-style:solid;border-left-width:1pt;border-bottom-style:solid;border-bottom-width:1pt">
						<p style="text-indent: 0pt;text-align: left;"><br/></p>
				</td>
				<td style="width:354pt;border-bottom-style:solid;border-bottom-width:1pt;border-right-style:solid;border-right-width:1pt">
						<p class="s3" style="padding-left: 1pt;text-indent: 0pt;text-align: left;">(c) Insurance</p>
				</td>
				<td style="width:92pt;border-top-style:solid;border-top-width:1pt;border-left-style:solid;border-left-width:1pt;border-bottom-style:solid;border-bottom-width:1pt;border-right-style:solid;border-right-width:1pt">
						<p class="s6" style="padding-top: 1pt;text-indent: 0pt;text-align: left;">
							<span class="s5 hand-written">
								{{ showAmountInCurreny(parseFloat(otherCharges['Insurance'] ? otherCharges['Insurance'].amount : 0)) }}
							</span>
						</p>
				</td>
			</tr>
			<tr style="height:10pt">
				<td style="width:88pt;border-top-style:solid;border-top-width:1pt;border-left-style:solid;border-left-width:1pt;border-right-style:solid;border-right-width:1pt">
						<p class="s3" style="padding-left: 1pt;text-indent: 0pt;line-height: 9pt;text-align: left;">C. Post landing</p>
				</td>
				<td style="width:14pt;border-top-style:solid;border-top-width:1pt;border-left-style:solid;border-left-width:1pt">
						<p class="s3" style="text-indent: 0pt;line-height: 9pt;text-align: center;">18.</p>
				</td>
				<td style="width:354pt;border-top-style:solid;border-top-width:1pt;border-right-style:solid;border-right-width:1pt">
						<p class="s3" style="padding-left: 1pt;text-indent: 0pt;line-height: 9pt;text-align: left;">TOTAL of “B” (in currency of invoice)</p>
				</td>
				<td style="width:92pt;border-top-style:solid;border-top-width:1pt;border-left-style:solid;border-left-width:1pt;border-bottom-style:solid;border-bottom-width:1pt;border-right-style:solid;border-right-width:1pt" rowspan="2">
						<p class="s6" style="padding-top: 1pt;text-indent: 0pt;text-align: left;">
							<strong style="font-size: 12px;" class="hand-written">
								{{
									showAmountInCurreny(TOTAL.B = 
										totalTheAmounts([
											otherCharges['Commissions incurred when Buying'] ? otherCharges['Commissions incurred when Buying'].amount : 0,
											otherCharges['Commissions incurred when Selling'] ? otherCharges['Commissions incurred when Selling'].amount : 0,
											otherCharges['Brokerage fee incurred'] ? otherCharges['Brokerage fee incurred'].amount : 0,
											otherCharges['Materials, Components, Parts and similar items incorporated in the imported goods'] ? otherCharges['Materials, Components, Parts and similar items incorporated in the imported goods'].amount : 0,
											otherCharges['Tools, Dies, Moulds and similar items used in the production of the imported goods'] ? otherCharges['Tools, Dies, Moulds and similar items used in the production of the imported goods'].amount : 0,
											otherCharges['Materials consumed in the production of the imported goods'] ? otherCharges['Materials consumed in the production of the imported goods'].amount : 0,
											otherCharges['Engineering, development, art work, design work, plans and sketches undertaken elsewhere other than in the country of the import and necessary for the production of imported goods'] ? otherCharges['Engineering, development, art work, design work, plans and sketches undertaken elsewhere other than in the country of the import and necessary for the production of imported goods'].amount : 0,
											otherCharges['Royalties and Licences Fees'] ? otherCharges['Royalties and Licences Fees'].amount : 0,
											otherCharges['Proceeds of any subsequent resale, disposal or use accruing to the seller'] ? otherCharges['Proceeds of any subsequent resale, disposal or use accruing to the seller'].amount : 0,
											otherCharges['Transport/Freight'] ? otherCharges['Transport/Freight'].amount : 0,
											otherCharges['Loading and handling charges'] ? otherCharges['Loading and handling charges'].amount : 0,
											otherCharges['Insurance'] ? otherCharges['Insurance'].amount : 0
										])
									)
								}}
							</strong>
						</p>
				</td>
			</tr>
			<tr style="height:11pt">
				<td style="width:88pt;border-left-style:solid;border-left-width:1pt;border-right-style:solid;border-right-width:1pt">
						<p class="s3" style="padding-left: 1pt;text-indent: 0pt;line-height: 9pt;text-align: left;">expenses (may be</p>
				</td>
				<td style="width:14pt;border-left-style:solid;border-left-width:1pt;border-bottom-style:solid;border-bottom-width:1pt">
						<p style="text-indent: 0pt;text-align: left;"><br/></p>
				</td>
				<td style="width:354pt;border-bottom-style:solid;border-bottom-width:1pt;border-right-style:solid;border-right-width:1pt">
						<p style="text-indent: 0pt;text-align: left;"><br/></p>
				</td>
			</tr>
			<tr style="height:21pt">
				<td style="width:88pt;border-left-style:solid;border-left-width:1pt;border-right-style:solid;border-right-width:1pt">
						<p class="s3" style="padding-left: 1pt;text-indent: 0pt;text-align: left;">included in &quot;A&quot;)</p>
				</td>
				<td style="width:14pt;border-top-style:solid;border-top-width:1pt;border-left-style:solid;border-left-width:1pt;border-bottom-style:solid;border-bottom-width:1pt">
						<p class="s3" style="text-indent: 0pt;text-align: center;">19.</p>
				</td>
				<td style="width:354pt;border-top-style:solid;border-top-width:1pt;border-bottom-style:solid;border-bottom-width:1pt;border-right-style:solid;border-right-width:1pt">
						<p class="s3" style="padding-left: 1pt;text-indent: 0pt;text-align: left;">Cost of transport after arrival in the Community</p>
				</td>
				<td style="width:92pt;border-top-style:solid;border-top-width:1pt;border-left-style:solid;border-left-width:1pt;border-bottom-style:solid;border-bottom-width:1pt;border-right-style:solid;border-right-width:1pt">
						<p class="s6" style="padding-top: 1pt;text-indent: 0pt;text-align: left;">
							<span class="s5 hand-written">
								{{ showAmountInCurreny(parseFloat(otherCharges['Cost of transport after arrival in the Community'] ? otherCharges['Cost of transport after arrival in the Community'].amount : 0)) }}
							</span>
						</p>
				</td>
			</tr>
			<tr style="height:10pt">
				<td style="width:88pt;border-left-style:solid;border-left-width:1pt;border-right-style:solid;border-right-width:1pt">
						<p style="text-indent: 0pt;text-align: left;"><br/></p>
				</td>
				<td style="width:14pt;border-top-style:solid;border-top-width:1pt;border-left-style:solid;border-left-width:1pt">
						<p class="s3" style="text-indent: 0pt;line-height: 9pt;text-align: center;">20.</p>
				</td>
				<td style="width:354pt;border-top-style:solid;border-top-width:1pt;border-right-style:solid;border-right-width:1pt">
						<p class="s3" style="padding-left: 1pt;text-indent: 0pt;line-height: 9pt;text-align: left;">Charges of Construction, Maintenance and other related expenses after importation</p>
				</td>
				<td style="width:92pt;border-top-style:solid;border-top-width:1pt;border-left-style:solid;border-left-width:1pt;border-bottom-style:solid;border-bottom-width:1pt;border-right-style:solid;border-right-width:1pt" rowspan="2">
						<p class="s6" style="padding-top: 1pt;text-indent: 0pt;text-align: left;">
							<span class="s5 hand-written">
								{{ showAmountInCurreny(parseFloat(otherCharges['Charges of Construction, Maintenance and other related expenses after importation except installation costs'] ? otherCharges['Charges of Construction, Maintenance and other related expenses after importation except installation costs'].amount : 0)) }}
							</span>
						</p>
				</td>
			</tr>
			<tr style="height:21pt">
				<td style="width:88pt;border-left-style:solid;border-left-width:1pt;border-right-style:solid;border-right-width:1pt">
						<p style="text-indent: 0pt;text-align: left;"><br/></p>
				</td>
				<td style="width:14pt;border-left-style:solid;border-left-width:1pt;border-bottom-style:solid;border-bottom-width:1pt">
						<p style="text-indent: 0pt;text-align: left;"><br/></p>
				</td>
				<td style="width:354pt;border-bottom-style:solid;border-bottom-width:1pt;border-right-style:solid;border-right-width:1pt">
						<p class="s3" style="padding-left: 1pt;text-indent: 0pt;text-align: left;">except installation costs</p>
				</td>
			</tr>
			<tr style="height:43pt">
				<td style="width:88pt;border-left-style:solid;border-left-width:1pt;border-right-style:solid;border-right-width:1pt">
						<p style="text-indent: 0pt;text-align: left;"><br/></p>
				</td>
				<td style="width:14pt;border-top-style:solid;border-top-width:1pt;border-left-style:solid;border-left-width:1pt;border-bottom-style:solid;border-bottom-width:1pt">
						<p class="s3" style="text-indent: 0pt;text-align: center;">21.</p>
				</td>
				<td style="width:354pt;border-top-style:solid;border-top-width:1pt;border-bottom-style:solid;border-bottom-width:1pt;border-right-style:solid;border-right-width:1pt">
						<p class="s3" style="padding-left: 1pt;text-indent: 0pt;text-align: left;">Other charges (specify)</p>
						<p style="text-align: left;"><span class="s5 hand-written">{{ otherCharges['Other'] ? otherCharges['Other'].description : ''  }}</span></p>
				</td>
				<td style="width:92pt;border-top-style:solid;border-top-width:1pt;border-left-style:solid;border-left-width:1pt;border-bottom-style:solid;border-bottom-width:1pt;border-right-style:solid;border-right-width:1pt">
						<p class="s6" style="padding-top: 1pt;text-indent: 0pt;text-align: left;">
							<span class="s5 hand-written">
								{{ showAmountInCurreny(parseFloat(otherCharges['Other'] ? otherCharges['Other'].amount : 0)) }}
							</span>
						</p>
				</td>
			</tr>
			<tr style="height:32pt">
				<td style="width:88pt;border-left-style:solid;border-left-width:1pt;border-right-style:solid;border-right-width:1pt">
						<p style="text-indent: 0pt;text-align: left;"><br/></p>
				</td>
				<td style="width:14pt;border-top-style:solid;border-top-width:1pt;border-left-style:solid;border-left-width:1pt;border-bottom-style:solid;border-bottom-width:1pt">
						<p class="s3" style="text-indent: 0pt;text-align: center;">22.</p>
				</td>
				<td style="width:354pt;border-top-style:solid;border-top-width:1pt;border-bottom-style:solid;border-bottom-width:1pt;border-right-style:solid;border-right-width:1pt">
						<p class="s3" style="padding-left: 1pt;text-indent: 0pt;text-align: left;">Customs Duty and Taxes in the Community</p>
				</td>
				<td style="width:92pt;border-top-style:solid;border-top-width:1pt;border-left-style:solid;border-left-width:1pt;border-bottom-style:solid;border-bottom-width:1pt;border-right-style:solid;border-right-width:1pt">
						<p class="s6" style="padding-top: 1pt;text-indent: 0pt;text-align: left;">
							<span class="s5 hand-written">
								{{ showAmountInCurreny(parseFloat(otherCharges['Customs Duty and Taxes in the Community'] ? otherCharges['Customs Duty and Taxes in the Community'].amount : 0)) }}
							</span>
						</p>
				</td>
			</tr>
			<tr style="height:32pt">
				<td style="width:88pt;border-left-style:solid;border-left-width:1pt;border-bottom-style:solid;border-bottom-width:1pt;border-right-style:solid;border-right-width:1pt">
						<p style="text-indent: 0pt;text-align: left;"><br/></p>
				</td>
				<td style="width:14pt;border-top-style:solid;border-top-width:1pt;border-left-style:solid;border-left-width:1pt;border-bottom-style:solid;border-bottom-width:1pt">
						<p class="s3" style="text-indent: 0pt;text-align: center;">23.</p>
				</td>
				<td style="width:354pt;border-top-style:solid;border-top-width:1pt;border-bottom-style:solid;border-bottom-width:1pt;border-right-style:solid;border-right-width:1pt">
						<p class="s3" style="padding-left: 1pt;text-indent: 0pt;text-align: left;">TOTAL of “C” (in currency of invoice)</p>
				</td>
				<td style="width:92pt;border-top-style:solid;border-top-width:1pt;border-left-style:solid;border-left-width:1pt;border-bottom-style:solid;border-bottom-width:1pt;border-right-style:solid;border-right-width:1pt">
						<p class="s6" style="padding-top: 1pt;text-indent: 0pt;text-align: left;">
							<strong style="font-size: 12px;" class="hand-written">
								{{
									showAmountInCurreny(TOTAL.C = 
										totalTheAmounts([
											otherCharges['Customs Duty and Taxes in the Community'] ? otherCharges['Customs Duty and Taxes in the Community'].amount : 0,
											otherCharges['Other'] ? otherCharges['Other'].amount : 0,
											otherCharges['Charges of Construction, Maintenance and other related expenses after importation except installation costs'] ? otherCharges['Charges of Construction, Maintenance and other related expenses after importation except installation costs'].amount : 0,
											otherCharges['Cost of transport after arrival in the Community'] ? otherCharges['Cost of transport after arrival in the Community'].amount : 0
										])
									)
								}}
							</strong>
						</p>
				</td>
			</tr>
			<tr style="height:10pt">
				<td style="width:88pt;border-top-style:solid;border-top-width:1pt;border-left-style:solid;border-left-width:1pt;border-right-style:solid;border-right-width:1pt">
						<p class="s3" style="padding-left: 1pt;text-indent: 0pt;line-height: 9pt;text-align: left;">CUSTOMS VALUE</p>
				</td>
				<td style="width:14pt;border-top-style:solid;border-top-width:1pt;border-left-style:solid;border-left-width:1pt">
						<p class="s3" style="text-indent: 0pt;line-height: 9pt;text-align: center;">24.</p>
				</td>
				<td style="width:354pt;border-top-style:solid;border-top-width:1pt;border-right-style:solid;border-right-width:1pt">
						<p class="s3" style="padding-left: 1pt;text-indent: 0pt;line-height: 9pt;text-align: left;">Total Customs value (in currency of invoice)</p>
				</td>
				<td style="width:92pt;border-top-style:solid;border-top-width:1pt;border-left-style:solid;border-left-width:1pt;border-bottom-style:solid;border-bottom-width:1pt;border-right-style:solid;border-right-width:1pt" rowspan="3">
						<p class="s6" style="padding-top: 1pt;text-indent: 0pt;text-align: left;">
							<strong style="font-size: 13px; font-weight: 600;" class="s5 hand-written">{{ showAmountInCurreny (TOTAL.A + TOTAL.B + TOTAL.C) }}</strong>
						</p>
				</td>
			</tr>
			<tr style="height:10pt">
				<td style="width:88pt;border-left-style:solid;border-left-width:1pt;border-right-style:solid;border-right-width:1pt">
						<p class="s3" style="padding-left: 1pt;text-indent: 0pt;line-height: 9pt;text-align: left;">(A+B) or (A+B-C)</p>
				</td>
				<td style="width:14pt;border-left-style:solid;border-left-width:1pt">
						<p style="text-indent: 0pt;text-align: left;"><br/></p>
				</td>
				<td style="width:354pt;border-right-style:solid;border-right-width:1pt">
						<p style="text-indent: 0pt;text-align: left;"><br/></p>
				</td>
			</tr>
			<tr style="height:11pt">
				<td style="width:88pt;border-left-style:solid;border-left-width:1pt;border-bottom-style:solid;border-bottom-width:1pt;border-right-style:solid;border-right-width:1pt">
						<p class="s3" style="padding-left: 1pt;text-indent: 0pt;line-height: 9pt;text-align: left;">if C is included in A</p>
				</td>
				<td style="width:14pt;border-left-style:solid;border-left-width:1pt;border-bottom-style:solid;border-bottom-width:1pt">
						<p style="text-indent: 0pt;text-align: left;"><br/></p>
				</td>
				<td style="width:354pt;border-bottom-style:solid;border-bottom-width:1pt;border-right-style:solid;border-right-width:1pt">
						<p style="text-indent: 0pt;text-align: left;"><br/></p>
				</td>
			</tr>
	</table>
</template>

<script setup>
import { fetchFormData, UnitConverter } from "@/scripts/data";
import { computed, inject, onMounted, ref } from "vue";

const USER = inject("loggedInUser");
const setPrintPage = inject("setPrintPage");
const SERVER_LOCATION = inject("SERVER_LOCATION");
const SERVER = SERVER_LOCATION.value;

const TOTAL = ref({
	"A":0, "B":0, "C":0
})

const props = defineProps(['id']);
const FORM = ref({});
let totalAdditionalCharge = ref(0);
let itemTotals = ref({"weight": 0, "quantity": 0, "total_price": 0});
const getItemTotals = () => {
	let totals = {"weight": 0, "quantity": 0, "total_price": 0};
	console.log(FORM.value.items);
	for(let i in FORM.value.items){
		let item = FORM.value.items[i];
		totals.weight += UnitConverter.LBKGConverter(item.weight.value, item.weight.format, FORM.value.form_weight_format);
		totals.quantity += parseFloat(item.quantity);
		totals.total_price += parseFloat(item.total_price);
	}
	return totals;
};

const otherCharges = ref({});

const getAdditionalChargesTotal = () => {
	let additionalCharges = 0;
	for(let i in FORM.value.other_charges){
		let charge = FORM.value.other_charges[i];
		additionalCharges += parseFloat(charge.amount);
		otherCharges.value[charge.title] = charge;
	}

	return additionalCharges;
}

const showAmountInCurreny = (amnt) => {
	return FORM.value.invoice_currency+amnt.toLocaleString()
}

const totalItemCost = computed(() => {
  let total = 0;
  for (let ind in FORM.value.items ?? []) {
    let item = FORM.value.items[ind];
    total += parseFloat(item.value?.total_price ?? item.total_price ?? 0);
  }
  return total;
});

const totalTheAmounts = (arr) => {
	let total = 0;
	for(let t of arr){
		total += parseFloat(t);
	}
	return total;
}

onMounted(async ()=>{
	let data = await fetchFormData(SERVER, props.id, USER.value.token);
	FORM.value = data.form;

	itemTotals = getItemTotals();
	totalAdditionalCharge = getAdditionalChargesTotal();

	setPrintPage();
});

</script>
<style>
	html,body,#app{
		background-color: #fff;
	}
	p { color: black; font-family:Arial, sans-serif; font-style: normal; font-weight: bold; text-decoration: none; font-size: 10pt; margin:0pt; }
	.s1 { color: black; font-family:Arial, sans-serif; font-style: normal; font-weight: normal; text-decoration: none; font-size: 8pt; }
	.s2 { color: black; font-family:Arial, sans-serif; font-style: normal; font-weight: normal; text-decoration: none; font-size: 12pt; }
	.s3 { color: black; font-family:Arial, sans-serif; font-style: normal; font-weight: normal; text-decoration: none; font-size: 8pt; }
	.s4 { color: black; font-family:Arial, sans-serif; font-style: normal; font-weight: normal; text-decoration: none; font-size: 6pt; vertical-align: 1pt; }
	.s5 { color: black; font-family:Arial, sans-serif; font-style: normal; font-weight: normal; text-decoration: none; font-size: 8pt; }
	h1 { color: black; font-family:Arial, sans-serif; font-style: italic; font-weight: bold; text-decoration: none; font-size: 8pt; }
	.s6 { color: black; font-family:Arial, sans-serif; font-style: normal; font-weight: normal; text-decoration: none; font-size: 6pt; }
	li {display: block; }
	#l1 {padding-left: 0pt;counter-reset: c1 7; }
	#l1> li>*:first-child:before {counter-increment: c1; content: counter(c1, decimal)". "; color: black; font-family:Arial, sans-serif; font-style: normal; font-weight: normal; text-decoration: none; font-size: 8pt; }
	#l1> li:first-child>*:first-child:before {counter-increment: c1 0;  }
	#l2 {padding-left: 0pt;counter-reset: c2 2; }
	#l2> li>*:first-child:before {counter-increment: c2; content: "("counter(c2, lower-latin)") "; color: black; font-family:Arial, sans-serif; font-style: normal; font-weight: normal; text-decoration: none; font-size: 8pt; }
	#l2> li:first-child>*:first-child:before {counter-increment: c2 0;  }
	li {display: block; }
	#l3 {padding-left: 0pt;counter-reset: d1 8; }
	#l3> li>*:first-child:before {counter-increment: d1; content: counter(d1, decimal)". "; color: black; font-family:Arial, sans-serif; font-style: normal; font-weight: normal; text-decoration: none; font-size: 8pt; }
	#l3> li:first-child>*:first-child:before {counter-increment: d1 0;  }
	#l4 {padding-left: 0pt; }
	#l4> li>*:first-child:before {content: "- "; color: black; font-family:Arial, sans-serif; font-style: normal; font-weight: normal; text-decoration: none; font-size: 8pt; }
	table, tbody {vertical-align: top; overflow: visible; }
	h1{
		margin-top: 0 !important;
	}

.hand-written{
	padding-left: 10px;
	font-style: italic;
	font-family: 'Courier New', Courier, monospace;
}
</style>