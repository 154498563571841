<template>
  <div class="dashboard container-fluid pt-5">
    <div class="overview row">
      <div class="metric col-md-3 mb-4">
        <div class="card">
          <div class="card-body">
            <h5 class="card-title d-flex align-items-center">
              <i class="fas fa-box me-2"></i>Total Imports in KES
            </h5>
            <p class="card-text value">24,025</p>
            <p class="card-text percentage text-success">+2.55%</p>
          </div>
        </div>
      </div>
      <div class="metric col-md-3 mb-4">
        <div class="card">
          <div class="card-body">
            <h5 class="card-title d-flex align-items-center">
              <i class="fas fa-ship me-2"></i>Sea Freight
            </h5>
            <p class="card-text value">224,025</p>
            <p class="card-text percentage text-success">+2.55%</p>
          </div>
        </div>
      </div>
      <div class="metric col-md-3 mb-4">
        <div class="card">
          <div class="card-body">
            <h5 class="card-title d-flex align-items-center">
              <i class="fas fa-plane me-2"></i>Air Freight
            </h5>
            <p class="card-text value">4,025</p>
            <p class="card-text percentage text-success">+2.55%</p>
          </div>
        </div>
      </div>
      <div class="metric col-md-3 mb-4">
        <div class="card">
          <div class="card-body">
            <h5 class="card-title d-flex align-items-center">
              <i class="fas fa-truck me-2"></i>Road Transport
            </h5>
            <p class="card-text value">24,025</p>
            <p class="card-text percentage text-danger">-2.55%</p>
          </div>
        </div>
      </div>
    </div>

    <div class="charts row">
      <div class="col-md-8 mb-4">
        <div class="card">
          <div class="card-body">
            <h5 class="card-title">Cargo Tonnage</h5>
            <svg width="100%" height="300" viewBox="0 0 800 300">
              <path
                d="M10 290 L50 240 L90 180 L130 220 L170 160 L210 200 L250 280 L290 200 L330 260 L370 180 L410 220 L450 160 L490 240 L530 200 L570 280 L610 220 L650 260 L690 180 L730 240 L770 200"
                fill="none"
                stroke="#4285f4"
                stroke-width="2"
              />
              <text x="10" y="20" font-size="12" fill="#666">Tonnage</text>
              <text x="790" y="290" text-anchor="end" font-size="12" fill="#666">Year</text>
            </svg>
          </div>
        </div>
      </div>
      <div class="col-md-4 mb-4">
        <div class="card">
          <div class="card-body">
            <h5 class="card-title">Cargo Tonnage By Year</h5>
            <svg width="100%" height="200" viewBox="0 0 200 200">
              <circle
                cx="100"
                cy="100"
                r="80"
                fill="#f2f2f2"
                stroke="#d9d9d9"
                stroke-width="2"
              />
              <path
                d="M100 20 A80 80 0 0 1 189.8 99.6 L100 100 Z"
                fill="#4285f4"
              />
              <path
                d="M100 100 A80 80 0 0 1 162.9 180.4 L100 100 Z"
                fill="#ea4335"
              />
              <path
                d="M100 100 A80 80 0 0 1 37.1 119.6 L100 100 Z"
                fill="#fbbc05"
              />
              <path
                d="M100 100 A80 80 0 0 1 10.2 99.6 L100 100 Z"
                fill="#34a853"
              />
              <text
                x="100"
                y="100"
                text-anchor="middle"
                alignment-baseline="central"
                font-size="12"
                fill="#666"
              >
                203,015
              </text>
              <text x="100" y="115" text-anchor="middle" font-size="10" fill="#999"
                >Tonnage</text
              >
            </svg>
          </div>
        </div>
      </div>
    </div>
    <div class="top-countries row">
      <div class="col-md-8 mb-4">
        <div class="card">
          <div class="card-body">
            <h5 class="card-title">Import Top Countries</h5>
            <table class="table table-borderless table-sm">
              <thead>
                <tr>
                  <th>Country</th>
                  <th>Value</th>
                  <th>Unit</th>
                  <th>Quantity</th>
                  <th>Total</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>China</td>
                  <td>KES 778.35</td>
                  <td>Metric Ton</td>
                  <td>1,234</td>
                  <td>KES 960,345.90</td>
                </tr>
                <tr>
                  <td>Bangladesh</td>
                  <td>KES 239.01</td>
                  <td>Metric Ton</td>
                  <td>567</td>
                  <td>KES 135,526.67</td>
                </tr>
                <tr>
                  <td>India</td>
                  <td>KES 190.43</td>
                  <td>Metric Ton</td>
                  <td>892</td>
                  <td>KES 169,823.56</td>
                </tr>
                <tr>
                  <td>Qatar</td>
                  <td>KES 180.83</td>
                  <td>Metric Ton</td>
                  <td>345</td>
                  <td>KES 62,386.35</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div class="col-md-4 mb-4">
        <div class="card">
          <div class="card-body">
            <h5 class="card-title">Export Top Countries</h5>
            <ul class="list-group list-group-flush small">
              <li
                class="list-group-item d-flex justify-content-between align-items-center"
              >
                <div class="country">Egypt</div>
                <div class="value">1,446</div>
                <div class="total">KES 213,573</div>
              </li>
              <li
                class="list-group-item d-flex justify-content-between align-items-center"
              >
                <div class="country">Saudi Arabia</div>
                <div class="value">3,133</div>
                <div class="total">KES 518,867.5</div>
              </li>
              <li
                class="list-group-item d-flex justify-content-between align-items-center"
              >
                <div class="country">Holland</div>
                <div class="value">3,182</div>
                <div class="total">KES 516,786.4</div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
</script>