<template>
  <div class="col-sm min-vh-100 content-page">
    <div class="content-header d-flex">
      <div class="title pill bg-red">{{ pageTitle }}</div>
      <div class="description p-1">{{ pageSub }}</div>
    </div>
    <slot class="p-3"></slot>
  </div>
</template>

<script setup>
import { computed, inject, onMounted } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useStore } from "vuex";
const pageTitle = inject("pageTitle");
const pageSub = inject("pageSub");
const route = useRoute();
const router = useRouter();
const store = useStore();

const USER = computed(()=>store.state.user);

if (route.meta.authenticated && USER == null) {
  router.push({ name: "login" });
}

if (route.meta.authenticated && USER) {
  router.push({ name: "dashboard" });
}

if(route.name == "profile"){
  pageSub.value = USER && USER?.name ? USER?.name + "'s Profile Details" : "User Profile"
}

</script>

<style>
.content-page {
  color: #343434;
}

.content-header {
  padding: 10px;
  height: 50px;
  border-bottom: 1px solid #ddd;
  background-color: #fff;
}

.content-header .title {
  font-weight: 500;
  padding: 3px 15px 1px;
  background-color: #ddd;
  font-size: 1em;
  display: inline-block;
  border-radius: 20px;
}

.content-header .description {
  padding-left: 20px;
}
</style>